<template>
  <body class="overflow-hidden dark:bg-gray-900">
    <div
      class="max-w-7xl mx-auto  px-4 pb-8"
    >
      <breadcrumps></breadcrumps>
      <div
        class="flex justify-center items-center lg:flex-row flex-col-reverse gap-10 lg:mb-20"
      >
        <!-- Description Div -->

        <div class="w-full sm:w-96 md:w-8/12 lg:w-6/12 items-center">
          <h2
            class="font-semibold capitalize lg:text-4xl text-3xl lg:leading-9 leading-7 text-yellow dark:text-white mt-4"
          >
            {{ this.product.title }}
          </h2>

          <p class="font-normal text-base leading-6 text-gray-600 mt-7">
            <span class="font-bold text-lg">Model: {{ product.model }}</span
            ><br />
            <span class="font-bold text-lg">EAN: {{ product.ean }}</span
            ><br />
            <span
              v-for="sub in product.subCategory"
              :key="sub.id"
              class="font-bold text-lg"
            >
              {{ sub.child }} /
            </span>
          </p>
          <div class="flex flex-col w-44">
            <p
              class="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 mt-6 dark:text-white"
            >
              <small>
                <del
                  v-if="this.product.actionPrice > 0"
                  class="text-gray-400 text-sm"
                >
                   {{ trackPrice(this.product.price) }}</del
                >
              </small>
              <span v-if="this.product.actionPrice == 0">
                {{ trackPrice(this.product.price) }}</span
              >
            </p>
            <p v-if="this.product.actionPrice > 0" class="tag">
               {{trackPrice(this.product.actionPrice)  }}
            </p>
          </div>
          <!-- <div class="lg:mt-11 mt-10">
            <div class="flex flex-row justify-between">
              <p
                class="font-medium text-base leading-4 text-gray-600 capitalize"
              >
                kolicina
              </p>
              <div class="flex">
                <span
                  @click="minus()"
                  class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-r-0 w-7 h-7 flex items-center justify-center pb-1"
                  >-</span
                >
                <input
                  id="counter"
                  v-model="counter"
                  aria-label="input"
                  class="border dark:text-white border-gray-300 dark:bg-transparent h-full text-center w-14 pb-1"
                  type="text"
                />
                <span
                  @click="plus()"
                  class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-l-0 w-7 h-7 flex items-center justify-center pb-1"
                  >+</span
                >
              </div>
            </div>
          </div> -->

          <button
            @click="addToCart($route.params.id)"
            class="focus:outline-none focus:ring-2 hover:bg-yellow-dark focus:ring-offset-2 focus:ring-gray-800 font-medium text-base leading-4 text-white bg-yellow w-full py-5 lg:mt-12 mt-6 dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100"
          >
            Dodaj u Korpu
          </button>
        </div>

        <!-- Preview Images Div For larger Screen-->

        <div
          class="w-full sm:w-96 md:w-8/12 lg:w-6/12 lg:flex-col flex flex-col gap-2"
        >
          <div class="w-full h-96 flex justify-center items-center relative">
            <img
              v-for="image in this.product.images"
              :key="image.id"
              :src="$store.getters.get_API + '/images/' + image.url"
              :alt="image.model"
              class="w-auto h-auto absolute mx-auto transition-all mySlides"
              loading="lazy"
            />
          </div>
          <div class="w-full flex flex-row gap-3">
            <div
              v-for="(image, index) in this.product.images"
              :key="image.id"
              class="flex justify-center items-center w-4/12"
            >
              <img
                :src="$store.getters.get_API + '/images/' + image.url"
                :alt="image.url"
                class="transition-all transform hover:scale-95 demo cursor-pointer"
                @click="showDivs(index)"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mt-20">
        <h2
          class="font-semibold lg:text-4xl text-3xl lg:leading-9 leading-7 text-yellow dark:text-white mt-4 mb-10"
        >
          Specifikacija
        </h2>
        <specification
          v-for="(spec, index) in this.product.specification"
          :key="index"
          :description="spec.description"
          :heading="spec.heading"
          :index="index"
        ></specification>
      </div>
    </div>
    <spinner :loading="start"></spinner>
  </body>
</template>

<script>
import Spinner from "./TheSpinner.vue";
import Breadcrumps from "../layout/Breadcrumps.vue";
import Specification from "../partials/SpecificationList.vue";
import FormPrice from "../../middleware/PriceHandler.js"
export default {
  components: {
    Specification,
    Breadcrumps,
    Spinner,
  },
  data() {
    return {
      counter: 1,
      product: {},
      start: false,
    };
  },
  metaInfo () {
    return {
      title: this.$store.state.spinner ? 'Loading...' :  this.$store.getters.get_oneProduct.title,
      meta: [
        { vmid: 'description', name: 'description', content:  this.$store.getters.get_oneProduct.title}
      ]
    }
  },
  async mounted() {
    await this.getProduct();
  },
  updated() {},
  beforeDestroy() {
    this.$store.commit("SET_NAME_FOR_BREADCRUMP", null);
  },
  methods: {
    async getProduct() {
      this.start = true;
      await this.$store.dispatch("getOneProduct", this.$route.params);
      this.product = await this.$store.getters.get_oneProduct;
      this.$store.commit("SET_NAME_FOR_BREADCRUMP", this.product.title);
      this.start = false;
    },

    showDivs(n) {
      const slides = document.querySelectorAll(".mySlides");
      for (let i = 0; i < slides.length; i++) {
        slides[i].style = "opacity: 0";
      }
      slides[n].style = "opacity: 1";
    },
    async addToCart(id) {
      this.start = true;
      if (!this.$store.getters.get_isValid) {
        this.$store.commit("SET_SINGIN_MODAL", true);
        this.start = false;
        return;
      } else {
        await this.$store.dispatch("addShopItem", {
          id,
          email: this.$store.getters.get_user.email,
        });
        this.$store.commit("SET_NOTIFICATION_STATUS", true);
        this.start = false;
      }
    },
    trackPrice(price){
      return FormPrice(price)
    },
    plus() {
      this.counter;
      this.counter = this.counter + 1;
    },

    minus() {
      var preValue = document.getElementById("counter").value;
      if (parseInt(preValue) != 0) {
        this.counter = this.counter - 1;
      }
    },
  },
};
</script>

<style></style>
