<template>
  <section class="p-4 md:py-24 mx-auto max-w-7xl">
  <h2 class="mb-2 text-3xl font-extrabold leading-tight text-gray-900">Nas Blog</h2>
  <p class="mb-20 text-lg text-gray-400">Dolazi direktno sa stola inženjera, kreatora i menadžera u Dilektri.</p>
  <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
      <div v-for="post in this.allPosts" :key="post.post_id">
        <blog-post
          :time="post.createdAt"
          :title="post.title"
          :text="post.text"
          :id="post.post_id"
          :images="post.images[post.images.length - 1]"
          :category="post.category"
          :name="post.name"
          :lastname="post.lastname"
        ></blog-post>
      </div>
      <!-- END: Blog Layout -->
    </div>
     <spinner :loading="start"></spinner>
  </section>
</template>

<script>
import Spinner from "../components/reusable/TheSpinner.vue";
import BlogPost from "../components/partials/BlogPost.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  metaInfo: {
    title: 'Blog '
  },
  components: {
    BlogPost,Spinner
  },
  data() {
    return {
      allPosts: [],
      start:false
    };
  },
  computed: {
    ...mapGetters(["get_allPosts"]),
    ...mapActions(["getAllPosts"]),
    trackAllPosts() {
      return this.allPosts;
    },
  },
  mounted() {
    this.setAllPosts();
  },
  methods: {
    async setAllPosts() {
      this.start = true
      await this.$store.dispatch("getAllPosts");
      this.allPosts = await this.$store.getters.get_allPosts;
      this.start = false
    },
  },
};
</script>

<style>
</style>