import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=c6f436cc&scoped=true&"
var script = {}
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=c6f436cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f436cc",
  null
  
)

export default component.exports