<template>
  <div class="w-full h-96 flex justify-center items-center relative">
    <figure
      v-for="(image, index) in this.images"
      :key="index"
      class="blog-slide absolute mx-auto transition-all mySlides hidden-button"
      @click="showModal"
    >
      <img
        :src="$store.getters.get_API + '/images/' + image"
        :alt="image"
        class="absolute"
      />
      <!-- <figcaption class="absolute inset-0 flex justify-center items-center hide">
        <button @click="showModal"><i class="fas fa-expand-arrows-alt text-2xl text-white"></i></button>
      </figcaption> -->
    </figure>

    <div v-show="this.show" class="modal-slider">
      <button class="absolute top-2 right-6" @click="showModal">
        <i class="fas fa-times text-2xl"></i>
      </button>
      <button @click="next(-1)" class="absolute left-5 top-1/2">
        <i class="fas fa-chevron-left text-5xl text-yellow"></i>
      </button>
      <img
        v-for="image in this.images"
        :key="image.id"
        :src="$store.getters.get_API + '/images/' + image"
        :alt="image.model"
        class="modal-slides hover:cursor-pointer"
      />
      <button @click="next(1)" class="absolute right-5 top-1/2">
        <i class="fas fa-chevron-right text-5xl text-yellow"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images"],
  data() {
    return {
      show: false,
      counter: 0,
    };
  },
  methods: {
    showModal() {
      this.show = !this.show;
    },
    next(n) {
      const slides = document.querySelectorAll(".modal-slides");
      for (let i = 0; i < slides.length; i++) {
        slides[i].style = "opacity: 0";
      }
      if (n > 0) {
        this.counter++;
      }
      if (n < 0) {
        this.counter--;
      }
      if (this.counter > slides.length - 1) {
        this.counter = 0;
      }
      if (this.counter < 0) {
        this.counter = slides.length - 1;
      }
      slides[this.counter].style = "opacity: 1";
    },
  },
};
</script>

<style lang="scss">
.modal-slider {
  @apply fixed inset-0 bg-gray-600 bg-opacity-80 w-full h-screen flex justify-center items-center;
  z-index: 5000;
}
.modal-slides {
  @apply w-7/12 h-auto mx-auto absolute;
}
.hidden-button {
  .hide {
    @apply opacity-0 transition-all;
  }
  &:hover .hide {
    @apply opacity-100 transform scale-110 z-50;
  }
}
</style>
