<template>
  <section
    v-if="$store.getters.get_openSinginModal"
    class="fixed inset-0 z-50 flex justify-center items-center bg-gray-600 bg-opacity-50 "
  >
    <div class="w-full sm:w-8/12 lg:w-6/12 mx-auto bg-white relative px-10 py-16 shadow-2xl bg-gradient-to-r from-yellow-light to-yellow-400 ">
      <button class="absolute top-2 right-4" @click="closeSinginModal">
        <i class="fas fa-times text-3xl text-gray-50"></i>
      </button>
      <section class="px-4 py-20 mx-auto max-w-7xl">
        <div
          class="w-full mx-auto space-y-5 lg:w-6/12"
        >
          <div class="text-center pb-6">
            <h1 class="text-4xl font-semibold text-center text-gray-50">
              Uloguj se
            </h1>
            <p class="mt-3 text-center text-gray-400 dark:text-gray-300">
              Unesite svoje kredencijale
            </p>
          </div>
          <div class="space-y-4 flex flex-col justify-center">
            <label class="block">
              <span class="block mb-1 text-xs font-medium text-gray-50"
                >Vas Email</span
              >
              <input
                class="form-input"
                type="email"
                placeholder="Ex. pera.peric@gmail.com"
                inputmode="email"
                v-model="email"
                required
              />
                <small v-if="nodata" class="capitalize text-red-500">{{ email.length > 2 ? '' : 'Unesite svoje kredencijale' }}</small>
            </label>
            <label class="block">
              <span class="block mb-1 text-xs font-medium text-gray-50"
                >Vas Password</span
              >
              <input
                class="form-input"
                type="password"
                placeholder="••••••••"
                required
                v-model="password"
              />
            </label>
            <button @click="trySingin" class="singinBtn">
              <span class="text">Prijavi se</span>
            </button>
          </div>
          <div class="my-2 text-center">
            <a
              class="text-sm text-gray-700 underline hover:text-purple-700"
              @click="closeSinginModalGoToResetPassword"
              >Zaboravio/la sam password</a>
          </div>
        </div>
      </section>
    </div>
     <spinner :loading="start"></spinner>
     
  </section>
</template>

<script>
import Spinner from "../reusable/TheSpinner.vue";
export default {
  components: {Spinner
  },
  data() {
    return {
      email: "",
      password: "",
      nodata: false,
      start: false
    };
  },

  mounted() {},
  methods: {
    closeSinginModal() {
      this.$store.commit("SET_SINGIN_MODAL", false);
    },
    closeSinginModalGoToResetPassword(){
      this.$store.commit("SET_SINGIN_MODAL", false);
      this.$router.push('/forgotpassword')
    },
    async trySingin() {
      this.start = true
      if (this.email.length == 0 || this.password.length < 8 ||  this.email == '' || this.password == '') {
         this.start = false
        this.nodata = true
        return;
      }
      await this.$store.dispatch("trySingin", {
        email: this.email,
        password: this.password,
      });
      this.closeSinginModal()
      this.start = false
    },
  },
};
</script>

<style lang="scss" scoped>
.singinBtn{
  @apply px-4 py-2 border border-gray-200 rounded-md text-gray-200 mx-auto;
}

</style>
