<template>
  <div class="w-full mt-28">
    <the-top-bar :icon="'fas fa-stream'" :title="'Novi proizvod'"></the-top-bar>
    <added-product-success
      v-show="trackSucces"
      :message="'Uspesno ste dodali proizvod.'"
    ></added-product-success>
    <section class="pl-12 pb-12">
      <h1
        role="heading"
        aria-label="enter Personal data"
        class="text-xl font-semibold leading-7 text-gray-800 mt-10"
      >
        Opis proizvoda
      </h1>
      <p class="text-sm font-light leading-none text-gray-600 mt-0.5">
        Popunite sve neophodna polja
      </p>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Slike proizvoda
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small><br />
      <span class="text-sm text-gray-400 uppercase"
        >dozvoljeni formati slika (jpg, jpeg, png, gif, webp)</span
      >
      <div class="mt-4 flex items-center">
        <div class="flex flex-col w-8/12">
          <div class="imagesPreview flex my-3">
            <image-holder
              v-for="(image, index) in trackPreviewImages"
              :key="index"
              :previewImage="image"
              :index="index"
              :for-delete="deleteFile"
            ></image-holder>
          </div>
          <div class="rounded relative w-72">
            <div
              class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
            >
              <div
                class="text-gray-100 relative flex flex-row hover:cursor-pointer"
              >
                <p
                  class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
                >
                  Dodaj slike <i class="fas fa-edit ml-2"></i>
                </p>

                <input
                  type="file"
                  multiple
                  @change="fileChange"
                  class="fileInput absolute opacity-0"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Deklaracija
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>

      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Tip i Model</label
          >
          <input
            type="text"
            aria-label="tip i model"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Tip i Model"
            v-model="productModel"
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Naziv i vrsta robe</label
          >
          <input
            type="text"
            aria-label="Naziv i vrsta robe"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Naziv i vrsta robe"
            v-model="productName"
            required
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Kategorija</label
          >
          <div class="relative">
            <div class="">
              <button
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 focus:bg-gray-100 w-64 p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
                @click="show = !show"
              >
                <span
                  >{{
                    this.selectedCategory == ""
                      ? "Izaberi Kategoriju"
                      : this.selectedCategory
                  }}
                </span>
                <div>
                  <div class="" v-if="!show">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                  <div v-if="show">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                </div>
              </button>
              <div
                class="w-64 mt-2 px-4 py-2 bg-white shadow rounded absolute"
                v-if="show"
              >
                <ol class="flex flex-col items-start justify-center">
                  <li
                    v-for="(cat, index) in catSelection"
                    :key="index"
                    class="focus:outline-none text-sm leading-normal text-gray-800 my-1 hover:text-yellow cursor-pointer"
                    @click="getCategory(cat)"
                  >
                    {{ cat }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Tagovi za pretragu
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>
      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Pod Kategorija</label
          >
          <div class="relative">
            <div class="">
              <button
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 focus:bg-gray-100 w-64 p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
                @click="showSubCategory = !showSubCategory"
              >
                <span
                  >{{
                    this.categorySub == ""
                      ? "Izaberi Kategoriju"
                      : this.categorySub
                  }}
                </span>
                <div>
                  <div class="" v-if="!showSubCategory">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                  <div v-if="showSubCategory">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                </div>
              </button>
              <div
                class="w-64 mt-2 px-4 py-2 bg-white shadow rounded absolute"
                v-if="showSubCategory"
              >
                <ol class="flex flex-col items-start justify-center">
                  <li
                    v-for="(subCat, index) in selectedSubCategory"
                    :key="index"
                    class="focus:outline-none text-sm leading-normal text-gray-800 my-1 hover:text-yellow cursor-pointer"
                    @click="getSubCategory(subCat)"
                  >
                    {{ subCat }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800">Naziv</label>
          <input
            type="text"
            aria-label="Naziv i vrsta robe"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Naziv i vrsta robe"
            v-model="subCategoryChild"
            required
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-transparent">opis</label>
          <button
            @click="addToFilters"
            class="flex items-center justify-center py-2 px-4 focus:outline-none bg-white border rounded border-gray-400 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            <span class="mx-1">Dodaj </span>
          </button>
        </div>
      </div>
      <div
        v-for="(f, index) in trackFilters"
        :key="index"
        class="flex flex-col w-4/12"
      >
        <div :class="index % 2 == 0 ? 'bg-gray-200' : 'bg-gray-50'">
          <div class="flex flex-row justify-start items-ceter my-2 py-3 px-3">
            <h5 class="min-250 flex items-center">{{ f.subcategory }}</h5>
            <p>{{ f.child }}</p>
          </div>
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Dodatne Informacije
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>
      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800">EAN kod</label>
          <input
            type="name"
            aria-label="EAN kod"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="EAN kod"
            v-model="eanCode"
          />
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Cena
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>
      <div class="mt-4 flex flex-col justify-start">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Regularna cena</label
          >
          <input
            type="number"
            min="0"
            aria-label="EAN kod"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Regularana cena"
            v-model="price"
          />
        </div>
        <hr class="mt-10 mb-3" />
        <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
          Akciska Cena
        </h2>
        <div class="flex justify-start items-center gap-2">
          <div
            class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
          >
            <input
              v-model="actionPrice"
              type="checkbox"
              name="toggle"
              class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
            />
            <label
              for="toggle2"
              class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            ><span :class="!this.actionPrice ? 'text-yellow' : 'text-gray-400'"
              >Ne</span
            >
            /
            <span :class="this.actionPrice ? 'text-yellow' : 'text-gray-400'"
              >Da</span
            ></label
          >
        </div>
        <div v-if="actionPrice" class="flex flex-col md:mt-0 mt-8">
          <input
            type="number"
            min="0"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Akciska Cena"
            v-model="productActionPrice"
          />
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Postavi na promociju
      </h2>
      <div class="flex justify-start items-center gap-2">
        <div
          class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
        >
          <input
            v-model="productForPromotion"
            type="checkbox"
            name="toggle"
            class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
          />
          <label
            for="toggle2"
            class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
        <label
          ><span
            :class="!this.productForPromotion ? 'text-yellow' : 'text-gray-400'"
            >Ne</span
          >
          /
          <span
            :class="this.productForPromotion ? 'text-yellow' : 'text-gray-400'"
            >Da</span
          ></label
        >
      </div>
      <div class="flex flex-col">
        <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
          Specifikacija
        </h2>
        <div class="flex justify-start items-center gap-2">
          <div
            class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
          >
            <input
              v-model="specStatus"
              type="checkbox"
              name="toggle"
              class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
            />
            <label
              for="toggle2"
              class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            ><span :class="!this.specStatus ? 'text-yellow' : 'text-gray-400'"
              >Iskljuci</span
            >
            /
            <span :class="this.specStatus ? 'text-yellow' : 'text-gray-400'"
              >Ukljuci</span
            ></label
          >
        </div>
      </div>

      <div v-if="this.specStatus" class="my-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800">Naslov</label>
          <input
            type="text"
            aria-label="Enter email Address"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Procesor npr."
            v-model="heading"
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800">Opis</label>
          <textarea
            type="text"
            name="description"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="i5-h9500 npr."
            v-model="description"
          ></textarea>
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-transparent">opis</label>
          <button
            @click="addToSpecification"
            class="flex items-center justify-center py-2 px-4 focus:outline-none bg-white border rounded border-gray-400 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            <span class="mx-1">Dodaj </span>
          </button>
        </div>
      </div>

      <div
        v-for="(spec, index) in trackSpecification"
        :key="'A' + index"
        class="flex flex-col w-10/12"
      >
        <div :class="index % 2 == 0 ? 'bg-gray-200' : 'bg-gray-50'">
          <div class="flex flex-row justify-start items-ceter my-2 py-3 px-3">
            <h5 class="min-250 flex items-center">{{ spec.heading }}</h5>
            <p v-html="spec.description.replace(/\n/g, '<br />')"></p>
          </div>
        </div>
      </div>

      <hr />
      <button
        role="button"
        aria-label="Next step"
        @click="saveProduct"
        class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
      >
        <span class="text-sm font-medium text-center text-yellow capitalize"
          >Objavi Proizvod</span
        >
      </button>
    </section>
    <spinner :loading="this.isLoading"></spinner>
    <info-modal
      v-show="$store.getters.get_infoModalOn"
      :message="infoMessage"
    ></info-modal>
  </div>
</template>

<script>
import TheTopBar from "./TheTopBar.vue";
import ImageHolder from "../../reusable/ImageHolder.vue";
import InfoModal from "../../modals/InfoModal.vue";
import AddedProductSuccess from "../../modals/AddedSuccess.vue";
import Spinner from "../../reusable/TheSpinner.vue";
export default {
  components: {
    TheTopBar,
    ImageHolder,
    InfoModal,
    AddedProductSuccess,
    Spinner,
  },
  mounted(){
    this.sortCategoryes()
  },
  data() {
    return {
      isLoading: false,
      infoMessage: "",
      showInfo: false,
      productImages: null,
      productModel: "",
      productName: "",
      selectedCategory: "",
      eanCode: "",
      specStatus: false,
      specification: [],
      heading: "",
      description: "",
      price: "",
      actionPrice: false,
      productActionPrice: "",
      show: false,
      showSubCategory: false,
      productForPromotion: false,
      catSelection: [
        "Desktop računari",
        "Laptop racunari",
        "Tableti",
        "Brandname računari",
        "Kućišta",
        "Napajanja",
        "Grafičke karte",
        "Matične ploče",
        "Memorije",
        "Procesori",
        "HDD i SSD",
        "Serveri",
        "Monitori",
        "Miševi",
        "Tastature",
        "Televizori",
        "Setovi",
        "Zvučnici",
        "Optika",
        "Slušalice",
        "Mikrofoni",
        "Neprekidna napajanja ",
        "Skeneri",
        "Štampači",
        "Multifunkcijski uređaji",
        "Toneri",
        "Ruteri",
        "Svičevi",
        "Ostalo",
        "Prečišćivači vazduha",
        "Korišćena oprema",
        "Eksterni diskovi",
        "USB memorija",
        "Memorijske kartice",
        "Klima Uredjaji",
      ],
      categorySub: "",
      subCategoryChild: "",
      selectedSubCategory: [],
      subCategory: [
        {
          name: "Laptop racunari",
          list: [
            { title: "Procesor", children: [] },
            { title: "Tip procesora", children: [] },
            {
              title: "Grafička karta",
              children: [],
            },
            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
        name: "Tableti",
        list: [
          { title: "Operativni sistem", children: [] },
          { title: "Dijagonala ekrana", children: [] },
          {
            title: "Rezolucija",
            children: [],
          },
          {
            title: "Procesor",
            children: [],
          },
          {
            title: "RAM memorija",
            children: [],
          },
          {
            title: "Interna memorija",
            children: [],
          },
          {
            title: "Prednja kamera",
            children: [],
          },
          {
            title: "Zadnja kamera",
            children: [],
          },
        ],
      },
        {
          name: "Desktop računari",
          list: [
            { title: "Procesor", children: [] },
            { title: "Tip procesora", children: [] },
            {
              title: "Grafička karta",
              children: [],
            },
            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
        name: "Brandname računari",
        list: [
          { title: "Procesor", children: [] },
          { title: "Tip procesora", children: [] },
          {
            title: "Grafička karta",
            children: [],
          },
          {
            title: "Količina memorije",
            children: [],
          },
          {
            title: "Tip Skladištenja",
            children: [],
          },
        ],
      },

        {
          name: "Kućišta",
          list: [{ title: "Proizvođač", children: [] }],
        },
        {
          name: "Napajanja",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Grafičke karte",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
          name: "Matične ploče",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Socket",
              children: [],
            },
          ],
        },
        {
          name: "Memorije",
          list: [
            {
              title: "Količina memorije",
              children: [],
            },

            {
              title: "Radni takt memorije",
              children: [],
            },
            {
              title: "Vrsta memorije",
              children: [],
            },
          ],
        },
        {
          name: "Procesori",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Tip procesora",
              children: [],
            },
            {
              title: "Soket",
              children: [],
            },
          ],
        },
        {
          name: "HDD i SSD",
          list: [
            { title: "Tip diska ", children: [] },

            {
              title: "Format diska",
              children: [],
            },
            {
              title: "Kapacitet",
              children: [],
            },
          ],
        },
        {
          name: "Serveri",
          list: [{ title: "Brandovi", children: [] }],
        },
        {
          name: "Monitori",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Dijagonala ekrana", children: [] },
            { title: "Tip panela", children: [] },
            {
              title: "Učestanost osvežavanja",
              children: [],
            },
            { title: "Rezolucija", children: [] },
          ],
        },
        {
          name: "Miševi",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Tastature",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Setovi",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Zvučnici",
          list: [
            { title: "Sistem", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Slušalice",
          list: [
            { title: "Tip", children: [] },
            { title: "Oblik", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Neprekidna napajanja ",
          list: [
            { title: "Snaga", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Štampači i skeneri",
          list: [
            { title: "Skeneri", children: [] },
            { title: "Štampači", children: [] },
            { title: "Multifunkcijski uređaji", children: [] },
          ],
        },
        {
          name: "Mrežna oprema",
          list: [
            { title: "Ruteri", children: [] },
            { title: "Svičevi", children: [] },
          ],
        },
        {
          name: "Eksterni diskovi",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Kapacitet diska", children: [] },
            { title: "Povezanost", children: [] },
          ],
        },
        {
          name: "USB memorija",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Kapacitet", children: [] },
            { title: "Interfejs", children: [] },
          ],
        },
        {
          name: "Memorijske kartice",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Tip", children: [] },
            { title: "Kapacitet", children: [] },
            { title: "Klasa", children: [] },
          ],
        },
        {
          name: "Prečišćivači vazduha",
          list: [
            { title: "boja", children: [] },
            { title: "funkcija", children: [] },
            { title: "površinska pokrivenost", children: [] },
            { title: "Protok vazduha", children: [] },
          ],
        },
        {
        name: "Štampači",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Tip štampača",
            children: []
          },
          {
            title: "Boja štampe",
            children: []
          },
          {
            title: "Rezolucija štampe",
            children: []
          },
        ],
      },
      {
        name: "Klima Uredjaji",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Snaga",
            children: []
          },
        ],
      },
      {
        name: "Televizori",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Rezolucija",
            children: []
          },
          {
            title: "Tip ekrana",
            children: []
          },
          {
            title: "Veličina ekrana",
            children: []
          },
        ],
      },
      ],
      filters: [],
    };
  },
  computed: {
    trackSpecification() {
      return this.specification;
    },
    trackPreviewImages() {
      return this.$store.getters.get_previewImages;
    },
    trackSucces() {
      return this.$store.getters.get_succes;
    },
    trackFilters() {
      return this.filters;
    },
  },
  methods: {
    sortCategoryes(){
      this.catSelection = this.catSelection.sort((a, b) => a.localeCompare(b))
    },
    addToFilters() {
      if (this.categorySub === "" || this.subCategoryChild === "") {
        return;
      }

      this.filters.push({
        subcategory: this.categorySub,
        child: this.subCategoryChild,
      });
      this.categorySub = "";
      this.subCategoryChild = "";
    },
    addToSpecification() {
      if (this.heading === "" || this.description === "") {
        return;
      }

      this.specification.push({
        heading: this.heading,
        description: this.description,
      });

      this.heading = "";
      this.description = "";
    },
    getCategory(category) {
      this.selectedCategory = category;
      this.show = !this.show;
      this.setSubCategory(category);
    },
    setSubCategory(category) {
      this.selectedSubCategory = [];
      for (let i = 0; i < this.subCategory.length; i++) {
        if (this.subCategory[i].name == category) {
          for (let y = 0; y < this.subCategory[i].list.length; y++)
            this.selectedSubCategory.push(this.subCategory[i].list[y].title);
        }
      }
    },
    getSubCategory(subCategory) {
      this.categorySub = subCategory;
      this.showSubCategory = !this.showSubCategory;
    },
    handleImageError(event) {
      event.target.src = "https://via.placeholder.com/150x150";
    },
    fileChange(event) {
      this.$store.commit("SET_PREVIEW_IMAGES", Array.from(event.target.files));
    },
    deleteFile(e) {
      this.productImages.splice(e, 1);
      return this.productImage;
    },
    async saveProduct() {
      this.isLoading = true;
      const formData = new FormData();
      this.productImages = this.$store.getters.get_previewImages;
      if (
        this.productImages.length == 0 ||
        this.productName === "" ||
        this.productModel === "" ||
        this.eanCode === "" ||
        this.price === "" ||
        this.selectedCategory === ""
      ) {
        this.infoMessage = "Popunite sva obavezna polja";
        this.$store.commit("SET_INFO_MODAL", true);
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < this.productImages.length; i++) {
        formData.append("photos", this.productImages[i]);
      }

      formData.append("title", this.productName);
      formData.append("model", this.productModel);
      formData.append("ean", this.eanCode);
      formData.append("price", this.price);
      formData.append(
        "actionPrice",
        this.productActionPrice === "" ? 0 : this.productActionPrice
      );
      formData.append("category", this.selectedCategory);
      const spec = JSON.stringify(this.specification);
      const filters = JSON.stringify(this.filters);
      formData.append("subcategory", filters);
      formData.append("specification", spec);
      formData.append(
        "productPromotion",
        this.productForPromotion == true ? 1 : 0
      );

      // for (var pair of formData.entries()) {
      //   console.log("form data");
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      await this.$store.dispatch("addNewProduct", formData);
      this.$store.commit("SET_PREVIEW_IMAGES", []);
      this.productImages = null;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.checkbox:checked + .check-icon {
  display: flex;
}
.previewSelectedImages {
  width: 150px;
  height: 150px;
}
.min-250 {
  min-width: 250px;
}
</style>
