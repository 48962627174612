<template>
  <div class="w-full mt-28">
    <the-top-bar
      :icon="'fas fa-stream'"
      :title="'Izmena proizvoda'"
    ></the-top-bar>
    <section class="pl-12 pb-12">
      <h1
        role="heading"
        aria-label="enter Personal data"
        class="text-xl font-semibold leading-7 text-gray-800 mt-10"
      >
        Opis proizvoda
      </h1>
      <p class="text-sm font-light leading-none text-gray-600 mt-0.5">
        Popunite sve neophodna polja
      </p>
      <div class="flex justify-start items-center gap-2">
        <label @click="changeStatus" class="px-5 py-3 mt-5 rounded-full text-white" :class="status == 0 ? 'bg-green-500' : 'bg-red-500'">
          <span>
            {{ status == 0 ? 'Uključen' : 'Isključen' }} 
          </span>
        </label>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Slike proizvoda
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small><br />
      <span class="text-sm text-gray-400 uppercase"
        >dozvoljeni formati slika (jpg, jpeg, png, gif, webp)</span
      >
      <div class="mt-4 flex items-center">
        <div class="flex flex-col w-8/12">
          <div class="imagesPreview flex my-3">
            <image-holder
              v-for="(image, index) in trackPreviewImages"
              :key="image.id"
              :previewImage="image"
              :index="index"
              :productID="$route.params.id"
              :for-delete="deleteFile"
            ></image-holder>
          </div>
          <div class="rounded relative w-72">
            <div
              class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
            >
              <div
                class="text-gray-100 relative flex flex-row hover:cursor-pointer"
              >
                <p
                  class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
                >
                  Izmeni slike <i class="fas fa-edit ml-2"></i>
                </p>

                <input
                  type="file"
                  multiple
                  @change="fileChange"
                  class="fileInput absolute opacity-0"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Deklaracija
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>

      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Tip i Model</label
          >
          <input
            type="text"
            tabindex="0"
            aria-label="tip i model"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Tip i Model"
            v-model="productModel"
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Naziv i vrsta robe</label
          >
          <input
            type="text"
            tabindex="0"
            aria-label="Naziv i vrsta robe"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Naziv i vrsta robe"
            v-model="productName"
            required
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Kategorija</label
          >
          <div class="relative">
            <div class="">
              <button
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 focus:bg-gray-100 w-64 p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
                @click="show = !show"
              >
                <span
                  >{{
                    this.selectedCategory == ""
                      ? "Izaberi Kategoriju"
                      : this.selectedCategory
                  }}
                </span>
                <div>
                  <div class="" v-if="!show">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                  <div v-if="show">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                </div>
              </button>
              <div
                class="w-64 mt-2 px-4 py-2 bg-white shadow rounded absolute"
                v-if="show"
              >
                <ol class="flex flex-col items-start justify-center">
                  <li
                    v-for="(cat, index) in catSelection"
                    :key="index"
                    class="focus:outline-none text-sm leading-normal text-gray-800 my-1 hover:text-yellow cursor-pointer"
                    @click="getCategory(cat)"
                  >
                    {{ cat }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Tagovi za pretragu
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>

      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Pod Kategorija</label
          >
          <div class="relative">
            <div class="">
              <button
                class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 focus:bg-gray-100 w-64 p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
                @click="showSubCategory = !showSubCategory"
              >
                <span
                  >{{
                    this.categorySub == ""
                      ? "Izaberi Kategoriju"
                      : this.categorySub
                  }}
                </span>
                <div>
                  <div class="" v-if="!showSubCategory">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                  <div v-if="showSubCategory">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </div>
                </div>
              </button>
              <div
                class="w-64 mt-2 px-4 py-2 bg-white shadow rounded absolute"
                v-if="showSubCategory"
              >
                <ol class="flex flex-col items-start justify-center">
                  <li
                    v-for="(subCat, index) in selectedSubCategory"
                    :key="index"
                    class="focus:outline-none text-sm leading-normal text-gray-800 my-1 hover:text-yellow cursor-pointer"
                    @click="getSubCategory(subCat)"
                  >
                    {{ subCat }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800">Naziv</label>
          <input
            type="text"
            tabindex="0"
            aria-label="Naziv i vrsta robe"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Naziv i vrsta robe"
            v-model="subCategoryChild"
            required
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-transparent">opis</label>
          <button
            @click="addToFilters"
            class="flex items-center justify-center py-2 px-4 focus:outline-none bg-white border rounded border-gray-400 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            <span class="mx-1">Dodaj </span>
          </button>
        </div>
      </div>

      <div class="my-10">
        <div
          v-for="(sub, index) in trackSubCategory"
          :key="sub.id"
          class="flex flex-col w-10/12"
        >
          <product-sub-categorys
            :id="sub.id"
            :subcategory="sub.subcategory"
            :child="sub.child"
            :index="index"
          ></product-sub-categorys>
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Dodatne Informacije
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>
      <div class="mt-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800">EAN kod</label>
          <input
            type="name"
            tabindex="0"
            aria-label="EAN kod"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="EAN kod"
            v-model="eanCode"
          />
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Cena
      </h2>
      <small class="text-gray-400">***Obavezna Polja</small>
      <div class="mt-4 flex flex-col justify-start">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >Regularna cena</label
          >
          <input
            type="name"
            tabindex="0"
            aria-label="EAN kod"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Regularana cena"
            v-model="price"
          />
        </div>
        <hr class="mt-10 mb-3" />
        <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
          Akciska Cena
        </h2>
        <div class="flex justify-start items-center gap-2">
          <div
            class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
          >
            <input
              v-model="actionPrice"
              type="checkbox"
              name="toggle"
              class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
            />
            <label
              for="toggle2"
              class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            ><span :class="!this.actionPrice ? 'text-yellow' : 'text-gray-400'"
              >Ne</span
            >
            /
            <span :class="this.actionPrice ? 'text-yellow' : 'text-gray-400'"
              >Da</span
            ></label
          >
        </div>
        
        <div v-if="actionPrice" class="flex flex-col md:mt-0 mt-8">
          <input
            type="number"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Akciska Cena"
            v-model="productActionPrice"
          />
        </div>
      </div>
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Postavi na promociju
      </h2>
      <div class="flex justify-start items-center gap-2">
        <div
          class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
        >
          <input
            v-model="productForPromotion"
            type="checkbox"
            name="toggle"
            class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
          />
          <label
            for="toggle2"
            class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
        <label
          ><span
            :class="!this.productForPromotion ? 'text-yellow' : 'text-gray-400'"
            >Ne</span
          >
          /
          <span
            :class="this.productForPromotion ? 'text-yellow' : 'text-gray-400'"
            >Da</span
          ></label
        >
      </div>
      <div class="flex flex-col">
        <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
          Specifikacija
        </h2>
        <div class="flex justify-start items-center gap-2">
          <div
            class="cursor-pointer my-5 rounded-full bg-indigo-700 relative shadow-sm w-12"
          >
            <input
              v-model="specStatus"
              type="checkbox"
              name="toggle"
              class="focus:outline-none checkbox w-6 h-6 rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
            />
            <label
              for="toggle2"
              class="toggle-label dark:bg-gray-700 block w-12 h-4 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            ><span :class="!this.specStatus ? 'text-yellow' : 'text-gray-400'"
              >Iskljuci</span
            >
            /
            <span :class="this.specStatus ? 'text-yellow' : 'text-gray-400'"
              >Ukljuci</span
            ></label
          >
        </div>
      </div>

      <div v-if="this.specStatus" class="my-4 md:flex items-center">
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800">Naslov</label>
          <input
            type="text"
            tabindex="0"
            aria-label="Enter email Address"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="Procesor npr."
            v-model="heading"
          />
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-gray-800">Opis</label>
          <textarea
            type="text"
            name="description"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="i5-h9500 npr."
            v-model="description"
          ></textarea>
        </div>
        <div class="flex flex-col md:ml-12 md:mt-0 mt-8">
          <label class="mb-3 text-sm leading-none text-transparent">Opis</label>
          <button
            @click="addToSpecification"
            class="flex items-center justify-center py-2 px-4 focus:outline-none bg-white border rounded border-gray-400 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            <span class="mx-1">Dodaj </span>
          </button>
        </div>
      </div>
      <div
        v-for="(spec, index) in trackSpecification"
        :key="spec.id"
        class="flex flex-col w-10/12"
      >
        <specification
          :id="spec.id"
          :heading="spec.heading"
          :description="spec.description"
          :index="index"
        ></specification>
      </div>
      <hr />
      <div class="flex">
        <button
          role="button"
          aria-label="Next step"
          @click="saveProduct"
          class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded border-gray-400 my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
        >
          <span class="text-sm font-medium text-center text-yellow capitalize"
            >Sacuvaj Promenu</span
          >
        </button>
        <router-link
          role="button"
          to="/dilektra-cms/products"
          class="flex items-center justify-center ml-2 py-4 px-7 focus:outline-none bg-white border border-yellow rounded border-gray-400 my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
        >
          <span class="text-sm font-medium text-center text-yellow capitalize"
            >Odustani</span
          >
        </router-link>
      </div>
    </section>
    <info-modal v-show="$store.getters.get_infoModalOn"></info-modal>
    <the-spinner :loading="this.isLoading"></the-spinner>
    <update-success
      v-show="$store.getters.get_updateModalOn"
      :message="'Uspesno ste izmenili proizvod'"
    ></update-success>
  </div>
</template>

<script>
import TheTopBar from "./TheTopBar.vue";
import Specification from "./ProductSpecification.vue";
import ProductSubCategorys from "./ProductSubCategorys.vue";
import ImageHolder from "../../reusable/ImageHolder.vue";
import TheSpinner from "../../reusable/TheSpinner.vue";
import InfoModal from "../../modals/InfoModal.vue";
import UpdateSuccess from "../../modals/AddedSuccess.vue";

export default {
  components: {
    TheTopBar,
    Specification,
    ImageHolder,
    InfoModal,
    TheSpinner,
    UpdateSuccess,
    ProductSubCategorys,
  },
  data() {
    return {
      isLoading: false,
      showInfo: false,
      productImages: [],
      specStatus: false,
      actionPrice: false,
      status: 0,
      show: true,
      productForPromotion: false,
      productModel: "",
      productName: "",
      selectedCategory: "",
      showSubCategory: false,
      selectedSubCategory: [],
      subCategoryChild: "",
      subCategory: [],
      categorySub: "",
      filters: [],
      eanCode: "",
      specification: [],
      heading: "",
      description: "",
      price: "",
      productActionPrice: "",
      catSelection: [
        "Desktop računari",
        "Laptop racunari",
        "Brandname računari",
        "Tableti",
        "Kućišta",
        "Napajanja",
        "Grafičke karte",
        "Matične ploče",
        "Memorije",
        "Procesori",
        "HDD i SSD",
        "Serveri",
        "Monitori",
        "Miševi",
        "Tastature",
        "Setovi",
        "Zvučnici",
        "Optika",
        "Slušalice",
        "Mikrofoni",
        "Neprekidna napajanja ",
        "Skeneri",
        "Štampači",
        "Multifunkcijski uređaji",
        "Prečišćivači vazduha",
        "Toneri",
        "Ruteri",
        "Svičevi",
        "Ostalo",
        "Korišćena oprema",
      ],
      selectSubCategory: [
        {
          name: "Laptop racunari",
          list: [
            { title: "Procesor", children: [] },
            { title: "Tip procesora", children: [] },
            {
              title: "Grafička karta",
              children: [],
            },
            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
          name: "Tableti",
          list: [
            { title: "Operativni sistem", children: [] },
            { title: "Dijagonala ekrana", children: [] },
            {
              title: "Rezolucija",
              children: [],
            },
            {
              title: "Procesor",
              children: [],
            },
            {
              title: "RAM memorija",
              children: [],
            },
            {
              title: "Interna memorija",
              children: [],
            },
            {
              title: "Prednja kamera",
              children: [],
            },
            {
              title: "Zadnja kamera",
              children: [],
            },
          ],
        },
        {
          name: "Desktop računari",
          list: [
            { title: "Procesor", children: [] },
            { title: "Tip procesora", children: [] },
            {
              title: "Grafička karta",
              children: [],
            },
            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
          name: "Brandname računari",
          list: [
            { title: "Procesor", children: [] },
            { title: "Tip procesora", children: [] },
            {
              title: "Grafička karta",
              children: [],
            },
            {
              title: "Količina memorije",
              children: [],
            },
            {
              title: "Tip Skladištenja",
              children: [],
            },
          ],
        },

        {
          name: "Kućišta",
          list: [{ title: "Proizvođač", children: [] }],
        },
        {
          name: "Napajanja",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Grafičke karte",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Količina memorije",
              children: [],
            },
          ],
        },
        {
          name: "Matične ploče",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Socket",
              children: [],
            },
          ],
        },
        {
          name: "Memorije",
          list: [
            {
              title: "Količina memorije",
              children: [],
            },

            {
              title: "Radni takt memorije",
              children: [],
            },
            {
              title: "Vrsta memorije",
              children: [],
            },
          ],
        },
        {
          name: "Procesori",
          list: [
            { title: "Proizvođač", children: [] },

            {
              title: "Tip procesora",
              children: [],
            },
            {
              title: "Soket",
              children: [],
            },
          ],
        },
        {
          name: "HDD i SSD",
          list: [
            { title: "Tip diska ", children: [] },

            {
              title: "Format diska",
              children: [],
            },
            {
              title: "Kapacitet",
              children: [],
            },
          ],
        },
        {
          name: "Serveri",
          list: [{ title: "Brandovi", children: [] }],
        },
        {
          name: "Monitori",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Dijagonala ekrana", children: [] },
            { title: "Tip panela", children: [] },
            {
              title: "Učestanost osvežavanja",
              children: [],
            },
            { title: "Rezolucija", children: [] },
          ],
        },
        {
          name: "Miševi",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Tastature",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Setovi",
          list: [
            { title: "Proizvođač", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Zvučnici",
          list: [
            { title: "Sistem", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Slušalice",
          list: [
            { title: "Tip", children: [] },
            { title: "Oblik", children: [] },
            { title: "Povezivost", children: [] },
          ],
        },
        {
          name: "Neprekidna napajanja ",
          list: [
            { title: "Snaga", children: [] },
            { title: "Snaga", children: [] },
          ],
        },
        {
          name: "Štampači i skeneri",
          list: [
            { title: "Skeneri", children: [] },
            { title: "Štampači", children: [] },
            { title: "Multifunkcijski uređaji", children: [] },
          ],
        },
        {
          name: "Mrežna oprema",
          list: [
            { title: "Ruteri", children: [] },
            { title: "Svičevi", children: [] },
          ],
        },
        {
          name: "Prečišćivači vazduha",
          list: [
            { title: "boja", children: [] },
            { title: "funkcija", children: [] },
            { title: "površinska pokrivenost", children: [] },
            { title: "Protok vazduha", children: [] },
          ],
        },
      ],
    };
  },
  computed: {
    trackSpecification() {
      return this.specification;
    },
    trackPreviewImages() {
      return this.$store.getters.get_oneProduct.images;
    },
    trackSubCategory() {
      return this.subCategory;
    },
  },
  async mounted() {
    console.log(this.$route.params.id);
    await this.getProductById();
  },
  methods: {
    async getProductById() {
      await this.$store.dispatch("getOneProduct", {
        id: this.$route.params.id,
      });
      this.status = this.$store.getters.get_oneProduct.status;
      this.productModel = this.$store.getters.get_oneProduct.model;
      this.productName = this.$store.getters.get_oneProduct.title;
      this.selectedCategory = this.$store.getters.get_oneProduct.category;
      this.getCategory(this.selectedCategory);
      this.eanCode = this.$store.getters.get_oneProduct.ean;
      this.price = this.$store.getters.get_oneProduct.price;
      this.subCategory = this.$store.getters.get_oneProduct.subCategory;
      //  promotion on/off
      this.$store.getters.get_oneProduct.promotion > 0
        ? (this.productForPromotion = true)
        : (this.productForPromotion = false);
      // action price
      this.$store.getters.get_oneProduct.actionPrice > 0
        ? (this.actionPrice = true)
        : (this.actionPrice = false);
      this.productActionPrice = this.$store.getters.get_oneProduct.actionPrice;
      // images
      this.productImages = this.$store.getters.get_oneProduct.images;
      //za specifikaciju moram da napravim komponentu
      this.specification = this.$store.getters.get_oneProduct.specification;
      if (this.specification.length > 0) {
        this.specStatus = true;
      }
    },
    addToSpecification() {
      if (this.heading === "" || this.description === "") {
        this.$store.commit("SET_INFO_MODAL", true);
        return;
      }
      this.$store.commit("SET_ONE_PRODUCT_SPECIFICATION", {
        heading: this.heading,
        description: this.description,
      });

      this.heading = "";
      this.description = "";
    },
    getCategory(category) {
      this.selectedCategory = category;
      this.show = !this.show;
      this.setSubCategory(category);
    },
    getSubCategory(subCategory) {
      this.categorySub = subCategory;
      this.showSubCategory = !this.showSubCategory;
    },
    setSubCategory(category) {
      this.selectedSubCategory = [];
      for (let i = 0; i < this.selectSubCategory.length; i++) {
        if (this.selectSubCategory[i].name == category) {
          for (let y = 0; y < this.selectSubCategory[i].list.length; y++)
            this.selectedSubCategory.push(
              this.selectSubCategory[i].list[y].title
            );
        }
      }
      return this.selectedSubCategory;
    },
    async addToFilters() {
      if (this.categorySub === "" || this.subCategoryChild === "") {
        return;
      }

      this.subCategory.push({
        subcategory: this.categorySub,
        child: this.subCategoryChild,
      });

      await this.$store.dispatch("addSubCategoryByProductId", {
        id: this.$route.params.id,
        subcategory: this.categorySub,
        child: this.subCategoryChild,
      });

      this.categorySub = "";
      this.subCategoryChild = "";
    },
    async changeStatus(){
      this.status = this.status === 0 ? 1 : 0
      console.log(this.status);
      await this.$store.dispatch("updateProductStatus", {id:this.$route.params.id, status: this.status})
    },
    handleImageError(event) {
      event.target.src = "https://via.placeholder.com/150x150";
    },
    fileChange(event) {
      this.$store.commit(
        "UPDATE_PRODUCT_IMAGES",
        Array.from(event.target.files)
      );
    },
    deleteFile(e) {
      this.productImages.splice(e, 1);
      return this.productImages;
    },
    async saveProduct() {
      this.isLoading = true;
      const formData = new FormData();
      this.productImages = this.$store.getters.get_oneProduct.images;
      // if (
      //   this.productImages.length == 0 ||
      //   this.productName === "" ||
      //   this.productModel === "" ||
      //   this.eanCode === "" ||
      //   this.price === "" ||
      //   this.selectedCategory === ""
      // ) {
      //   this.$store.commit("SET_INFO_MODAL", true);
      //   return;
      // }
      for (let i = 0; i < this.productImages.length; i++) {
        formData.append("photos", this.productImages[i]);
      }

      formData.append("title", this.productName);
      formData.append("model", this.productModel);
      formData.append("ean", this.eanCode);
      formData.append("price", this.price);
      formData.append(
        "actionPrice",
        this.productActionPrice === "" ? 0 : this.productActionPrice
      );
      formData.append("category", this.selectedCategory);
      const spec = JSON.stringify(this.specification);
      formData.append("specification", spec);
      formData.append(
        "productPromotion",
        this.productForPromotion == true ? 1 : 0
      );

      // for (var pair of formData.entries()) {
      //   console.log("form data");
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      await this.$store.dispatch("updateProduct", {
        id: this.$route.params.id,
        formData,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.checkbox:checked + .check-icon {
  display: flex;
}
.previewSelectedImages {
  width: 150px;
  height: 150px;
}
.min-250 {
  min-width: 250px;
}
</style>
