import axios from 'axios';
import errorHandler from '../../../../middleware/ErrorHandler'
const users = {
    state: {
        allUsers: [],
        deleteModal: false
    },
    mutations: {
        SET_ALL_USERS(state, payload) {
            state.allUsers = payload
        },
        DELETE_MODAL(state) {
            state.deleteModal = !state.deleteModal
        }
    },
    actions: {
        async getAllUsers(context) {
            try {
                const response = await axios.get(context.rootGetters.get_API + '/users/all', context.rootGetters.get_headers)
                if (response.status == 200) {
                    context.commit('SET_ALL_USERS', response.data)
                }
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async deleteUserByEmail(context, payload) {
            try {
                const response = await axios.delete(context.rootGetters.get_API + '/users/' + payload, context.rootGetters.get_headers)
                if (response.status == 200) {
                    context.dispatch('getAllUsers')
                }
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        }

    },
    getters: {
        get_allUsers(state) {
            return state.allUsers.reverse()
        },
        get_deleteModal(state) {
            return state.deleteModal
        }
    }
}
export default users