<template>
  <!-- <transition name="slide-down"> -->
  <nav v-show="trackShow" class="dropdown-menu">
    <ul class="">
      <li
        v-for="cat in this.get_catSelection"
        :key="cat.name"
        class="list-item level-0"
        @mouseover="collaps(cat.name)"
        @mouseleave="hover = false"
      >
        <a
          v-if="cat.children"
          class="level-0-a"
          :style="cat.name == showSubMenu && 'color: #fbab18;'"
          >{{ cat.name }}
        </a>
        <a
          v-if="!cat.children"
          class="level-0-a"
          :style="cat.name == showSubMenu && 'color: #fbab18;'"
          @click="categoryList(cat.name)"
          >{{ cat.name }}</a
        >
        <i v-if="cat.children" class="fas fa-chevron-right ml-4 text-xs"></i>
      </li>
    </ul>
    <category-sub-menu
      :catname="this.showSubMenu"
      :list="this.get_catSelection"
    ></category-sub-menu>
  </nav>
  <!-- </transition> -->
</template>

<script>
import CategorySubMenu from "./CategorySubMenu.vue";
import {mapGetters} from "vuex";
export default {
  components: { CategorySubMenu },
  props: ["openMenu"],
  computed: {
    trackShow() {
      return this.openMenu;
    },
    ...mapGetters(["get_catSelection"]),
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.trackScroll(window.pageYOffset);
      const hisHeight = document.querySelector(".hisHeight");
      if (this.scrollHeight > hisHeight.clientHeight) {
        document.querySelector(".dropdown-menu").classList.add("ifScroll");
      } else {
        document.querySelector(".dropdown-menu").classList.remove("ifScroll");
      }
    })
  },

  data() {
    return {
      showSubMenu: "",
      hover: false,
      scrollHeight: 0,
      existCategory: [],
      catSelection: [],
    };
  },
  methods: {
    collaps(name) {
      this.hover = true;
      this.showSubMenu = name;
      return this.showSubMenu;
    },
    categoryList(categoryName) {
      this.$store.commit("TOGGLE_DROPDOWN_MENU");
      //get category
      console.log(categoryName);
      this.$store.dispatch('getProductsByCategory', categoryName)
      
      this.$router
      .push({ name: "CategoryPage", params: { category: categoryName } })
      .catch(() => {});
    },
    trackScroll(h) {
      this.scrollHeight = h;
      return this.scrollHeight;
    },
  },
};
</script>

<style lang="scss">
.dropdown-menu {
  @apply absolute top-1 rounded-sm bg-white shadow-md w-auto flex flex-row;
  z-index: 1;
  .level-0 {
    @apply w-full cursor-pointer bg-white flex flex-row;
    .level-0-a {
      @apply hover:text-yellow;
      width: 200px;
    }
  }
}
.ifScroll {
  @apply fixed;
  top: 72px;
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.45s ease-out;
}
.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>