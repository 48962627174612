<template>
  <div class="specification">
    <div :class="index % 2 == 0 ? 'bg-gray-200' : 'bg-gray-50'">
      <div class="flex flex-row justify-start items-ceter py-3 px-3">
        <h5 v-if="!edit || edit" class="min-250 flex items-center">
          {{ oneSubcategory.subcategory }}
        </h5>
        <p
          v-if="!edit"
          class="w-8/12 flex items-center"
          v-html="oneSubcategory.child"
        ></p>

        <input
          v-if="edit"
          class="w-8/12 flex items-center"
          v-model="oneSubcategory.child"
        />
        <div class="flex justify-center items-center flex-col ml-3">
          <button class="action-btn text-red-500" @click="deleteSpec(index)">
            <i class="far fa-trash-alt text-base text-red-400 my-1"></i> Obrisi
          </button>
          <button
            @click="editSpec"
            v-if="!edit"
            class="action-btn text-gray-500"
          >
            <i class="fas fa-edit"></i> Izmeni
          </button>
          <button
            @click="saveSpec"
            v-if="edit"
            class="action-btn text-gray-500"
          >
            <i class="fas fa-save"></i> Sacuvaj
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "subcategory", "child", "index"],
  data() {
    return {
      oneSubcategory: {
        subcategory: this.subcategory,
        child: this.child,
        id: this.id,
      },
      edit: false,
      editDesript: "",
    };
  },
  methods: {
    editSpec() {
      this.edit = !this.edit;
    },
    async saveSpec() {
      await this.$store.dispatch(
        "updateProductSubCategoryById",
        this.oneSubcategory
      );
      this.$store.commit("UPDATE_MODAL", true);
      this.editSpec();
    },
    async deleteSpec(index) {
      await this.$store.dispatch("deleteSubCategoryById", {
        id: this.oneSubcategory.id,
        index,
      });
    },
  },
};
</script>

<style lang="scss">
// *{
//     @apply border border-red-500;
// }
</style>
