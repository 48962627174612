<template>
  <header
    class="flex items-end justify-between fixed w-succes top-0 h-116 px-12 pb-4 bg-gray-900 text-gray-100 font-bold z-40"
  >
    <h2 class="text-2xl text-gray-100"><i :class="icon"></i> {{ title }}</h2>
    <router-link
    v-if="link != undefined"
      :to="link"
      class="focus:ring-2 focus:ring-offset-2 focus:ring-yellow inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-yellow hover:bg-yellow-light focus:outline-none rounded"
    >
      <i class="fas fa-plus-circle"></i>
      <p class="text-sm font-medium leading-none text-white ml-1">
        {{ add }}
      </p>
    </router-link>
  </header>
</template>

<script>
export default {
  props: ["title", "add", "link", "icon"],
};
</script>
