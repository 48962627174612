<template>
  <div class="w-full pt-28">
    <the-top-bar
      :icon="'fas fa-blog'"
      :title="'Izmeni Blog Post'"
    ></the-top-bar>
    <!--Pocetak posta-->
    <added-post-succes
      v-show="trackSucces"
      :message="'Uspesno ste izmenili post.'"
    ></added-post-succes>
    <!-- <div v-on:submit.prevent> -->
    <div class="bg-white">
      <div class="container px-12 bg-white rounded">
        <div class="w-full mx-auto pt-5">
          <div class="flex flex-col w-1/2">
            <img class="preview" :src="this.imageSrc()" alt="preview image" />
            <div class="rounded relative w-72 mt-4">
              <div
                class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
              >
                <div
                  class="text-gray-100 relative flex flex-row hover:cursor-pointer"
                >
                  <p
                    class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
                  >
                    Izmeni naslovnu sliku <i class="fas fa-edit ml-2"></i>
                  </p>

                  <input
                    type="file"
                    @change="fileChange"
                    class="fileInput absolute opacity-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-16 flex flex-col xl:w-2/6 lg:w-1/2 md:w-1/2 w-full">
            <label
              for="username"
              class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
              >Naslov</label
            >
            <input
              type="text"
              v-model="post.title"
              name="username"
              required
              class="edit-input"
              :placeholder="post.title"
            />

            <div class="flex justify-strat flex-col mb-8 mt-4">
              <p class="text-base text-gray-800 dark:text-gray-100 pb-1">
                Kategorija
              </p>
              <div class="flex justify-between items-center">
                <div class="w-9/12">
                  <p class="text-xl dark:text-gray-400">
                    <span
                      :class="!this.checkBox ? 'text-yellow' : 'text-gray-500'"
                      >Mreze</span
                    >
                    /
                    <span
                      :class="this.checkBox ? 'text-yellow' : 'text-gray-500'"
                      >Servisi</span
                    >
                  </p>
                </div>
                <div
                  class="cursor-pointer rounded-full bg-gray-200 relative shadow-sm"
                >
                  <input
                    type="checkbox"
                    name="categody"
                    v-model="checkBox"
                    class="focus:outline-none checkbox w-6 h-6 rounded-full bg-gray-200 shadow-md dark:bg-gray-400 absolute appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
                  />

                  <label
                    for="toggle1"
                    class="toggle-label block w-12 h-4 overflow-hidden rounded-full bg-gray-300 dark:bg-gray-800 cursor-pointer"
                  >
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 flex flex-col xl:w-3/5 lg:w-1/2 md:w-1/2 w-full">
            <label
              for="posttext"
              class="pb-2 text-base font-bold text-gray-800 dark:text-gray-100"
              >Text</label
            >
            <textarea
              name="posttext"
              required
              class="text-area-edit"
              v-model="textForEdit"
            ></textarea>
            <p
              class="w-full text-right text-xs pt-1 text-gray-500 dark:text-gray-400"
            >
              Bice napomena kako fomratirati text...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container px-12 bg-white mt-10 rounded">
      <div class="w-full border-b border-gray-300 py-2">
        <div class="flex w-11/12 mx-auto xl:w-full xl:mx-0 items-center">
          <p class="text-lg text-gray-800 dark:text-gray-100 font-bold">
            Autor
          </p>
        </div>
      </div>
      <div class="mx-auto">
        <div class="container mx-auto">
          <div class="my-6 w-11/12 mx-auto xl:w-full xl:mx-0">
            <!-- <h2>{{ this.post.author }}</h2> -->
            <div class="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
              <label
                for="FirstName"
                class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
                >Ime</label
              >
              <input
                type="text"
                name="firstName"
                required
                class="edit-input"
                :placeholder="post.name"
                v-model="post.name"
              />
            </div>
            <div class="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
              <label
                for="LastName"
                class="pb-2 text-sm font-bold text-gray-800 dark:text-gray-100"
                >Prezime</label
              >
              <input
                type="text"
                name="lastName"
                required
                class="edit-input"
                v-model="post.lastname"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-12 bg-white mt-10 rounded">
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Galerija
      </h2>
      <small class="text-gray-400">***Nije obavezno polje</small><br />
      <div class="flex flex-col w-8/12">
        <div class="imagesPreview flex my-3">
          <image-holder
            v-for="(image, index) in this.post.gallery"
            :key="image.id"
            :previewImage="image"
            :index="index"
            :productID="$route.params.id"
          ></image-holder>
        </div>
        <div class="rounded relative w-72">
          <div
            class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
          >
            <div
              class="text-gray-100 relative flex flex-row hover:cursor-pointer"
            >
              <p
                class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
              >
                Izmeni slike <i class="fas fa-edit ml-2"></i>
              </p>

              <input
                type="file"
                multiple
                @change="galleryChange"
                class="fileInput absolute opacity-0"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-12 bg-white my-10 rounded">
      <div class="w-full py-4 sm:px-0 bg-white flex">
        <button @click="cancelEdit" class="button">
          <span class="text"> Odkazi </span>
        </button>
        <button class="button ml-3" @click="saveProduct">
          <span class="text"> Sacuvaj Promene </span>
        </button>
      </div>
    </div>
    <!-- </div> -->
    <the-spinner :loading="this.isLoading"></the-spinner>
  </div>
</template>

<script>
import TheTopBar from "./TheTopBar.vue";
import ImageHolder from "../../reusable/ImageHolder.vue";
import TheSpinner from "../../reusable/TheSpinner.vue";
import AddedPostSucces from "../../modals/AddedSuccess.vue";
export default {
  components: {
    TheTopBar,
    ImageHolder,
    TheSpinner,
    AddedPostSucces,
  },
  data() {
    return {
      post: {},
      selectedImage: null,
      title: "",
      postText: "",
      checkBox: null,
      isLoading: false,
      textForEdit: "",
    };
  },
  async mounted() {
    await this.getPostById(), await this.setText();
  },
  computed: {
    trackPost() {
      return this.post;
    },
    trackSucces() {
      return this.$store.getters.get_succes;
    },
    trackText() {
      return this.setText();
    },
    // trackGallery() {
    //   return this.forTrackingGalleryImages();
    // },
  },
  methods: {
    async getPostById() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("getOnePost", this.$route.params.id);

        this.post = {
          title: this.$store.getters.get_onePost.title,
          text: this.$store.getters.get_onePost.text.replace(/\n/g, "<br />"),
          createdAt: this.$store.getters.get_onePost.createdAt,
          image: this.$store.getters.get_onePost.images[0].url,
          gallery: [],
          name: this.$store.getters.get_onePost.name,
          lastname: this.$store.getters.get_onePost.lastname,
          category: this.$store.getters.get_onePost.category,
          post_id: this.$store.getters.get_onePost.post_id,
        };
        if (this.$store.getters.get_onePost.images.length > 1) {
          let shiftGallery = [];
          for (
            let i = 1;
            i < this.$store.getters.get_onePost.images.length;
            i++
          ) {
            shiftGallery.push(this.$store.getters.get_onePost.images[i]);
            this.post.gallery = shiftGallery;
          }
        }
        if (this.$store.getters.get_galleryImages.length > 0) {
          this.post.gallery = this.$store.getters.get_galleryImages;
        }
        if (
          this.$store.getters.get_onePost.images.length == 0 &&
          this.$store.getters.get_galleryImages.length == 0
        ) {
          this.post.gallery = [];
        }

        this.checkBox = this.post.category == "servis" ? true : false;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    setText() {
      this.textForEdit = this.post.text.replaceAll("<br />", "");
      return this.textForEdit;
    },
    handleImageError(event) {
      event.target.src = "https://via.placeholder.com/400x200";
    },
    imageSrc() {
      if (this.post.image !== undefined) {
        return this.$store.getters.get_API + "/images/" + this.post.image;
      }
      if (this.selectedImage !== null) {
        return this.selectedImage;
      }
      if (this.post.image === undefined && this.selectedImage === null) {
        return "https://via.placeholder.com/400x200";
      }
    },
    fileChange(event) {
      const previewSelectedImage = document.querySelector(".preview");

      this.selectedImage = event.target.files[0];

      let reader = new FileReader();

      reader.addEventListener("load", () => {
        previewSelectedImage.src = reader.result;
      });
      if (this.selectedImage) {
        reader.readAsDataURL(this.selectedImage);
      }

      return this.selectedImage;
    },
    galleryChange() {
      this.$store.commit(
        "SET_GALLERY_PREVIEW_IMAGES",
        Array.from(event.target.files)
      );
      this.post.gallery = this.$store.getters.get_galleryImages;
    },
    cancelEdit() {
      this.$router.push("/dilektra-cms/blog");
    },
    async saveProduct() {
      try {
        this.isLoading = true;

        const cat = this.checkBox ? "servis" : "mreze";
        const formData = new FormData();

        const photos = [];

        if (this.$store.getters.get_galleryImages.length > 0) {
          for (
            let i = 0;
            i < this.$store.getters.get_galleryImages.length;
            i++
          ) {
            photos.push(this.$store.getters.get_galleryImages[i]);
          }
        }
        if (this.selectedImage instanceof File) {
          formData.append("photos", this.selectedImage);
          formData.append(
            "photoUpdate",
            this.$store.getters.get_onePost.images[0].id
          );
        } else {
          this.selectedImage = null;
        }

        for (let i = 0; i < photos.length; i++) {
          formData.append("photos", photos[i]);
        }

        formData.append("title", this.post.title);
        formData.append("name", this.post.name);
        formData.append("lastname", this.post.lastname);
        formData.append("text", this.textForEdit);
        formData.append("category", cat);
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        let id = this.post.post_id;
        await this.$store.dispatch("updatePost", { id, formData });
        this.$store.commit("RESET_GALLERY");
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.edit-input {
  @apply border border-transparent rounded outline-none transition-all text-xl py-3 bg-transparent placeholder-gray-500 text-gray-800;
  &:focus {
    @apply border border-yellow px-3;
  }
}
.text-area-edit {
  @apply bg-transparent border border-transparent outline-none transition-all py-3 mt-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none overflow-hidden placeholder-gray-500 text-gray-500 dark:text-gray-400;
  height: 500px;
  &:focus {
    @apply border border-yellow px-3;
  }
}
</style>
