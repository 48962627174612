<template>
  <section class="px-4 py-20 mx-auto max-w-7xl">
    <div
      class="w-full mx-auto space-y-5 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12"
    >
      <div class="text-center pb-6">
        <h1 class="text-4xl font-semibold text-center text-gray-900">
          Resetuj sifru
        </h1>
        <p class="mt-3 text-center text-gray-500 dark:text-gray-300">
          Unesite svoju email adresu.
        </p>
      </div>
      <div class="space-y-4">
        <label class="block">
          <span class="block mb-1 text-xs font-medium text-gray-700"
            >Vas Email</span
          >
          <input
            v-if="!startReset"
            class="form-input"
            type="email"
            placeholder="Ex. pera.peric@gmail.com"
            inputmode="email"
            v-model="userEmail"
            required
          />
          <span v-if="startReset">{{ this.userEmail }}</span>
        </label>
        <div v-if="startReset">
          <label class="block">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Kod koji ste dobili na email iskopirajte ovde</span
            >
            <input
              class="form-input"
              type="text"
              inputmode="text"
              v-model="token"
              required
            />
          </label>
          <label class="block">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Novi Password</span
            >
            <input
              class="form-input"
              type="password"
              placeholder="*************"
              inputmode="password"
              v-model="newPassword"
              required
            />
          </label>
        </div>
        <button
          v-if="!startReset"
          @click="sendEmail"
          class="w-full py-3 btn btn-primary"
        >
          Posalji
        </button>

        <button
          v-if="startReset"
          @click="resetPassword"
          class="w-full py-3 btn btn-primary"
        >
          Resetuj sifru
        </button>
      </div>
    </div>
    <success-pop-vue :message="$store.getters.get_message" :show="$store.getters.get_showModal"></success-pop-vue>
  </section>
</template>

<script>
import SuccessPopVue from '../modals/SuccessPop.vue';
export default {
  components:{
    SuccessPopVue
  },
  data() {
    return {
      userEmail: null,
      startReset: false,
      token: null,
      newPassword: null,
    };
  },
  methods: {
    async sendEmail() {
      if (this.userEmail == null) {
        return;
      }
      await this.$store.dispatch("handleForgotPassword", this.userEmail);
      this.startReset = !this.startReset;
    },
    async resetPassword() {
      if (this.token == null || this.newPassword == null) {
        return;
      }
      await this.$store.dispatch("handleResetPassword", {
        token: this.token,
        password: this.newPassword,
      });
    },
  },
};
</script>

<style></style>
