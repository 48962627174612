<template>
  <section class="">
    <section class="overflow-hidden max-w-7xl mx-auto">
      <div class="2xl:mx-auto 2xl:px-0 md:py-12 md:px-4 sm:py-9 sm:px-4">
        <div class="px-4 pt-4 pb-10 sm:p-0 lg:w-10/12 w-full">
          <h2
            class="xl:w-4/12 lg:w-10/12 w-full font-bold text-gray-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-4 text-left"
          >
            Preko dvadeset godina na tržištu
          </h2>
          <p
            class="font-normal text-base leading-6 text-gray-600 mt-6 text-left"
          >
            Dilektra je nastala 25.01.1993 god. U početku smo radili
            elektroinstalacije i spuštene plafone. Vrlo brzo kreće sa radom
            servis i prodaja računara kao zasebna celina. Vremenom posao sa
            računarima postaje primaran. Obavljamo posao servisa računara i
            računarske opreme kao i prodaju istih i sve vreme radimo zamenu po
            principu staro za novo. Potom krećemo sa uvozom polovnih brand
            računara i to radimo do trenutka kada ministarstvo za ekoligiju
            počinje da traži posebne dozvole zbog očuvanja prirode.
          </p>
        </div>
      </div>
    </section>

    <section class="text-white bg-yellow-light">
      <div
        class="flex flex-col items-center justify-between px-4 py-20 mx-auto max-w-5xl lg:flex-row sm:items-center"
      >
        <div>
          <h2
            class="mb-1 text-xl font-semibold text-left sm:text-2xl text-center lg:text-left"
          >
            Nemate nalog kod nas?
          </h2>
          <p
            class="mb-6 text-lg font-normal text-left text-gray-50 sm:text-xl lg:mb-0 sm:text-center lg:text-left"
          >
            Napravite ga sto pre, i porucujte online.
          </p>
        </div>
        <!-- <div
          class="flex flex-col mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0"
        > -->
          <router-link
            to="/singup"
            class="px-6 py-3 border-2 border-gray-100 text-gray-100 rounded-md uppercase font-bold"
            >Registruj se</router-link
          >
        <!-- </div> -->
      </div>
    </section>

    <section class="overflow-hidden max-w-7xl mx-auto">
      <div class="2xl:mx-auto 2xl:px-0 md:py-12 md:px-4 sm:py-9 sm:px-4">
        <h2
          class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 px-4 py-4 sm:px-0"
        >
          Nasa Priča
        </h2>
        <div
          class="px-4 pb-10 sm:p-0 lg:w-full w-full flex flex-col lg:flex-row lg:justify-between flex-nowrap"
        >
          <div class="sm:p-0 w-full xl:w-5/12 lg:w-5/12">
            <p class="font-normal text-base leading-6 text-gray-600 mt-4">
              U to vreme krećemo sa intezivnijim radom na instalacijama mreža i
              opremanju serverskih soba sa akcentom na nezavisne rashladne
              sisteme za serverske sobe.
            </p>
            <p class="font-normal text-base leading-6 text-gray-600 mt-6">
              Kroz vreme smo došli i do opremanja agregatima čitavih kancelarija
              naravno UPS-evi i Upsovski razvod su bili sastavni deo našeg
              posla. Ukratko kao firma sa prilično dugom tradicijom svoje
              poslovanje smo prilagođavali trendovima a ponekada smo ih i
              započinjali.
            </p>
            <p class="font-normal text-base leading-6 text-gray-600 mt-6">
              Ukratko kao firma sa prilično dugom tradicijom svoje poslovanje
              smo prilagođavali trendovima a ponekada smo ih i započinjali.
            </p>
          </div>
          <div class="sm:p-0 w-full xl:w-5/12 lg:w-5/12">
            <p class="font-normal text-base leading-6 text-gray-600 mt-4">
              Danas radimo i dalje:
            </p>
            <ul>
              <li>• Prodaju i servis računara i računarske opreme</li>
              <li>• Zamenu staro za novo</li>
              <li>• Izgradnju serverskih soba sa svom pratećom opremom</li>
              <li>• IT održavanje kompanija</li>
              <li>• Servis i prodaju potrošnog materijala za printere</li>
              <li>
                • Izradu mrežne instalacije i kompletna rešenja za
                funkcionisanje kompanije (bez obzira na veličinu iste)
              </li>
              <li>
                Trenutno se bavimo i alternativnim izvorima energije ali o tome
                ćemo blagovrememo informisati zainteresovane.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
export default {
  metaInfo: {
    title: 'O nama ',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Kroz vreme smo došli i do opremanja agregatima čitavih kancelarija naravno UPS-evi i Upsovski razvod su bili sastavni deo našeg posla. Ukratko kao firma sa prilično dugom tradicijom svoje poslovanje smo prilagođavali trendovima a ponekada smo ih i apočinjali.'
      }
    ]
  },
}
</script>
<style lang="scss" scoped>
ul {
  li {
    @apply font-normal text-base leading-6 text-gray-600 mt-6;
  }
}
</style>
