<template>
 <transition name="fade-down">
    <div
      v-show="trackShow"
      class="w-full text-white bg-green-500 fixed bottom-0 right-0 z-50"
    >
      <div
        class="container flex items-center justify-between px-6 py-4 mx-auto"
      >
        <div class="flex">
          <svg viewBox="0 0 40 40" class="w-6 h-6 fill-current">
            <path
              d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
            ></path>
          </svg>

          <p class="mx-3">{{ message }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["message", "show"],
  mounted() {
    this.toggleModal();
  },
  updated() {
    this.toggleModal();
  },
  computed: {
    ...mapGetters(["get_showModal"]),
    trackShow() {
      return this.show;
    },
  },
  methods: {
    toggleModal() {
      if (this.show) {
        setTimeout(() => {
          this.$store.commit("CLEAR_MESSAGE");
        }, 3000);
      }
    },
  },
};
</script>

<style>
.fade-in-out-enter-active {
  animation: fade-in-out 0.5s;
}
.fade-in-out-leave-active {
  animation: fade-in-out 0.5s reverse;
}

/* .fade-in-out-enter, .fade-in-out-leave-to{

} */

@keyframes fade-in-out {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>
