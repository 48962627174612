const SET_DATE = (time) => {
    const d = new Date(time);
    const year = d.getFullYear();
    const date = d.getDate();
    const mounths = [
        "Janudar",
        "Februar",
        "Mart",
        "April",
        "Maj",
        "Jun",
        "Jul",
        "Avgust",
        "Septembar",
        "Oktobar",
        "Novembar",
        "Decembar",
    ];
    const monthIndex = d.getMonth();
    const monthName = mounths[monthIndex];
    return date + ". " + monthName + " " + year + "."
}
const SET_TIME = (time) => {
    const d = new Date(time);
    const h = d.getHours()
    const m = d.getMinutes()
    const s = d.getSeconds()
    let mm = m < 10 ? "0" + m.toString() : m;
    return h + ":" + mm + ":" + s
}

export default {
    SET_DATE,
    SET_TIME
}