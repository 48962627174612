<template>
  <div class="w-full relative pt-28">
    <the-top-bar :icon="'fas fa-blog'" :title="'Novi Blog Post'"></the-top-bar>
    <!--Pocetak posta-->
    <added-post-succes
      v-show="trackSucces"
      :message="'Uspesno ste dodali post.'"
    ></added-post-succes>
    <!-- <div v-on:submit.prevent> -->
    <div class="bg-white">
      <div class="container px-12 bg-white rounded">
        <h1
          role="heading"
          aria-label="enter Personal data"
          class="text-xl font-semibold leading-7 text-gray-800 mt-10"
        >
          Opis proizvoda
        </h1>
        <p class="text-sm font-light leading-none text-gray-600 mt-0.5 mb-4">
          Popunite sve neophodna polja
        </p>
        <div class="flex flex-col w-1/2">
          <img
            class="previewSelectedImage"
            src="https://via.placeholder.com/400x200"
            @error="handleImageError"
            alt="preview image"
          />
          <div class="w-full mx-auto pt-5">
            <div class="rounded relative w-72">
              <div
                class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
              >
                <div
                  class="text-gray-100 relative flex flex-row hover:cursor-pointer"
                >
                  <p
                    class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
                  >
                    Dodaj naslovnu sliku <i class="fas fa-edit ml-2"></i>
                  </p>

                  <input
                    type="file"
                    @change="fileChange"
                    class="fileInput absolute opacity-0"
                    required
                  />
                </div>
              </div>
            </div>
            <small class="text-gray-400">***Obavezna Polja</small><br />
            <span class="text-sm text-gray-400 uppercase"
              >dozvoljeni formati slika (jpg, jpeg, png, gif, webp)</span
            >
          </div>
          <div class="mt-16 flex flex-col xl:w-2/6 lg:w-1/2 md:w-1/2 w-full">
            <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
              Naslov
            </h2>
            <small class="text-gray-400">***Obavezna Polja</small><br />
            <input
              type="text"
              v-model="title"
              name="username"
              required
              class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              placeholder="Naslov Post-a"
            />
            <div class="flex justify-between items-center mb-8 mt-4">
              <div class="w-9/12">
                <h2
                  role="heading"
                  class="text-xl font-semibold text-gray-800 mt-5"
                >
                  Kategorija
                </h2>

                <p class="text-sm dark:text-gray-400">
                  <span
                    :class="!this.checkBox ? 'text-yellow' : 'text-gray-500'"
                    >Mreze</span
                  >
                  /
                  <span :class="this.checkBox ? 'text-yellow' : 'text-gray-500'"
                    >Servisi</span
                  >
                </p>
              </div>
              <div
                class="cursor-pointer rounded-full bg-gray-200 relative shadow-sm"
              >
                <input
                  type="checkbox"
                  name="email_comments"
                  v-model="checkBox"
                  class="focus:outline-none checkbox w-6 h-6 rounded-full bg-gray-200 shadow-md dark:bg-gray-400 absolute appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
                />

                <label
                  for="toggle1"
                  class="toggle-label block w-12 h-4 overflow-hidden rounded-full bg-gray-300 dark:bg-gray-800 cursor-pointer"
                >
                </label>
              </div>
            </div>
          </div>
          <div class="mt-8 flex flex-col xl:w-3/5 lg:w-1/2 md:w-1/2 w-full">
            <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
              Tekst
            </h2>
            <small class="text-gray-400">***Obavezna Polja</small><br />
            <textarea
              name="posttext"
              required
              class="bg-transparent border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-500 dark:text-gray-400"
              placeholder="Tekst post-a..."
              rows="5"
              v-model="postText"
            ></textarea>
            <p
              class="w-full text-right text-xs pt-1 text-gray-500 dark:text-gray-400"
            >
              Bice napomena kako fomratirati text...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container px-12 bg-white mt-10 rounded">
      <div class="w-full">
        <div class="flex flex-col justify-start">
          <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
            Autor
          </h2>
          <small class="text-gray-400">***Obavezna Polja</small><br />
        </div>
      </div>
      <div class="mx-auto my-4 border-b border-gray-200">
        <div class="mx-auto">
          <div class="mb-6 flex gap-8">
            <div class="flex flex-col mb-6">
              <label class="mb-3 text-sm leading-none text-gray-800">Ime</label>
              <input
                type="text"
                name="firstName"
                required
                class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
                placeholder=""
                v-model="firstname"
              />
            </div>
            <div class="xl:w-1/4 lg:w-1/2 md:w-1/2 flex flex-col mb-6">
              <label class="mb-3 text-sm leading-none text-gray-800"
                >Prezime</label
              >
              <input
                type="text"
                name="lastName"
                required
                class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
                v-model="lastname"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-12 bg-white mt-10 rounded">
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Galerija
      </h2>
      <small class="text-gray-400">***Nije obavezno polje</small><br />
      <div class="imagesPreview flex my-3">
        <image-holder
          v-for="(image, index) in trackGalleryImages"
          :key="index"
          :previewImage="image"
          :index="index"
        ></image-holder>
      </div>
      <div class="rounded relative w-72">
        <div
          class="flex items-center justify-center w-64 bg-gray-100 text-sm font-medium leading-none p-3 border rounded border-gray-200 cursor-pointer"
        >
          <div
            class="text-gray-100 relative flex flex-row hover:cursor-pointer"
          >
            <p
              class="text-base text-gray-800 whitespace-nowrap mr-2 flex items-center justify-center"
            >
              Dodaj slike <i class="fas fa-edit ml-2"></i>
            </p>

            <input
              type="file"
              multiple
              @change="galleryImages"
              class="fileInput absolute opacity-0"
              required
            />
          </div>
        </div>
        <small class="text-gray-400">***Obavezna Polja</small><br />
        <span class="text-sm text-gray-400 uppercase"
          >dozvoljeni formati slika (jpg, jpeg, png, gif, webp)</span
        >
      </div>

      <div class="bg-white my-10 rounded">
        <div
          class="w-full py-4 sm:px-0 bg-white dark:bg-gray-800 flex justify-start"
        >
          <button
            role="button"
            aria-label="Next step"
            @click="savePost"
            class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          >
            <span class="text-sm font-medium text-center text-yellow capitalize"
              >Objavi Pricu</span
            >
          </button>
        </div>
      </div>
      <!-- </div> -->
      <spinner :loading="this.isLoading"></spinner>
      <info-modal
        v-show="$store.getters.get_infoModalOn"
        :message="infoMessage"
      ></info-modal>
    </div>
  </div>
</template>

<script>
import TheTopBar from "./TheTopBar.vue";
import ImageHolder from "../../reusable/ImageHolder.vue";
import AddedPostSucces from "../../modals/AddedSuccess.vue";
import InfoModal from "../../modals/InfoModal.vue";
import Spinner from "../../reusable/TheSpinner.vue";

export default {
  components: { TheTopBar, ImageHolder, AddedPostSucces, InfoModal, Spinner },
  mounted() {
    // this.submit();
  },
  computed: {
    trackSucces() {
      return this.$store.getters.get_succes;
    },
    trackGalleryImages() {
      return this.$store.getters.get_galleryImages;
    },
  },
  data() {
    return {
      selectedImage: null,
      title: "",
      postText: "",
      firstname: "",
      lastname: "",
      checkBox: true,
      isLoading: false,
      infoMessage: "",
      gallery: null,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://via.placeholder.com/400x200";
    },
    galleryImages() {
      this.$store.commit(
        "SET_GALLERY_PREVIEW_IMAGES",
        Array.from(event.target.files)
      );
    },
    async savePost() {
      try {
        this.isLoading = true;
        if (
          this.selectedImage == null ||
          this.title == "" ||
          this.postText == "" ||
          this.firstname == "" ||
          this.lastname == ""
        ) {
          this.infoMessage = "Popunite sva obavezna polja";
          this.$store.commit("SET_INFO_MODAL", true);
          this.isLoading = false;
          return;
        }
        const cat = this.checkBox ? "servis" : "mreze";
        const formData = new FormData();
        // const gallery = this.$store.getters.get_galleryImages
        const photos = [];
        photos.push(this.selectedImage);

        if (this.$store.getters.get_galleryImages.length > 0) {
          for (
            let i = 0;
            i < this.$store.getters.get_galleryImages.length;
            i++
          ) {
            photos.push(this.$store.getters.get_galleryImages[i]);
          }
        }

        for (let i = 0; i < photos.length; i++) {
          formData.append("photos", photos[i]);
        }
        formData.append("photos", photos);
        formData.append("title", this.title);
        formData.append("text", this.postText);
        formData.append("name", this.firstname);
        formData.append("lastname", this.lastname);
        formData.append("category", cat);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }
        await this.$store.dispatch("addNewPost", formData);
        this.$store.commit("RESET_GALLERY");
        this.isLoading = false;
        this.selectedImage = null;
      } catch (error) {
        this.isLoading = false;
      }
    },
    fileChange(event) {
      const previewSelectedImage = document.querySelector(
        ".previewSelectedImage"
      );
      this.selectedImage = event.target.files[0];

      let reader = new FileReader();

      reader.onload = (e) => {
        previewSelectedImage.setAttribute("src", e.target.result);
      };

      reader.readAsDataURL(this.selectedImage);
    },
    deleteFile(e) {
      this.gallery.splice(e, 1);
      return this.gallery;
    },
  },
};
</script>

<style>
.checkbox:checked {
  right: 0;
}
.checkbox:checked + .toggle-label {
  @apply bg-yellow shadow-md;
}
.previewSelectedImage {
  width: 400px;
  height: 200px;
}
</style>
