<template>
    <section class="overflow-hidden max-w-7xl mx-auto">
        <div class="2xl:mx-auto 2xl:px-0 md:py-12 md:px-4 sm:py-9 sm:px-4">
            <h1 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 px-4 py-4 sm:px-0">
                Servisiranje</h1>

            <div class="px-4 pt-4 pb-10 sm:p-0 lg:w-10/12 w-full">
                <p class="my-5">Za naše klijente nudimo preuzimanje laptopova, PC računara i štampača sa adrese kako bi se
                    obavio servis.<br>
                    Ovu uslugu pružamo na teritoriji Beograda i obuhvata hardverske i softverske popravke uključujući i
                    sledeće
                    vrste servisnih usluga:</p>
                <ul class="my-5">
                    <li>• Servis desktop, laptop, notebook i netbook računara </li>
                    <li>• Servis monitora (LCD, TFT, LED, OLED) </li>
                    <li>• Zamena ekrana laptopva </li>
                    <li>• Servis štampača </li>
                    <li>• Čišćenje računara od virusa i instalacija antivirusa </li>
                    <li>• Instalacija operativnog sistema </li>
                    <li>• Instalacija Office paketa </li>
                    <li>• Popravka sistemskih fajlova </li>
                    <li>• Backup podataka </li>
                    <li>• Spašavanje fajlova sa oštećenih hard diskova itd. </li>
                </ul>
                <p class="my-5">Ako zbog bilo kog razloga odustanete od servisa, dolazak na adresu i vraćanje uređaja se
                    naplaćuje ukupno
                    1500dinara.<br>
                    Ako je cena servisa manja od 5000 dinara dolazak adresu i vraćanje uređaja se naplaćuje ukupno 750
                    dinara.<br>
                    Ako je cena servisa veća od 5000 dinara dolazak adresu i vraćanje uređaja se ne naplaćuje.</p>

                <p class="my-5">
                    Za sve dodatne informacije možete da nam pišete na<br>

                    info@dilektra.co.rs<br>

                    ili pozovete<br>

                    062/492-091
                </p>

            </div>
        </div>
    </section>
</template>
<script>
export default {
  metaInfo: {
    title: 'Servisiranje',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Za naše klijente nudimo preuzimanje laptopova, PC računara i štampača sa adrese kako bi se obavio servis. Ovu uslugu pružamo na teritoriji Beograda i obuhvata hardverske i softverske popravke'
      }
    ]
  },
};
</script>