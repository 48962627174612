<template>
  <article class="max-w-7xl mx-auto my-10">
    <div class="grid items-center grid-cols-1 lg:grid-cols-2">
      <div class="order-2 h-64 lg:order-1 lg:h-full">
        <img
          :src="imageSrc()"
          @error="imageErrorHandler"
          class="object-cover w-full h-full bg-center"
          alt="post image"
        />
      </div>
      <div
        class="order-1 w-full px-4 py-12 mx-auto text-left lg:w-3/4 lg:py-48 lg:order-2 lg:px-0"
      >
        <p class="mb-3 text-gray-500">
          <time>{{ postedOn(this.post.createdAt) }}</time>
          •
          <span class="capitalize text-yellow">{{ this.post.category }}</span>
        </p>
        <h1
          class="mb-5 text-3xl font-bold text-gray-900 md:leading-tight md:text-4xl"
          itemprop="headline"
          title="Rise of Tailwind - A Utility First CSS Framework"
        >
          {{ this.post.title }}
        </h1>
        <a class="flex items-center text-gray-700" href="#">
          <!-- <div class="avatar">
            <img src="https://via.placeholder.com/50x50" alt="author" />
          </div> -->
          <div class="flex">
            <p class="text-sm text-gray-500 mr-1">Autor •</p>

            <p class="text-sm font-semibold text-yellow">
              {{ this.post.name }} {{ this.post.lastname }}
            </p>
          </div>
        </a>
      </div>
    </div>
    <div class="p-4 md:py-20 mx-auto">
      <p v-html="this.post.text"></p>
    </div>
    <div
      v-if="this.showGallery"
      class="gallery"
    >
      <h2
        class="mb-5 text-3xl font-bold md:leading-tight md:text-4xl text-yellow"
        itemprop="headline"
        title="Rise of Tailwind - A Utility First CSS Framework"
      >
        Galerija
      </h2>

      <post-image-holder :images="this.post.gallery"></post-image-holder>
      <div class="w-full flex flex-row justify-center bg-white">
        <div
          v-for="(image, index) in this.post.gallery"
          :key="index"
          class="flex"
        >
          <img
            :src="$store.getters.get_API + '/images/' + image"
            :alt="image.url"
            class="transition-all transform hover:scale-95 demo cursor-pointer slide-images"
            @click="showDivs(index)"
          />
        </div>
      </div>
    </div>
    <the-spinner :loading="isLoading"></the-spinner>
  </article>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TimeHandler from "../../middleware/TimeHandler";
import TheSpinner from "./TheSpinner.vue";
import PostImageHolder from "../modals/PostImageHolder.vue";
export default {
  components: { TheSpinner, PostImageHolder },
  data() {
    return {
      post: {},
      isLoading: false,
      showGallery: false,
      counter: 1,
    };
  },
  async created() {
    await this.findPostByID();
  },
  computed: {
    ...mapGetters(["get_allPosts"]),
    ...mapActions(["getAllPosts"]),
  },
  methods: {
    async findPostByID() {
      this.isLoading = true;
      await this.$store.dispatch("getAllPosts");
      const postById = this.$store.getters.get_allPosts.find(
        (post) => post.post_id == this.$route.params.id
      );
      this.post = {
        title: postById.title,
        text: postById.text.replace(/\n/g, "<br />"),
        createdAt: postById.createdAt,
        image: postById.images[postById.images.length - 1],
        gallery: postById.images,
        name: postById.name,
        lastname: postById.lastname,
        category: postById.category,
      };
      if (this.post.gallery.length == 1 || this.post.gallery.length == 0) {
        this.post.gallery.length = 0;
        this.showGallery = false;
      } else {
        this.showGallery = true;
      }

      this.isLoading = false;
    },
    postedOn(time) {
      return TimeHandler.SET_DATE(time)
    },
    imageErrorHandler(event) {
      event.target.src = "https://via.placeholder.com/400x200";
    },
    imageSrc() {
      if (this.post.image === undefined) {
        return "https://via.placeholder.com/400x200";
      } else {
        return this.$store.getters.get_API + "/images/" + this.post.image;
      }
    },
    showDivs(n) {
      const slides = document.querySelectorAll(".mySlides");
      for (let i = 0; i < slides.length; i++) {
        slides[i].style = "opacity: 0";
      }
      slides[n].style = "opacity: 1";
    },
    plus() {
      this.counter;
      this.counter = this.counter + 1;
    },
    minus() {
      var preValue = document.getElementById("counter").value;
      if (parseInt(preValue) != 0) {
        this.counter = this.counter - 1;
      }
    },
  },
};
</script>

<style lang="scss">
.blog-slide {
  width: 100%;
  height: 400px;
}
.slide-images {
  width: 100%;
  height: 100px;
}
.gallery{
  @apply hidden flex-col gap-2 p-4;
  
  @media screen and (min-width: 640px) {
      @apply flex w-full;
  }

  @media screen and (min-width: 768px) {
      @apply w-11/12;
  }
  @media screen and (min-width: 885px) {
      @apply w-10/12;
  }
  @media screen and (min-width: 950px) {
      @apply w-9/12;
  }
  
  @media screen and (min-width: 1024px) {
      @apply w-8/12;
  }
   @media screen and (min-width: 1191px) {
      @apply w-7/12;
  }

  @media screen and (min-width: 1280px) {
      @apply w-7/12;
  }
}
</style>
