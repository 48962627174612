<template>
  <section class="bg-white">
    <div class="max-w-7xl py-8 px-3 mx-auto" >
      <BuildPc />
      <div class="flex" v-if="get_productOnPromotion.length > 3">
        <div class="flex flex-col justify-center items-start relative w-full">
          <h4
            class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800 text-center sm:text-left w-full"
          >
            Preporucujemo
          </h4>

          <button @click="goLeft('.product-row')" class="wl-1400">
            <i
              class="fas fa-chevron-left text-5xl text-yellow transition-colors"
            ></i>
          </button>
          <button @click="goRight('.product-row')" class="wr-1400">
            <i
              class="fas fa-chevron-right text-5xl text-yellow transition-colors"
            ></i>
          </button>
          <div class="product-row">
            <product
              class="product"
              v-for="product in get_productOnPromotion"
              :key="product.id"
              :id="product.id"
              :image="product.images[product.images.length - 1].url"
              :model="product.model"
              :title="product.title"
              :price="product.price"
              :actionPrice="product.actionPrice"
              :category="product.category"
              :needed="true"
            ></product>
          </div>
        </div>
        <!-- <div class="mx-auto px-4 md:px-6 2xl:px-0 flex justify-center">

        </div> -->
      </div>
      <div class="flex my-8" v-if="get_productOnAction.length > 3">
        <div class="flex flex-col justify-center items-start relative w-full">
          <h4
            class="text-3xl lg:text-4xl font-semibold leading-9 text-gray-800 text-center sm:text-left w-full"
          >
            Trenutno na Akciji
          </h4>

          <button
            @click="goLeft('.product-row-1')"
            class="absolute left-0 xl:-left-10 z-40"
          >
            <i class="fas fa-chevron-left text-5xl text-yellow"></i>
          </button>
          <button
            @click="goRight('.product-row-1')"
            class="absolute right-0 xl:-right-10 z-40"
          >
            <i class="fas fa-chevron-right text-5xl text-yellow"></i>
          </button>

          <div class="product-row-1">
            <product
              class="product"
              v-for="product in get_productOnAction"
              :key="product.id"
              :id="product.id"
              :image="product.images[product.images.length - 1].url"
              :model="product.model"
              :title="product.title"
              :price="product.price"
              :actionPrice="product.actionPrice"
              :category="product.category"
              :needed="true"
            ></product>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
          class="lg:mx-auto xl:px-4 md:px-6 2xl:px-0 flex "
        >
    </div> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import BuildPc from '../reusable/BuildPc.vue';
import Product from "./Product.vue";
export default {
  components: {
    Product, BuildPc
  },
  computed: {
    ...mapGetters(["get_productOnAction", "get_productOnPromotion"])
  },

  methods: {
    goLeft(cls) {
      let productrow = document.querySelector(cls);
      if (screen.availWidth < 400 && screen.availWidth > 320) {
        productrow.scrollLeft -= 332;
      } else if (screen.availWidth <= 320) {
        productrow.scrollLeft -= 262;
      } else {
        productrow.scrollLeft -= 312;
      }
    },
    goRight(cls) {
      let productrow = document.querySelector(cls);

      if (screen.availWidth < 400 && screen.availWidth > 320) {
        productrow.scrollLeft += 332;
      } else if (screen.availWidth <= 320) {
        productrow.scrollLeft += 262;
      } else {
        productrow.scrollLeft += 312;
      }
    },
  },
};
</script>

<style lang="scss">
.product-row,
.product-row-1 {
  @apply w-full max-w-7xl grid gap-3 overflow-x-auto my-3 py-10 xl:px-5 sm:px-0;
  grid-auto-flow: column;
  // grid-gap: 50px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  // border: 1px solid transparent;

  @media screen and (max-width: 400px) {
    padding: 40px 22px;
  }
  .product {
    width: 300px;
    height: auto;
    @media screen and (max-width: 1024px) {
      width: 320px;
    }
    @media screen and (max-width: 640px) {
      width: 320px;
    }
    @media screen and (max-width: 400px) {
      width: 320px;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }
}
.product-row::-webkit-scrollbar,
.product-row-1::-webkit-scrollbar {
  display: none;
}

.wl-1400 {
  @apply absolute left-0  z-40;
  @media screen and (min-width: 1366px) {
    @apply -left-10;
  }
}
.wr-1400 {
  @apply absolute right-0  z-40;
  @media screen and (min-width: 1366px) {
    @apply -right-10;
  }
}
</style>
