import { render, staticRenderFns } from "./Singin.vue?vue&type=template&id=ecae3908&scoped=true&"
import script from "./Singin.vue?vue&type=script&lang=js&"
export * from "./Singin.vue?vue&type=script&lang=js&"
import style0 from "./Singin.vue?vue&type=style&index=0&id=ecae3908&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecae3908",
  null
  
)

export default component.exports