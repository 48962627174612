<template>
  <transition name="mob-product-nav">
    <nav
      v-if="$store.getters.get_mobDrowdownMenu"
      class="mob-product-nav overflow-y-auto"
    >
      <div
        class="flex justify-between items-baseline mb-5 bg-gray-800 py-5 sticky top-0"
      >
        <router-link to="/" class="flex items-center pl-3">
          <img
            src="@/assets/logos/logo-big-white.svg"
            alt="Dilektra doo"
            class="logo"
          />
        </router-link>
        <button class="btn text-white" @click="closeMobCategories">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            aria-hidden="true"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          <span class="sr-only">Close Menu</span>
        </button>
      </div>
      <!--Linkovi kategorije-->
      <ul class="text-white overflow-y-auto flex flex-col justify-start">
        <li
          class="menu-item w-full sm:w-1/2 pl-3 text-lg"
          :id="c.name"
          v-for="(c, i) in get_catSelection"
          :key="i"
        >
          <a
            :href="'#' + c.name"
            class="flex justify-between items-center"
            v-if="c.children"
          >
            {{ c.name }}<i class="fas fa-angle-down text-yellow"></i>
          </a>
          <a
            :href="'#' + c.name"
            class="flex"
            v-if="!c.children"
            @click="categoryList(c.name)"
          >
            {{ c.name }}
          </a>
          <div class="menu-item__sub flex flex-col">
            <a
              v-for="(child,index) in c.children"
              :key="index"
              @click="categoryList(Object.values(child)[0])"
              >{{ Object.values(child)[0] }}</a
            >
          </div>
        </li>
      </ul>
    </nav>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      show: false,
      catSelection: [],
    };
  },
  computed:{
    ...mapGetters(['get_catSelection']),
  },
  methods: {
    closeMobCategories() {
      this.$store.commit("TOGGLE_MOB_DROPDOWN");
    },
    categoryList(categoryName) {
      this.$store.commit("TOGGLE_MOB_DROPDOWN");
      this.$store.commit("SET_CATEGORY_LIST", categoryName);
      this.$router
        .push({ name: "CategoryPage", params: { category: categoryName } })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.mob-product-nav {
  @apply bg-gray-800 fixed inset-0 w-full h-screen px-2 pb-5;
  z-index: 999;
}

.mob-product-nav-enter-active,
.mob-product-nav-leave-active {
  transition: all 0.5s ease-in-out;
}
.mob-product-nav-enter, .mob-product-nav-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
}

.logo {
  min-width: 150px;
}
.menu-item {
  @apply px-5 py-2;
}
.menu-item__sub {
  transition: max-height 0.35s;
  max-height: 0;
  overflow: hidden;
  a {
    display: block;
    position: relative;
  }
}
.menu-item:target .menu-item__sub {
  max-height: 1000px;
  @apply bg-gray-600 mt-1;
  a {
    @apply px-2 py-2 border-b border-gray-500 cursor-pointer;
  }
}
</style>
