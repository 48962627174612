<template>
  <div>
    <div v-if="trackProducts.length > 0" class="max-w-7xl mx-auto">
      <div class="flex flex-col justify-center items-center ml-4 mb-10">
        <div
          class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-between mt-4 gap-4"
        >
          <!-- lista proizvoda -->
          <product
            v-for="product in this.trackProducts"
            :key="product.id"
            :id="product.id"
            :image="product.images[product.images.length - 1].url"
            :model="product.model"
            :title="product.title"
            :price="product.price"
            :actionPrice="product.actionPrice"
            :category="product.category"
            :needed="true"
          ></product>
        </div>
      </div>
    </div>
    <div class="lg:flex lg:-mx-2" v-if="trackProducts.length < 1">
      <section class="px-4 pt-20 pb-32 mx-auto max-w-7xl">
        <div class="w-full lg:w-2/3">
          <p class="mb-3 text-base font-medium text-gray-700">
            Izvinjavamo Vam se na neprijatnosti. Pokusajte sa drugom pretgragom.
            <br />
            Vratite se na
            <router-link to="/" class="underline">pocetnu stranu</router-link>.
          </p>
        </div>
      </section>
    </div>
    <spinner :loading="start"></spinner>
  </div>
</template>

<script>
import Product from "../partials/Product.vue";
import Spinner from "../reusable/TheSpinner.vue";
export default {
  components: {
    Product,
    Spinner,
  },
  data() {
    return {
      start: false,
    };
  },
  computed: {
    trackProducts() {
      return this.$store.getters.get_searchList;
    },
  },
};
</script>

<style></style>
