<template>
  <div
    v-if="$store.getters.get_userShopingCart.length > 0"
    class="for-small-screens flex py-8 px-4 mt-6 border-b border-t border-gray-200"
  >
    <div class="shop-item-img">
      <img
        :src="$store.getters.get_API + '/images/' + image"
        alt="Black Leather Bag"
        class="sm:h-full object-center object-cover"
      />
    </div>

    <div class="sm:pl-3 w-8/12 lg:w-7/12 flex flex-col justify-center">
      <img
        :src="$store.getters.get_API + '/images/' + image"
        alt="Black Leather Bag"
        class="w-20 sm:hidden"
      />
      <p class="text-xs leading-3 text-gray-800 md:pt-0 pt-4">
        model: {{ model }}
      </p>

      <p class="text-base font-black leading-none text-gray-800">
        Naziv: {{ title }}
      </p>

      <p class="text-xs leading-3 text-gray-600 pt-2">EAN: {{ ean }}</p>
    </div>
    <div class="flex items-center pt-5 lg:w-3/12">
      <div class="flex flex-col gap-2">
        <p class="uppercase">kolicina</p>
        <div class="flex items-stretch">
          <span
            @click="minus()"
            class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-r-0 w-7 h-7 flex items-center justify-center pb-1"
            >-</span
          >
          <p
            class="border dark:text-white border-gray-300 dark:bg-transparent h-7 text-center w-14 pb-1"
          >
            {{ amount }}
          </p>
          <span
            @click="plus()"
            class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-l-0 w-7 h-7 flex items-center justify-center pb-1"
            >+</span
          >
        </div>
        <p class="text-base font-black leading-none text-gray-800">
          <span v-if="this.actionPrice == 0" class="uppercase"
            >cena <br />
            {{ trackPrice(this.price) }} RSD
          </span>
          <span v-if="this.actionPrice > 0" class="uppercase"
            >cena na akciji <br />
            {{ trackPrice(this.actionPrice) }} RSD</span
          >
        </p>
        <p
          @click="removeFromCart(index)"
          class="text-xs leading-3 underline text-red-500 cursor-pointer"
        >
          Izbaci iz liste
        </p>
      </div>
    </div>
    <TheSpinner :loading="start"></TheSpinner>
  </div>
</template>

<script>
import TheSpinner from "./TheSpinner.vue";
import FormPrice from "../../middleware/PriceHandler.js"
export default {
  props: [
    "ean",
    "model",
    "title",
    "price",
    "image",
    "id",
    "index",
    "amount",
    "actionPrice",
  ],
  data() {
    return {
      counter: Number(this.amount),
      start: false,
    };
  },
  methods: {
    removeFromCart(i) {
      this.start = true;
      this.$store.dispatch("removeShopItem", { index: i, product_id: this.id });
      this.start = false;
    },
    async plus() {
      this.counter += 1;
      this.start = true;
      await this.$store.dispatch("setProductQuantity", {
        id: this.id,
        quantity: this.counter,
      });
      this.start = false;
    },
    async minus() {
      this.counter -= 1;
      this.start = true;
      if (this.counter < 1) {
        this.counter = 1;
        this.start = false;
        return;
      }
      await this.$store.dispatch("setProductQuantity", {
        id: this.id,
        quantity: this.counter,
      });
      this.start = false;
    },
    trackPrice(price){
      return FormPrice(price)
    },
  },
  components: { TheSpinner },
};
</script>

<style lang="scss">
.for-small-screens {
  @media screen and (max-width: 320px) {
    @apply flex-col justify-center;
  }
}
.shop-item-img {
  @apply hidden sm:w-2/12 sm:flex sm:items-center;
}
</style>
