<template>
  <section class="px-4 py-20 mx-auto max-w-7xl">
    <div
      class="w-full mx-auto space-y-5 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12"
    >
      <div class="text-center pb-6">
        <h1 class="text-4xl font-semibold text-center text-yellow">
          Registruj se
        </h1>
        <p class="mt-3 text-center text-gray-500 dark:text-gray-300">
          Napravite svoj nalog
        </p>
      </div>
      <div class="space-y-4">
        <label class="block">
          <div class="flex justify-between">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Ime</span
            >
            <small class="text-red-500">{{
              name.length > 2 ? "" : errorMessage
            }}</small>
          </div>
          <input
            class="form-input"
            type="email"
            placeholder="Ex. Pera"
            inputmode="text"
            v-model="name"
            required
          />
        </label>

        <label class="block">
          <div class="flex justify-between">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Prezime</span
            >
            <small class="text-red-500">{{
              lastname.length > 2 ? "" : errorMessage
            }}</small>
          </div>
          <input
            class="form-input"
            type="email"
            placeholder="Ex. Peric"
            inputmode="text"
            v-model="lastname"
            required
          />
        </label>

        <label class="block">
          <div class="flex justify-between">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Telefon</span
            >
            <small class="text-gray-400">***Nije obavezno polje.</small>
          </div>
          <input
            class="form-input"
            type="tel"
            placeholder="Ex. 0652094581"
            inputmode="tel"
            v-model="phone"
          />
        </label>

        <label class="block">
          <div class="flex justify-between">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Vas Email</span
            >
            <small class="text-red-500">{{
              email.includes("@") && email.includes(".") ? "" : errorMessage
            }}</small>
          </div>
          <input
            class="form-input"
            type="email"
            placeholder="Ex. pera.peric@gmail.com"
            inputmode="email"
            v-model="email"
            required
          />
        </label>

        <label class="block">
          <div class="flex justify-between">
            <span class="block mb-1 text-xs font-medium text-gray-700"
              >Vas Password</span
            >
            <small class="text-red-500">{{
              this.error == true && 3 > password.length ? errorMessage : ""
            }}</small>
          </div>
          <input
            class="form-input"
            type="password"
            placeholder="••••••••"
            required
            v-model="password"
          />
          <small class="mt-2 text-gray-400"
            >Password mora imati najmanje 8 karaktera.</small
          >
        </label>

        <button @click="trySingup" class="singout mx-auto">
          <span class="text"> Registruj se </span>
        </button>
      </div>
      <div class="my-2 text-center">
        <span
          >Ako vec imate nalog idite na
          <router-link
            to="/singin"
            class="text-sm text-gray-700 underline hover:text-yellow-dark"
            >Prijavi se</router-link
          ></span
        >
      </div>
    </div>
    <success-pop
      :show="this.$store.getters.get_showModal"
      :message="'Molimo vas da verifikujete svou email adresu.'"
    >
    </success-pop>
    <the-spinner :loading="isLoading"></the-spinner>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import SuccessPop from "../components/modals/SuccessPop.vue";
import TheSpinner from "../components/reusable/TheSpinner.vue";
export default {
  metaInfo: {
    title: 'Registruj se'
  },
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      error: false,
      errorMessage: "",
      isLoading: false,
    };
  },
  components: {
    SuccessPop,
    TheSpinner,
  },
  computed: {
    ...mapGetters(["get_message"]),
  },
  methods: {
    async trySingup() {
      try {
        this.isLoading = true;

        const newUser = {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          phone: this.phone,
        };

        const inputs = document.querySelectorAll(".form-input");
        inputs.forEach((input) => {
          if (
            (input.value == "" && input.name != "tel") ||
            input.value.length > 0
          ) {
            this.error = true;
            this.errorMessage = "Obavezno polje.";
            return input.classList.add("error-input");
          } else {
            this.error = false;
          }
        });

        await this.$store.dispatch("trySingup", newUser);
        this.isLoading = false;
      } catch (error) {
        this.error = true;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-input {
  @apply placeholder-red-500;
}

.singout {
  @apply flex items-center justify-center border border-yellow py-2 px-4 rounded-md;
  .text {
    @apply text-sm text-center text-yellow uppercase;
    
  }
  &:hover > .text,  &:hover > i{
    @apply text-yellow;
  }
  
}

</style>