import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// import store
import store from '../store/index.js'

//import views & commponents
// import Home from '../views/Home.vue'
import Singin from '../views/Singin.vue'
import Singup from '../views/Singup.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Complaints from '../views/Complaints.vue'
import Services from '../views/Services.vue'
import Shop from '../views/Shop.vue'
import Delivery from '../views/Delivery.vue'
import EquipmentServicing from '../views/EquipmentServicing.vue'
import PayingProccess from '../views/PayingProccess.vue'
import CategoryPage from '../components/reusable/CategoryPage.vue'
import ProductPage from '../components/reusable/ProductPage.vue'
import ShopLandingPage from '../components/partials/ShopLandingPage.vue'
import ShopingCart from '../components/reusable/ShopingCart.vue'
import ForgotPassword from '../components/layout/ForgotPassword.vue'
import TheVerify from '../components/layout/TheVerify.vue'

import Blog from '../views/Blog.vue'

import BlogPostPage from '../components/reusable/BlogPostPage.vue'

import NotFound from '../components/layout/404.vue'

import TheSearchResult from "../components/layout/TheSearchResult.vue";


import CMS from '../views/Dilektra-CMS.vue'
import AddNewProduct from '../components/cms-components/partials/AddNewProduct.vue'
import AddNewPost from '../components/cms-components/partials/AddNewPost.vue'
import EditPost from '../components/cms-components/partials/EditPost.vue'
import EditProduct from '../components/cms-components/partials/EditProduct.vue'
import TheDashboard from '../components/cms-components/dashboard/TheDashboard.vue'


const routes = [{
    path: '/',
    redirect: '/prodavnica',
    name: 'Home'
  },
  {
    path: '/prodavnica',
    component: Shop,
    children: [{
        path: '/',
        name: 'ShopLandingPage',
        component: ShopLandingPage,
      },
      {
        path: ':category',
        name: 'CategoryPage',
        component: CategoryPage,
      },
      {
        path: ':category/product/:id',
        name: 'ProductPage',
        component: ProductPage
      },
    ],
    
  },
  {
    path: '/pretraga',
    name: 'TheSearchResult',
    component: TheSearchResult
  },
  {
    path: '/onama',
    name: 'About',
    component: About
  },
  {
    path: '/mogucnosti-placanja',
    name: 'PayingProccess',
    component: PayingProccess
  },
  {
    path: '/dostava',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/singin',
    name: 'Singin',
    component: Singin
  },
  {
    path: '/singup',
    name: 'Singup',
    component: Singup
  },
  {
    path: '/zaboravljena-sifra',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/korpa',
    name: 'ShopingCart',
    component: ShopingCart,
    beforeEnter: (to, from, next) => {
      if (store.getters.get_isValid) next()
      else next('/')
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    beforeEnter: (to, from, next) => {
      if(store.getters.get_allPosts.length > 1){
        next()
      }else{
        next('/about')
      }
    }
  },
  {
    path: '/blog/:id',
    name: 'BlogPostPage',
    component: BlogPostPage
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/servisiranje-opreme',
    name: 'EquipmentServicing',
    component: EquipmentServicing
  },
  {
    path: '/reklamacije',
    name: 'Complaints',
    component: Complaints
  },
  {
    path: '/usluge',
    name: 'Services',
    component: Services
  },
  {
    path: '/verify/:_id',
    name: 'Verify',
    component: TheVerify
  },
  {
    path: '/dilektra-cms',
    component: CMS,
    meta: {
      isAdmin: true
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.get_isAdmin == null || store.getters.get_isAdmin === false) next('/')
      else next()
    },
    children: [{
        path: '/',
        name: 'CMS',
        component: TheDashboard
      },
      {
        path: 'products',
        component: () =>
          import(
            /* webpackChunkName: "products" */
            '../components/cms-components/lists/ProductList.vue'
          )
      },
      {
        path: 'promotions',
        component: () =>
          import(
            /* webpackChunkName: "promotions" */
            '../components/cms-components/partials/Promotions.vue'
          )
      },
      {
        path: 'products/add',
        component: AddNewProduct,
        name: 'AddNewProduct'
      },
      {
        path: 'products/edit/:id',
        component: EditProduct,
        name: 'EditProduct'
      },
      {
        path: 'blog',
        component: () =>
          import(
            /* webpackChunkName: "blog" */
            '../components/cms-components/lists/BlogList.vue'
          )
      },
      {
        path: 'blog/add',
        component: AddNewPost,
        name: 'AddNewPost'
      },
      {
        path: 'blog/edit/:id',
        name: 'EditPost',
        component: EditPost
      },
      {
        path: 'users',
        component: () =>
          import(
            /* webpackChunkName: "users" */
            '../components/cms-components/lists/UsersList.vue'
          )
      },
      {
        path: 'orders',
        component: () =>
          import(
            /* webpackChunkName: "orders" */
            '../components/cms-components/lists/OrderList.vue'
          )
      },
    ]
  },
  {
    path: '*',
    name: 'Notfound',
    component: NotFound,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      y: 0,
      behavior: 'smooth',
    }
  }
})

//hidde and show navbar and footer
router.beforeEach((to, _, next) => {
  if (to.path.includes('cms')) {
    store.commit('SET_IS_ROUTE_CMS', true)
    next()
  } else {
    store.commit('SET_IS_ROUTE_CMS', false)
    next()
  }

})

export default router