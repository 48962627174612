import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


//modules
import auth from './modules/auth/index.js'
import products from './modules/cms/products/index.js'
import blog from './modules/cms/blog/index.js'
import users from './modules/cms/users/index.js'
import promotion from './modules/cms/promotions/index.js'
import shopingCart from './modules/shop/index.js'
import orders from './modules/cms/orders/index.js'


export default new Vuex.Store({
  state: {
    isRouteCms: false,
    notificationStatus: false,
    dropdownMenu: false,
    mobDrowdownMenu: false,
    API: process.env.VUE_APP_ROOT_API,
    time: '',
    exactTime: '',
  },
  mutations: {
    SET_IS_ROUTE_CMS(state, payload) {
      state.isRouteCms = payload
    },
    SET_NOTIFICATION_STATUS(state, payload) {
      state.notificationStatus = payload
    },
    TOGGLE_DROPDOWN_MENU(state) {
      state.dropdownMenu = !state.dropdownMenu
    },
    TOGGLE_MOB_DROPDOWN(state) {
      state.mobDrowdownMenu = !state.mobDrowdownMenu
    },
  },
  getters: {
    get_notificationStatus(state) {
      return state.notificationStatus
    },
    get_dropdownMenu(state) {
      return state.dropdownMenu
    },
    get_mobDrowdownMenu(state) {
      return state.mobDrowdownMenu
    },
    get_API(state) {
      return state.API
    },

  },
  modules: {
    auth,
    products,
    blog,
    users,
    promotion,
    shopingCart,
    orders
  }
})