<template>
  <div class="prod">
    <div class="relative flex justify-center items-cente mx-auto">
      
        <img
          :src="$store.getters.get_API + '/images/' + image"
          :alt="model"
          class="img-w img-h cursor-pointer"
          @click="$router.push({ name: 'ProductPage', params: { category, id } })"
        />
    
      <button
        v-if="needed"
        @click="addToCart(id)"
        class="absolute top-4 right-4 flex justify-center items-center p-3.5 bg-gray-100 rounded-full transition-all transform hover:scale-125 hover:text-yellow"
      >
        <i class="fas fa-shopping-cart"></i>
      </button>
    </div>
    <div class="flex flex-col items-start jusitfy-start mt-3 space-y-3 w-full">
      <div class="w-full">
        <router-link
          :to="{ name: 'ProductPage', params: { category, id } }"
          class="cursor-pointer my-2"
        >
          <p
            class="text-lg text-center capitalize sm:text-left font-medium leading-5 text-gray-800 transition-all hover:text-yellow h-10 w-full"
          >
            {{ title }}
          </p>
          <p
            class="text-lg mt-16 text-center sm:text-left font-light leading-3 text-gray-800"
          >
            {{ model }}
          </p>
        </router-link>
      </div>
      <div class="relative pb-8 w-full text-center sm:text-left">
        <p class="text-lg my-2 leading-4 text-gray-600">
          <span v-if="actionPrice == 0"> RSD {{ trackPrice(price)}}</span>
          <span v-if="actionPrice > 0" class="text-gray-400 text-xs"
            >RSD <del>{{ trackPrice(price) }}</del></span
          >
        </p>
        <div class="w-full flex justify-center sm:justify-start">
          <p
            v-if="actionPrice > 0"
            class="flex justify-start flex-col mb-5 w-36"
          >

            <span class="tag">{{ trackPrice(actionPrice) }}&nbsp;RSD</span>
          
          </p>
        </div>
        <!-- <p v-if="actionPrice > 0">ŠOK CENA!!!</p> -->
      </div>
      <small
        class="relative bottom-5 w-full text-center sm:text-left text-gray-500"
      >
        *Cene su prikazane u RSD sa PDV-om</small
      >
    </div>
    <the-spinner :loading="start"></the-spinner>
  </div>
</template>

<script>
import TheSpinner from "../reusable/TheSpinner.vue";
import FormPrice from "../../middleware/PriceHandler.js"
export default {
  components: { TheSpinner },
  props: [
    "image",
    "title",
    "model",
    "price",
    "actionPrice",
    "id",
    "category",
    "needed",
  ],
  data() {
    return {
      start: false,
    };
  },
  methods: {
    addToCart(id) {
      this.start = true;
      if (!this.$store.getters.get_isValid) {
        this.$store.commit("SET_SINGIN_MODAL", true);
        this.start = false;
        return;
      } else {
        this.$store.dispatch("addShopItem", {
          id,
          email: this.$store.getters.get_user.email,
        });
        this.$store.commit("SET_NOTIFICATION_STATUS", true);
        this.start = false;
      }
      // this.$store.commit('FILTER_SHOPING_CART')
    },
    trackPrice(price){
        return FormPrice(price)
      },
  },
};
</script>

<style lang="scss">
.prod {
  @apply flex items-start flex-col py-4 px-6 transition-all relative;
  box-shadow: none;
  @media screen and (min-width: 1025px) {
    @apply border border-gray-200;
  }
  &:hover {
    @media screen and (min-width: 1025px) {
      box-shadow: 0px 0px 25px -10px rgba(36, 37, 38, 0.7);
    }
    @media screen and (max-width: 1024px) {
      box-shadow: none;
      border: none;
    }
  }
}
.tag {
  @apply inline-block w-auto bg-red-500 relative text-white font-bold;

  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  border-left: 1px solid red;
  margin-left: 19px;
  line-height: 38px;
  padding: 0 15px 0 5px;
  height: 38px;
}

/* Makes the triangle */
.tag:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid rgb(239, 68, 68);
}

/* Makes the circle */
.tag:after {
  content: "";
  background-color: white;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}

.img-w{
  max-width: 250px;
  min-width: 250px;
  width: 100%;
}
.img-h{
  min-height: 200px;
  max-height: 200px;
  height: 100%;
}
</style>
