<template>
  <div v-if="trackShow" style="min-height: 640px">
    <div
      class="py-12 bg-gray-600 bg-opacity-50 transition duration-150 ease-in-out fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center"
      ref="modal"
    >
      <div
        role="alert"
        class="w-11/12 md:w-2/3 max-w-lg flex justify-center"
      >
        <div
          class="relative w-11/12 sm:w-8/12 md:w-9/12 bg-white dark:bg-gray-800 shadow pt-10 pb-8 rounded"
        >
          <div class="flex flex-col items-center px-4 md:px-12">
            <img src="@/assets/logos/logo-small.svg" class="w-10" />
            <p v-if="text.length == 0"
              class="text-base sm:text-lg md:text-2xl uppercase font-bold md:leading-6 mt-6 text-gray-800 text-center dark:text-gray-100"
            >
             Vasa Porudzbina<br> je primljena
            </p>
            <p v-else
              class="text-base sm:text-lg md:text-2xl uppercase font-bold md:leading-6 mt-6 text-gray-800 text-center dark:text-gray-100"
            >
             Vasa Korpa<br> je prazna
            </p>
            <p v-if="text.length == 0"
              class="text-xs sm:text-sm leading-5 mt-2 sm:mt-4 text-center text-gray-600 dark:text-gray-300"
            >
              Uskoro ce vam stici porudzbenica na mail.
            </p>
            <p v-else
              class="text-xs sm:text-sm leading-5 mt-2 sm:mt-4 text-center text-gray-600 dark:text-gray-300"
            >
              {{text}}
            </p>
           
          </div>
          <div
            class="cursor-pointer absolute top-0 right-0 m-3 text-gray-800 dark:text-gray-100 transition duration-150 ease-in-out"
            @click="modalHandler()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Close"
              class="icon icon-tabler icon-tabler-x"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  props: ["show", "text"],
  computed: {
    trackShow() {
      return this.$store.getters.get_showOrderModal
    },
  },
  methods: {
    modalHandler(val) {
      let modal = this.$refs.modal;
      if (val) {
        this.fadeIn(modal);
        this.$store.commit("TOGGLE_MODAL", true);
      } else {
        this.fadeOut(modal);
        this.$store.commit("TOGGLE_MODAL", false);
      }
    },
    fadeOut(el) {
      el.style.opacity = 1;
      (function fade() {
        if ((el.style.opacity -= 0.1) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    },
    fadeIn(el, display) {
      el.style.opacity = 0;
      el.style.display = display || "block";
      (function fade() {
        let val = parseFloat(el.style.opacity);
        if (!((val += 0.2) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
        }
      })();
    },
  },
};
</script>

<style>
.checkbox:checked + .check-icon {
  display: flex;
}
</style>
