import axios from 'axios'
import errorHandler from '../../../../middleware/ErrorHandler'

const products = {
    state: {
        promotionId: undefined,
        promotionActive: false,
        promotionProducts: [],
        previewProduct: [],
        productForPromotion: []
    },
    mutations: {
        SET_PROMOTION(state, payload) {
            state.promotionId = payload.promotionID
            if (state.promotionId !== undefined) {
                state.promotionActive = true
            } else {
                state.promotionActive = false
            }
        },
        SET_PROMOTION_PRODUCT(state, payload) {
            state.promotionProducts = payload
        },
        SET_PREVIEW_PRODUCTS(state, payload) {
            state.previewProduct.push(payload[0])
        },
        SET_PRODUCT_FOR_PROMOTION(state, payload) {
            state.productForPromotion.push(payload)
        },
        CLOSE_PROMOTION_MODAL(state, payload) {
            state.promotionActive = payload
        },
        RESET_PROMOTION_STATUS(state) {
            state.promotionId = undefined
            state.promotionActive = false
            state.previewProduct = []
            state.productForPromotion = []
        },
        RESET_PREVIEW(state){
            state.previewProduct = []
            state.productForPromotion = []
        },
        REMOVE_FROM_PROMO_LIST(state, payload) {
            state.productForPromotion.splice(payload, 1)
            state.previewProduct.splice(payload, 1)
        }

    },
    actions: {
        async getPromotion(context) {
            try {
                const response = await axios.get(context.rootGetters.get_API + '/actions')
                context.commit('SET_PROMOTION', response.data)
                context.dispatch('getPromotionProducts', response.data.promotionID)
            } catch (error) {
                 errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async getPromotionProducts(context, payload) {
            try {
                const response = await axios.get(context.rootGetters.get_API +'/actions/' + payload + '/products')
                context.commit('SET_PROMOTION_PRODUCT', response.data)
            } catch (error) {
                 errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async addPromotion(context, payload) {
            try {
                console.log(payload);
                const response = await axios.post(context.rootGetters.get_API +'/actions/add', payload, context.rootGetters.get_headers)
                context.commit('SET_PROMOTION', response.data)
                context.dispatch('getPromotionProducts', response.data.promotionID)

            } catch (error) {
                 errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async deletePromotion(context, payload) {
            try {
                await axios.delete(context.rootGetters.get_API +'/actions/' + payload, context.rootGetters.get_headers)
                context.commit('SET_PROMOTION_PRODUCT', [])
                context.commit('RESET_PROMOTION_STATUS')
            } catch (error) {
                 errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
    },
    getters: {
        get_promotionActive(state) {
            return state.promotionActive
        },
        get_promotionId(state) {
            return state.promotionId
        },
        get_promotionProducts(state) {
            return state.promotionProducts
        },
        get_previewProduct(state) {
            return state.previewProduct
        },
        get_productForPromotion(state){
            return state.productForPromotion
        }

    }

}

export default products