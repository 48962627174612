<template>
  <div class="hover-effect relative w-52 h-52 mx-1">
    <img
      :src="trackImage"
      alt="image"
      ref="img"
      class="absolute inset-0 w-full"
    />
    <button
      v-if="$route.path.includes('/dilektra-cms/products')"
      @click="removeProductFile(index)"
      class="absolute top-2 right-2"
    >
      <i class="fas fa-times"></i>
    </button>
    <button
      v-if="$route.path.includes('/dilektra-cms/blog')"
      @click="removeBlogFile(index)"
      class="absolute top-2 right-2"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ["previewImage", "index", "productID"],

  computed: {
    trackImage() {
      if (typeof this.previewImage.url === "string") {
        return this.$store.getters.get_API + "/images/" + this.previewImage.url;
      }
      if (this.previewImage instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.img.setAttribute("src", e.target.result);
        };
        return reader.readAsDataURL(this.previewImage);
      }
      return this.previewImage;
    },
  },
  methods: {
    removeProductFile(i) {
      if (typeof this.previewImage.url === "string") {
        this.$store.dispatch("deleteImageById", {
          id: this.previewImage.id,
          productID: this.productID,
          index: i,
        });
      }
      if (this.previewImage instanceof File) {
        this.$store.commit("DELETE_FROM_PREVIEW_IMAGES", i);
        this.$store.commit("DELETE_FROM_PRODUCT_IMAGES", i);
      }
    },
    removeBlogFile(i) {
      if (typeof this.previewImage.url === "string") {
        this.$store.dispatch("deleteBlogImageById", {
          id: this.previewImage.id,
          blogID: this.productID,
          index: i,
        });
      }
      if (this.previewImage instanceof File) {
        this.$store.commit("DELETE_FROM_ONE_POST_IMAGES", i);
        this.$store.commit("DELETE_FROM_GALLERY_PREVIEW_IMAGES", i);
      }
    },
  },
};
</script>
<style lang="scss">
.hover-effect {
  &:hover {
    img {
      @apply shadow-lg;
    }
  }
}
</style>
