<template>
  <div class="dark:bg-gray-900">
    <div class="pb-20">
      <div class="mx-auto bg-gradient-to-l from-yellow-200 to-yellow h-60">
        <div
          class="mx-auto container w-full flex flex-col justify-center items-center"
        >
          <div class="flex justify-center items-center flex-col">
            <div class="mt-10">
              <h2
                class="lg:text-6xl md:text-5xl text-4xl font-black leading-10 text-white"
              >
                Opšte Informacije
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mx-auto container md:-mt-28 -mt-20 flex justify-center items-center"
      >
        <div
          class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-x-2 gap-y-2 lg:gap-x-6 md:gap-x-6 md:gap-y-6"
        >
          <div
            class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl"
          >
            <h2
              class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800"
            >
              {{ this.$store.getters.get_allUsers.length }}
            </h2>
            <p
              class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600"
            >
              Korisnika
            </p>
          </div>
          <div
            class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl"
          >
            <h2
              class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800"
            >
              {{ this.$store.getters.get_allProducts.length }}
            </h2>
            <p
              class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600"
            >
              Proizvoda
            </p>
          </div>
          <div
            class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl"
          >
            <h2
              class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800"
            >
              {{ this.$store.getters.get_allOrders.length }}
            </h2>
            <p
              class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600"
            >
              Porudzbina
            </p>
          </div>
            <div
            class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl"
          >
            <h2
              class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800"
            >
              {{ this.$store.getters.get_promotionProducts.length }}
            </h2>
            <p
              class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600"
            >
              Proizvoda na promociji
            </p>
          </div>
            <div
            class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl"
          >
            <h2
              class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800"
            >
              {{ this.$store.getters.get_allPosts.length }}
            </h2>
            <p
              class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600"
            >
              Blog postova
            </p>
          </div>
        </div>
      </div>
    </div>
    <the-spinner :loading="start"></the-spinner>
  </div>
</template>

<script>
import TheSpinner from '../../../reusable/TheSpinner.vue';
export default {
  components: { TheSpinner },
  name: "MyComponent",
  data(){
    return{
      start: false
    }
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      this.start = true
      await this.$store.dispatch("getAllPosts");
      await this.$store.dispatch("getAllOrders");
      await this.$store.dispatch("getAllProducts");
      await this.$store.dispatch("getPromotion");
      await this.$store.dispatch("getAllUsers");
       this.start = false
    },
  },
};
</script>

<style scoped></style>
