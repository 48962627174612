<template>
  <!-- start of post -->
  <div>
    <router-link
      :to="{ name: 'BlogPostPage', params: { id } }"
      class="text-gray-900 hover:text-purple-700"
    >
      <img
        :src="$store.getters.get_API + '/images/' + images"
        @error="imageErrorHandler"
        class="object-cover w-full h-56 mb-5 bg-center rounded"
        alt="Kutty"
        loading="lazy"
      />
    </router-link>
    <router-link
      :to="{ name: 'BlogPostPage', params: { id } }"
      class="text-gray-900 hover:text-purple-700"
    >
      <h2
        class="mb-2 text-lg font-semibold text-gray-700 hover:text-yellow-light"
      >
        {{ title }}
      </h2>
    </router-link>
    <p class="mb-3 text-sm font-normal text-gray-500">
      {{ this.reduceText(text) }}
    </p>
    <p class="mb-3 text-sm font-normal text-gray-500">
      <span class="font-medium text-yellow hover:text-yellow-light capitalize"
        >{{ name }} {{ lastname }}</span
      >

      • {{ this.postedOn(time) }} •
      <span class="capitalize text-yellow">{{ category }}</span>
    </p>
  </div>
  <!--end od post-->
</template>
<script>
import TimeHandler from '../../middleware/TimeHandler';
export default {
  props: [
    "name",
    "lastname",
    "time",
    "category",
    "title",
    "text",
    "images",
    "id",
  ],
  methods: {
    postedOn(time) {
      return TimeHandler.SET_DATE(time)
    },
    reduceText(text) {
      return text.substring(100, text.lenght - 1) + "...";
    },
    imageErrorHandler(event) {
      event.target.src = "https://via.placeholder.com/400x200";
    },
  },
};
</script>
