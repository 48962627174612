<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="container  mx-auto max-w-7xl">
      <div class="flex justify-between px-4 items-center">
        <breadcrumps></breadcrumps>
        <button
          @click="showFilters"
          class="border border-gray-300 px-4 py-2 rounded-md whitespace-nowrap lg:hidden"
        >
          Filteri <i class="fas fa-filter text-yellow"></i>
        
        </button>
      </div>
      <div class="lg:flex lg:-mx-2">
        <div
          class="2xl:mx-auto 2xl:container px-4 md:px-6 2xl:px-0 lg:flex lg:justify-start lg:items-start">
          <!-- podcinju fileteri -->

          <div class="hidden lg:relative lg:top-0 lg:left-0 lg:w-350 lg:flex">
            <div class="block relative py-6 px-4 bg-gray-50">
              <div class="flex justify-start items-start">
                <h2 class="text-3xl font-semibold leading-6 text-gray-800 whitespace-nowrap">
                  {{ this.$route.params.category }}
                </h2>
              </div>

              <hr class="bg-gray-200 w-full my-6" />
              <div>
                <h6
                    class="lg:text-2xl text-xl leading-5 font-medium text-gray-800"
                >
                  Sortiraj
                </h6>
                <button @click="sortByName">Po nazivu A-Z</button>
                <hr class="bg-gray-200 w-full my-6" />
              </div>
              <div>
                <h6
                    class="lg:text-2xl text-xl leading-5 font-medium text-gray-800"
                >
                  Cene
                </h6>
                <div class="mb-4">
                  <button @click="filterProductsByPriceGoUp">Po ceni rasuće</button><br />
                  <button @click="filterProductsByPriceGoDown">Po ceni padajuće</button>
                </div>
                <div>
                  <p class="flex justify-between text-gray-400"><span class="text-sm">{{trackPrice(minProductPrice)}}RSD</span> <span class="text-sm">{{trackPrice(maxProductPrice)}}RSD</span></p>
                  <input type="range" id="volume" name="volume"  :min="minProductPrice" :max="maxProductPrice" v-model="selectPrice" @change="trackPriceChange" class="w-full">
                  <p class="text-gray-400 text-sm">{{ new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(selectPrice)}}RSD</p>
                </div>
                <hr class="bg-gray-200 w-full my-6" />
              </div>
              <!-- filteri -->
              <div v-for="filter in trackActiveFilters" :key="filter.name" >
                <div v-if="$route.params.category === filter.name">
                  <div v-for="(f, index) in filter.list" :key="index" >
                    <div class="flex">
                      <h6
                        class="lg:text-2xl text-xl leading-5 font-medium text-gray-800 capitalize"
                      >
                        {{ f.title }}
                      </h6>
                    </div>
                    <ol class="filteri flex flex-col justify-start">
                      <li
                        class="flex items-center justify-between my-1"
                        v-for="str in f.children"
                        :key="str"
                      >
                        <button v-if="setFileterLength(str) > 0" @click="setFilter(str)" class="mr-2 text-sm leading-3 font-normal text-gray-900 uppercase whitespace-nowrap">{{str}}</button>
                        <button v-if="setFileterLength(str) === 0" class="mr-2 text-sm leading-3 font-normal text-gray-400 uppercase whitespace-nowrap">{{str}}</button>
                         <span>({{ setFileterLength(str)}})</span>
                      </li>
                    </ol>
                    <hr class="bg-gray-200 w-full my-6" />
                  </div>
                </div>
              </div>
              <!-- zavrsavaju se fileteri -->
            </div>
          </div>

          <!-- filteri tablet i mob-->
           <transition name="mob-category-nav">
          <div
            class="fixed w-full inset-0 bg-gray-800 z-50 overflow-y-auto px-4"
            v-if="showFiltersNav"
          >

            <div
              class="flex justify-between items-baseline mb-5 bg-gray-800 py-5 sticky top-0"
            >
              <router-link to="/" class="flex items-center pl-3">
                <img
                  src="@/assets/logos/logo-big-white.svg"
                  alt="Dilektra doo"
                  class="logo"
                />
              </router-link>
              <button class="btn text-white" @click="showFilters">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  aria-hidden="true"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div class="mt-1 mb-4">
              <h6
                  class="lg:text-2xl text-xl leading-5 font-medium text-gray-100 my-2"
              >
                Sortiraj
              </h6>
              <button @click="sortByName" class="text-gray-100">Po nazivu A-Z</button>

            </div>
            <div class="mt-1 mb-4">
              <h6
                  class="lg:text-2xl text-xl leading-5 font-medium text-gray-100 my-2"
              >
                Cene
              </h6>

              <div class="mb-4">
                <button @click="filterProductsByPriceGoUp" :class="isMobilePriceToggle ?'text-yellow' :'text-gray-100'">Po ceni rasuće</button><br />
                <button @click="filterProductsByPriceGoDown" :class="!isMobilePriceToggle ?'text-yellow' :'text-gray-100'">Po ceni padajuće</button>
              </div>
              <div>
                <p class="flex justify-between text-gray-400"><span class="text-sm">{{trackPrice(minProductPrice)}}RSD</span> <span class="text-sm">{{trackPrice(maxProductPrice)}}RSD</span></p>
                <input type="range" id="volume" name="volume"  :min="minProductPrice" :max="maxProductPrice" v-model="selectPrice" @change="trackPriceChange" class="w-full">
                <p class="text-gray-400 text-sm">{{ new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(selectPrice)}}RSD</p>
              </div>
            </div>

            <div v-for="filter in trackActiveFilters" :key="filter.name">
              <div v-if="$route.params.category == filter.name">
                <div v-for="(f, index) in filter.list" :key="index">
                  <div class="flex flex-col">
                    <h6
                      class="lg:text-2xl text-xl leading-5 font-medium text-gray-100 capitalize"
                    >
                      {{ f.title }}
                    </h6>
                  </div>
                  <ol class="filteri flex flex-col justify-start mb-4">
                    <li
                      class="flex items-center justify-start my-1"
                      v-for="str in f.children"
                      :key="str"

                    >
                      <button v-if="setFileterLength(str) > 0" @click="setFilter(str)" class="mr-2 text-sm leading-3 font-normal text-gray-400 uppercase whitespace-nowrap">{{str}}</button>
                      <button v-if="setFileterLength(str) === 0" class="mr-2 text-sm leading-3 font-normal text-gray-600 uppercase whitespace-nowrap">{{str}}</button>
                      <span class="text-white">({{ setFileterLength(str)}})</span>

                    </li>
                  </ol>
                 
                </div>
              </div>
            </div>
            <button @click="showFilters" class="px-4 py-2 border border-yellow text-yellow my-5 rounded-md capitalize">Primeni filtere</button>
          </div>
          </transition>
          <hr class="hidden bg-gray-200 w-full my-6" />
          <!-- zavrsavaju se fileteri tablet i mob -->

          <div
            class="flex flex-col justify-center items-center ml-4 mb-10"
            v-if="this.get_categoryList.length > 0"
          >
            <div class="flex flex-row gap-2  flex-wrap w-full ">
              <div v-for="(filter, index) in activeFilters" :key="index" class="border border-yellow-dark px-4 py-2 flex flex-row flex-nowrap">
               <span @click="removeFilter(index)" class="text-sm whitespace-nowrap"> {{filter}} <i class="fas fa-times text-"></i></span>
              </div>
            </div>
              <div
              class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-items-between mt-4 gap-6"
            >
              <!-- lista proizvoda -->
              <product
                v-for="product in trackProducts.slice(
              this.startPosition,
              this.endPosition
            )"
                :key="product.id"
                :id="product.id"
                :image="product.images[product.images.length - 1].url"
                :model="product.model"
                :title="product.title"
                :price="product.price"
                :actionPrice="product.actionPrice"
                :category="product.category"
                :needed="true"
              ></product>
            </div>
            <hr class="bg-gray-700 w-full my-3" />
            <div class="flex justify-center md:justify-start items-center py-0 px-4 mt-6 w-full">

              <p
                  class="text-base text-gray-600 dark:text-gray-400"
                  id="page-view"

              >
                {{ startPosition + 1 }} -
                {{
                  endPosition > categoryProductList.length
                      ? categoryProductList.length
                      : endPosition
                }}
                od {{ categoryProductList.length }}
              </p>

              <a
                  class="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded"
                  @click="showBefore"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-left"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="15 6 9 12 15 18" />
                </svg>
              </a>
              <a
                  class="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer"
                  @click="showNext"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-right"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="9 6 15 12 9 18" />
                </svg>
              </a>
            </div>



          </div>
          <!-- end product-->
          <div class="lg:flex lg:-mx-2" v-if="trackProducts.length === 0">
            <section class="px-4 pt-20 pb-32 mx-auto max-w-7xl">
              <div class="w-full lg:w-2/3">
                <p class="mt-5 mb-3 text-xl font-bold text-black md:text-2xl">
                  Trenutno nemamo proizvode u ovoj kategoriji.
                </p>
                <p class="mb-3 text-base font-medium text-gray-700">
                  Izvinjavamo Vam se na neprijatnosti. Pokusajte sa drugom
                  kategorijom.
                  <br />
                  Vratite se na
                  <router-link to="/" class="underline"
                    >pocetnu stranu</router-link
                  >.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <spinner :loading="start"></spinner>
  </section>
</template>

<script>
import Breadcrumps from "../layout/Breadcrumps.vue";
import Product from "../partials/Product.vue";
import Spinner from "./TheSpinner.vue";
import {mapActions, mapGetters} from "vuex";
import FormPrice from "@/middleware/PriceHandler";
export default {
  metaInfo () {
    return {
      title:  this.$route.params.category,
      meta: [
        { vmid: 'description', name: 'description', content:  this.$route.params.category}
      ]
    }
  },
  components: {
    Product,
    Breadcrumps,
    Spinner,
  },
  data() {
    return {
      start: false,
      path: "",
      filter: "",
      allFilters: [],
      showFiltersNav: false,
      minProductPrice: 0,
      maxProductPrice:100,
      selectPrice: 0,
      filters: [],
      activeFilters:[],
      startPosition: 0,
      endPosition: 9,
      isMobilePriceToggle: false,
      categoryProductList: []
    };
  },
  computed: {
    ...mapGetters(["get_categoryList", "get_activatedFilters","get_categoryFilters", "get_productsAreReady", "get_allProducts"]),
    ...mapActions(["getProductsByCategory"]),
    trackProducts() {
      // return this.get_categoryList;
      return this.categoryProductList
    },
    trackActiveFilters(){
      return this.get_categoryFilters
    }
  },
  watch: {
    $route(to) {
      if (to.params.category) {
        this.setCategory(this.$route.params.category);
        this.selectPrice = 0
        this.activeFilters.length = 0
      } else {
         this.setCategory(to.params.category);
      }
      console.log( this.$route)
    },
  },
  mounted() {
    this.setCategory(this.$route.params.category)
   
  },
  beforeDestroy() {
    this.selectPrice = 0
  },
  methods: {
    setFileterLength(str){
      let count = 0
      this.categoryProductList.forEach((product) => {
        product.subCategory.forEach((child) => {
          if(child.child === str){
            count +=1
          }
        })
      })
      return count
    },
    showFilters() {
      this.showFiltersNav = !this.showFiltersNav;
    },
    async setCategory(path) {
      this.start = true;
      this.selectPrice = 0
      this.activeFilters.length = 0
      await this.$store.dispatch("getProductsByCategory", path);
      this.categoryProductList = this.get_categoryList
      this.trackMinMaxPrice()
      this.start = false;
    },
    setFilter(filter){
      this.activeFilters.push(filter)
      this.activeFilters = [...new Set(this.activeFilters)]
      this.categoryProductList = this.categoryProductList.filter((product) => {
         return  product.subCategory.find((sub)  => sub.child === filter)
      })

    
      this.startPosition = 0
      this.endPosition = 9

      this.selectPrice = 0
      console.log("this.activeFilters");
      console.log(this.activeFilters);
      console.log("this.categoryProductList");
      console.log(this.categoryProductList.length);
    },
    removeFilter(index){
      this.start = true
      this.activeFilters.splice(index, 1)
     
      if(this.activeFilters.length === 0){
        this.start = true
        this.categoryProductList = this.get_categoryList
        this.start = false
      }
     
     
      let arr=[]
      if(this.activeFilters.length > 0){
        for(let active in this.activeFilters){
          arr = this.get_categoryList.filter((product) => {
            return product.subCategory.find((sub) => sub.child === this.activeFilters[active])
          })
        }
        // this.$store.commit('SET_CATEGORY_LIST', arr)
        this.categoryProductList = arr
        this.selectPrice = 0
      }
      console.log("this.categoryProductList");
      console.log(this.categoryProductList.length);
      this.start = false
    },
    trackPriceChange(event){
      let old = this.selectPrice = event.target.value
      let newPrice = event.target.value
      let isChanged = old === newPrice ? true : false
      if(isChanged){
        this.categoryProductList = this.get_categoryList.filter((product) => product.price <= event.target.value)
      }
    },
    sortByName(){
      this.categoryProductList.sort((a, b) => a.title.localeCompare(b.title))
    },

    filterProductsByPriceGoUp(){
      this.start = true
      this.isMobilePriceToggle = !this.isMobilePriceToggle
      this.categoryProductList.sort((a,b) => a.price - b.price)
      this.start = false
    },
    filterProductsByPriceGoDown(){
      this.start = true
      this.isMobilePriceToggle = !this.isMobilePriceToggle
      this.categoryProductList.sort((a,b) => b.price - a.price)
      this.start = false
    },
    trackMinMaxPrice(){
      if(this.categoryProductList.length === 0){
        this.minProductPrice = 0
        this.maxProductPrice = 0
      }
      if(this.categoryProductList.length > 0) {
         setTimeout(() => {
          this.minProductPrice = this.categoryProductList.sort((a, b) => a.price - b.price)[0].price
          this.maxProductPrice = this.categoryProductList.sort((a, b) => a.price - b.price)[this.get_categoryList.length - 1].price
        }, 200)

      }
    },
    trackPrice(price){
      return FormPrice(price)
    },
    showBefore() {
      if (this.startPosition < 1) {
        return;
      }
      this.startPosition -= 9;
      this.endPosition -= 9;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    showNext() {
      if (this.endPosition > this.categoryProductList.length || this.endPosition === this.categoryProductList.length) {
        return;
      }
      this.startPosition = this.endPosition;
      this.endPosition += 9;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox:checked + .check-icon {
  display: flex;
}
.w-350 {
  width: 365px;
}

.mob-category-nav-enter-active,
.mob-category-nav-leave-active {
  transition: all 0.5s ease-in-out;
}
.mob-category-nav-enter, .mob-category-nav-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}
</style>
