<template>
  <section class="fixed inset-0 min-w-full min-h-screen z-50 flex justify-center items-center bg-gray-100 bg-opacity-40" v-if="trackLoading">
    <img src="@/assets/logos/logo-small.svg" alt="spiner" class="spiner" />
  </section>
</template>

<script>
export default {
  props: ["loading"],
  computed: {
    trackLoading() {
      return this.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.spiner {
  @apply w-36 h-36 animate-spin;
}
</style>
