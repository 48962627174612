<template>
  <header
    v-if="!this.$store.state.isRouteCms"
    class="sticky z-50 w-full h-auto bg-white shadow-lg"
  >
    <div
      class="flex items-baseline justify-between mx-auto max-w-7xl py-5 px-4 xl:px-0 hisHeight"
    >
      <a class="flex items-center hover:cursor-pointer">
        <img
          src="@/assets/logos/logo-big.svg"
          alt="Icewall Tailwind HTML Admin Template"
          class="logo "
          @click="$router.push('/')"
        />
      </a>
      <div class="hidden sm:block relative w-7/12 mx-5" v-if="$store.getters.get_allProducts.length > 20">
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg class="w-5 h-5 text-yellow" viewBox="0 0 24 24" fill="none">
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </span>

        <input
          type="text"
          class="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-yellow dark:border-yellow focus:border-yellow dark:focus:border-yellow focus:outline-none"
          placeholder="Pretrazi celu prodavnicu"
          @keyup="searchAllStore"
          @keydown="goToSearch"
          v-model="search"
          
        />
      </div>
      <div class="flex items-center space-x-1">
        <div class="hidden md:inline-flex">
          <button
            v-if="!this.$store.getters.get_isValid"
            class="px-4 py-2 border border-yellow rounded-md uppercase text-yellow text-sm"
            @click="goToLink('Singin')"
          >
            Prijava
          </button>
          <!-- <singout-btn 
            >Odjavi se</singout-btn
            > -->

          <div
            class="relative inline-block"
            v-if="this.$store.getters.get_isValid"
          >
            <!-- Dropdown toggle button -->
            <button
              @click="showDropDown"
              class="relative z-10 flex flex-row flex-nowrap items-center p-2 text-sm text-gray-600 bg-white border border-yellow rounded-md"
            ><i class="far fa-user-circle"></i>
              <span class="mx-1 capitalize whitespace-nowrap">{{
                this.$store.getters.get_user.name
              }}</span>
              <svg
                class="w-5 h-5 mx-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              v-if="this.dropdown"
              class="fixed left-0 top-0 min-w-full h-screen bg-transparent"
              @click="closeUserMenu('')"
            ></div>
            <div
              v-show="this.dropdown"
              class="absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl dark:bg-gray-800"
            >
              <a
                href="#"
                class="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <div class="mx-1">
                  <h1
                    class="text-sm font-semibold text-gray-700 capitalize dark:text-gray-200"
                  >
                    <i class="fas fa-at mr-1"></i>{{ this.$store.getters.get_user.name }}
                  </h1>
                  <p class="text-sm text-gray-500">
                    {{ this.$store.getters.get_user.email }}
                  </p>
                </div>
              </a>

              <hr class="border-gray-200 dark:border-gray-700" />

              <a
                v-if="$store.getters.get_isAdmin"
                class="block px-4 pt-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                @click="closeUserMenu('CMS')"
              >
                Admin Panel <i class="fas fa-user-shield ml-1"></i>
              </a>
              <a
                class="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                @click="closeUserMenu('ShopingCart')"
              >
                Vasa Korpa <i class="fas fa-shopping-cart ml-1"></i>
              </a>

              <hr class="border-gray-200 dark:border-gray-700" />

              <a
                @click="singOut"
                class="block px-4 py-3 text-sm text-gray-600 capitalize cursor-pointer transition-colors duration-200 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Izloguj se <i class="fas fa-sign-out-alt ml-1"></i>
              </a>
            </div>
          </div>

          <button class="px-4 py-2 border border-yellow text-yellow rounded-md text-sm" @click="goToLink('Singup')">
            Registracija
          </button>
        </div>
        <div class="inline-flex md:hidden">
          <button
            class="flex-none px-2 btn btn-link btn-sm"
            @click="toggleMenu('')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
              class="w-5 h-5"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
            <span class="sr-only">Open Menu</span>
          </button>
          <!--Dropdowm menu -->
          <transition name="menu-slide-in-out">
            <div
              class="fixed w-full h-full inset-0 z-40 flex flex-col px-2 py-5 bg-gray-800 sm:w-8/12"
              v-if="this.open"
            >
              <div
                class="flex justify-between items-baseline mb-5 bg-transparent"
              >
                <a class="flex items-center pl-3">
                  <img
                    src="@/assets/logos/logo-big-white.svg"
                    alt="Dilektra doo"
                    class="logo"
                    style="cursor: pointer;"
                    @click="$router.push('/')"
                  />
                </a>
                <button class="btn text-white" @click="toggleMenu('')">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                  <span class="sr-only">Close Menu</span>
                </button>
              </div>
              <!-- mob navigation -->
              <div class="flex flex-col items-start pt-4 uppercase">
                <a class="dropdown-link" @click="toggleMenu('Home')"
                   v-if="get_productsAreReady" 
                  >Prodavnica</a
                >
                <a class="dropdown-link" @click="toggleMenu('EquipmentServicing')" to="/servisiranje-opreme">Servis - Dolazak na adresu</a>
                <a class="dropdown-link" @click="toggleMenu('Blog')" v-if="$store.getters.get_allPosts.length > 1">Blog</a>
                <a class="dropdown-link" @click="toggleMenu('About')">O nama</a>
                <a class="dropdown-link" @click="toggleMenu('Contact')"
                  >Kontakt</a
                >
                <a
                  class="dropdown-link whitespace-nowrap"
                  @click="toggleMenu('ShopingCart')"
                  v-if="$store.getters.get_isValid"
                >
                  Vasa Korpa <i class="fas fa-shopping-cart"></i>
                </a>
              </div>
              <div class="flex flex-col items-start mt-16 pl-2">
                <button
                  class="mb-3 px-4 py-2 border border-yellow text-yellow rounded-md text-sm"
                  @click="toggleMenu('Singup')"
                >
                  Registracija
                </button>
                <button
                  v-if="!this.$store.getters.get_isValid"
                  class="px-4 py-2 border border-yellow text-yellow rounded-md text-sm"
                  @click="toggleMenu('Singin')"
                >
                  Prijava
                </button>
                <singout-btn v-if="this.$store.getters.get_isValid"
                  >Odjavi se</singout-btn
                >
              </div>
            </div>
          </transition>
          <transition name="tablet-help-slide">
            <div
              class="z-30 fixed inset-0 w-full h-full bg-gray-800"
              v-if="open == true"
            ></div>
          </transition>
          <!--end of Dropdowm menu -->
        </div>
      </div>
    </div>

    <!--navigation-->
    <hr class="block my-0" />

    <div class="w-full px-2 py-5 bg-gray-800 locktotop">
      <div class="flex items-center justify-between mx-auto max-w-7xl">
        <div class="flex items-center space-x-1">
          <div class="flex justify-between md:inline-flex">
            <a class="hidden sm:flex sm:items-center">
              <img
                v-show="showLogo"
                src="@/assets/logos/logo-small.svg"
                alt=""
                width="32px"
                class="mr-5 self-end"
                style="cursor: pointer;"
                @click="$router.push('/')"
              />
            </a>
            <div class="flex items-center justify-start moveRight">
              <button
                v-if="hideDeskBtn"
                class="flex flex-row items-center px-3 py-1 bg-yellow text-white rounded-md text-sm"
                @click="openCategories"
              >
                  <i class="fas fa-bars mr-2"></i> proizvodi
              </button>
              <button
                v-if="hideMobBtn"
                class="flex flex-row items-center px-3 py-1 bg-yellow text-white rounded-md text-sm"
                @click="openMobCategories"
              >
               
                  <i class="fas fa-bars mr-2"></i> proizvodi
              </button>
              <router-link class="link" to="/prodavnica" v-if="get_productsAreReady">Prodavnica</router-link>
              <router-link class="link" to="/servisiranje-opreme">Servis - Dolazak na adresu</router-link>
              <router-link class="link" to="/blog" v-if="$store.getters.get_allPosts.length > 1">Blog</router-link>
              <router-link class="link" to="/usluge">Usluge</router-link>
              <router-link class="link" to="/onama">O nama</router-link>
              <router-link class="link" to="/kontakt">Kontakt</router-link>
              
              <!-- <router-link v-if="$store.getters.get_isAdmin" class="link" to="/dilektra-cms"
                >Admin CMS
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end navigation-->
    <!--category menu-->
    <div class="relative m-auto max-w-7xl">
      <div
        class="close"
        v-if="$store.getters.get_dropdownMenu"
        @click="openCategories"
      ></div>
      <category-dropdown
        :openMenu="$store.getters.get_dropdownMenu"
      ></category-dropdown>
    </div>

    <!--end of category menu-->
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import singoutBtn from "../reusable/TheSingoutButton.vue";
import CategoryDropdown from "./CategoryDropdown.vue";
export default {
  components: {
    singoutBtn,
    CategoryDropdown,
  },
  data() {
    return {
      open: false,
      openCat: false,
      showLogo: false,
      dropdown: false,
      search: "",
      hideMobBtn: false,
      hideDeskBtn: true,
    };
  },
  computed:{
    ...mapGetters(["get_productOnAction", "get_productOnPromotion", "get_productsAreReady"]),
  },
  mounted() {
    window.addEventListener("load", this.hideCategoryBtn);
    document.addEventListener("scroll", this.stikyNav);
    window.addEventListener("resize", this.hideCategoryBtn);
  },
  methods: {
    hideCategoryBtn(){
      if(this.$store.getters.get_allProducts.length > 20){
        this.hideMobBtn = true
      }
      if(document.documentElement.clientWidth >= 640){
        this.hideMobBtn = false
      }
      if(document.documentElement.clientWidth <= 640){
        this.hideMobBtn = true
      }

      if(this.$store.getters.get_allProducts.length > 20){
        this.hideDeskBtn = true  
      }
      if(document.documentElement.clientWidth <= 640){
        this.hideDeskBtn = false
      }
        
    },
    stikyNav() {
      const hisHeight = document.querySelector(".hisHeight");
      if (window.pageYOffset > hisHeight.clientHeight) {
        this.showLogo = true;
        document.querySelector(".locktotop").classList.add("forNavBar");
        document.querySelector(".moveRight").classList.add("transformRight");
      } else {
        this.showLogo = false;
        document.querySelector(".locktotop").classList.remove("forNavBar");
      }
    },
    toggleMenu(route) {
      this.open = !this.open;
      if (route != "") {
        this.$router.push({ name: route }).catch(() => {});
      }
    },
    closeUserMenu(route) {
      this.dropdown = !this.dropdown;
      if (route != "") {
        this.$router.push({ name: route }).catch(() => {});
      }
    },
    goToLink(link) {
      this.$router.push({ name: link }).catch(() => {});
    },
    openCategories() {
      this.$store.commit("TOGGLE_DROPDOWN_MENU");
    },
    openMobCategories() {
      this.$store.commit("TOGGLE_MOB_DROPDOWN");
    },
    showDropDown() {
      this.dropdown = !this.dropdown;
    },
    trackScroll() {},
    singOut() {
      this.$store.dispatch("trySingout");
    },
    searchAllStore() {
      this.$store.commit("SEARCH_FILTER", this.search);
    },
    goToSearch() {
      if (this.$route.path !== "/pretraga") {
        this.$router.push("/pretraga");
      }
      if (this.search == "" || this.search == null || this.search == " ") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped lang="scss">
a {
  @apply whitespace-nowrap active:border-transparent hover:text-yellow hover:bg-transparent;
}
.router-link-active {
  @apply text-yellow;
}
.link {
  @apply hidden sm:flex sm:text-sm sm:leading-5 sm:py-1 sm:mx-2 sm:whitespace-nowrap sm:text-gray-200 sm:bg-transparent sm:hover:text-yellow sm:cursor-pointer sm:font-medium sm:select-none sm:align-middle;
}
.logo {
  min-width: 150px;
  cursor: pointer;
}
.forNavBar {
  @apply fixed top-0 transition-all;
}
.transformRight {
  @apply transition-all translate-x-1;
  // "transform: translateX(5px); transition: all 0.35s ease";
}

// menu-slide-in-out

.menu-slide-in-out-enter-active,
.menu-slide-in-out-leave-active {
  transition: all 0.5s ease-in-out;
}
.menu-slide-in-out-enter, .menu-slide-in-out-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
}

// tablet-help-slide
.tablet-help-slide-enter-active,
.tablet-help-slide-leave-active {
  transition: all 0.5s ease-in-out;
}
.tablet-help-slide-enter, .tablet-help-slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}

.dropdown-link {
  @apply text-base text-gray-200 hover:text-white mt-5 pl-3;
}
button {
  @apply uppercase mx-1 hover:text-white hover:bg-yellow-light;
  &:active,
  &:focus {
    @apply border-0 border-transparent;
  }
}
.close {
  background: transparent;
  width: 100vw;
  height: 100vh;
  inset: 0;
  position: fixed;
  z-index: 1;
}
.router-link-active {
  @apply text-yellow relative;
  &::after{
    content:'';
    height: 10%;
    bottom: -20px;
    @apply absolute w-full bg-yellow left-0;
  }
}
</style>
