import axios from 'axios'
import errorHandler from '../../../../middleware/ErrorHandler'

const products = {
  state: {
    allProducts: [],
    productsAreReady: false,
    previewImages: [],
    infoModalOn: false,
    updateModalOn: false,
    oneProduct: {},
    categoryList: [],
    activatedFilters: [],
    productName: null,
    categoryFilters: [],
    searchList: [],
    productOnAction: [],
    productOnPromotion: [],
    catSelection: [{
        name: "Racunari",
        children: {
          0: "Desktop računari",
          1: "Laptop racunari",
          2: "Brandname računari"
        },
      },
      {
        name: "Tableti"
      },
      {
        name: "Komponente",
        children: {
          0: "Kućišta",
          1: "Napajanja",
          2: "Grafičke karte",
          3: "Matične ploče",
          4: "Memorije",
          5: "Procesori",
          6: "HDD i SSD",
        },
      },
      {
        name: "Serveri"
      },
      {
        name: "Monitori"
      },
      {
        name: "Periferije",
        children: {
          0: "Miševi",
          1: "Tastature",
          2: "Setovi",
          3: "Zvučnici",
          4: "Optika",
          5: "Slušalice",
          6: "Mikrofoni",
        },
      },
      {
        name: "Neprekidna napajanja "
      },
      {
        name: "Štampači i skeneri",
        children: {
          0: "Skeneri",
          1: "Štampači",
          2: "Multifunkcijski uređaji",
        },
      },
      {
        name: "Toneri"
      },
      {
        name: "Mrežna oprema",
        children: {
          0: "Ruteri",
          1: "Svičevi",
          2: "Ostalo"
        },
      },
      {
        name: "Korišćena oprema"
      },
      {
        name: "Eksterni diskovi",
      },
      {
        name: "USB memorija",
      },
      {
        name: "Memorijske kartice",
      },
      {
        name: "Prečišćivači vazduha",
      },
      {
        name: "Televizori",
      },
      {
        name: "Klima Uredjaji",
      },
    ],
    filters: [{
        name: "Laptop racunari",
        list: [{
            title: "Procesor",
            children: []
          },
          {
            title: "Tip procesora",
            children: []
          },
          {
            title: "Grafička karta",
            children: [],
          },
          {
            title: "Količina memorije",
            children: [],
          },
        ],
      },
      {
        name: "Tableti",
        list: [{
            title: "Operativni sistem",
            children: []
          },
          {
            title: "Dijagonala ekrana",
            children: []
          },
          {
            title: "Rezolucija",
            children: [],
          },
          {
            title: "Procesor",
            children: [],
          },
          {
            title: "RAM memorija",
            children: [],
          },
          {
            title: "Interna memorija",
            children: [],
          },
          {
            title: "Prednja kamera",
            children: [],
          },
          {
            title: "Zadnja kamera",
            children: [],
          },
        ],
      },
      {
        name: "Desktop računari",
        list: [{
            title: "Procesor",
            children: []
          },
          {
            title: "Tip procesora",
            children: []
          },
          {
            title: "Grafička karta",
            children: [],
          },
          {
            title: "Količina memorije",
            children: [],
          },
        ],
      },
      {
        name: "Brandname računari",
        list: [{
            title: "Procesor",
            children: []
          },
          {
            title: "Tip procesora",
            children: []
          },
          {
            title: "Grafička karta",
            children: [],
          },
          {
            title: "Količina memorije",
            children: [],
          },
          {
            title: "Tip Skladištenja",
            children: [],
          },
        ],
      },

      {
        name: "Kućišta",
        list: [{
          title: "Proizvođač",
          children: []
        }],
      },
      {
        name: "Napajanja",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Snaga",
            children: []
          },
        ],
      },
      {
        name: "Grafičke karte",
        list: [{
            title: "Proizvođač",
            children: []
          },

          {
            title: "Količina memorije",
            children: [],
          },
        ],
      },
      {
        name: "Matične ploče",
        list: [{
            title: "Proizvođač",
            children: []
          },

          {
            title: "Socket",
            children: [],
          },
        ],
      },
      {
        name: "Memorije",
        list: [{
            title: "Količina memorije",
            children: [],
          },

          {
            title: "Radni takt memorije",
            children: [],
          },
          {
            title: "Vrsta memorije",
            children: [],
          },

        ],
      },
      {
        name: "Procesori",
        list: [{
            title: "Proizvođač",
            children: []
          },

          {
            title: "Tip procesora",
            children: [],
          },
          {
            title: "Soket",
            children: [],
          },
        ],
      },
      {
        name: "HDD i SSD",
        list: [{
            title: "Tip diska ",
            children: []
          },

          {
            title: "Format diska",
            children: [],
          },
          {
            title: "Kapacitet",
            children: [],
          },
        ],
      },
      {
        name: "Serveri",
        list: [{
          title: "Brandovi",
          children: []
        }],
      },
      {
        name: "Monitori",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Dijagonala ekrana",
            children: []
          },
          {
            title: "Tip panela",
            children: []
          },
          {
            title: "Učestanost osvežavanja",
            children: [],
          },
          {
            title: "Rezolucija",
            children: []
          },
        ],
      },
      {
        name: "Miševi",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Povezivost",
            children: []
          },
        ],
      },
      {
        name: "Tastature",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Povezivost",
            children: []
          },
        ],
      },
      {
        name: "Televizori",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Rezolucija",
            children: []
          },
          {
            title: "Tip ekrana",
            children: []
          },
          {
            title: "Veličina ekrana",
            children: []
          },
        ],
      },
      {
        name: "Setovi",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Povezivost",
            children: []
          },
        ],
      },
      {
        name: "Zvučnici",
        list: [{
            title: "Sistem",
            children: []
          },
          {
            title: "Snaga",
            children: []
          },
        ],
      },
      {
        name: "Slušalice",
        list: [{
            title: "Tip",
            children: []
          },
          {
            title: "Oblik",
            children: []
          },
          {
            title: "Povezivost",
            children: []
          },
        ],
      },
      {
        name: "Neprekidna napajanja ",
        list: [{
            title: "Snaga",
            children: []
          },
          {
            title: "Snaga",
            children: []
          },
        ],
      },
      {
        name: "Štampači i skeneri",
        list: [{
            title: "Skeneri",
            children: []
          },
          {
            title: "Štampači",
            children: []
          },
          {
            title: "Multifunkcijski uređaji",
            children: []
          },
        ],
      },
      {
        name: "Mrežna oprema",
        list: [{
            title: "Ruteri",
            children: []
          },
          {
            title: "Svičevi",
            children: []
          },
        ],
      },
      {
        name: "Eksterni diskovi",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Kapacitet diska",
            children: []
          },
          {
            title: "Povezanost",
            children: []
          },
        ],
      },
      {
        name: "USB memorija",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Kapacitet",
            children: []
          },
          {
            title: "Interfejs",
            children: []
          },
        ],
      },
      {
        name: "Memorijske kartice",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Tip",
            children: []
          },
          {
            title: "Kapacitet",
            children: []
          },
          {
            title: "Klasa",
            children: []
          },
        ],
      },
      {
        name: "Prečišćivači vazduha",
        list: [{
            title: "boja",
            children: []
          },
          {
            title: "funkcija",
            children: []
          },
          {
            title: "površinska pokrivenost",
            children: []
          },
          {
            title: "Protok vazduha",
            children: []
          },
        ],
      },
      {
        name: "Klima uređaji",
      },
      {
        name: "Štampači",
        list: [{
            title: "Proizvođač",
            children: []
          },
          {
            title: "Tip štampača",
            children: []
          },
          {
            title: "Boja štampe",
            children: []
          },
          {
            title: "Rezolucija štampe",
            children: []
          },
        ],
      },
    ],
  },
  mutations: {
    SET_ALLPRODUCTS(state, payload) {
      state.allProducts = payload
      state.productOnAction = state.allProducts.filter(prod => prod.actionPrice > 0)
      state.productOnPromotion = state.allProducts.filter(prod => prod.promotion != "0")
      if (state.productOnAction.length > 3 || state.productOnPromotion.length > 3) {  1
        state.productsAreReady = true
      }
    },
    UPDATE_ONEPRODUCT_STATUS(state, payload){
      const index = state.allProducts.findIndex(product => {
        return product.id == payload
      });
      
      state.allProducts[index].status = state.allProducts[index].status === 0 ? 1 : 0 
    },
    SET_PRODUCT_ON_ACTION(state, payload) {
      state.productOnAction = payload.filter(prod => prod.actionPrice > 0)
      if (state.productOnAction.length > 3) {
        state.productsAreReady = true
      }
    },
    SET_PRODUCT_ON_PROMOTION(state, payload) {
      
      state.productOnPromotion = payload.filter(prod => prod.promotion != "0")
      if (state.productOnPromotion.length > 3) {
        state.productsAreReady = true
      }
    },
    SET_PREVIEW_IMAGES(state, payload) {
      let images = payload
      images.length === 0 ?
        state.previewImages = images :
        images.forEach(image => {
          state.previewImages.push(image)
        });
    },
    SET_ONE_PRODUCT(state, payload) {
      state.oneProduct = payload
    },
    SET_ONE_PRODUCT_SPECIFICATION(state, payload) {
      state.oneProduct.specification.push(payload)
    },
    SET_SHOP_PRODUCT_MENU(state, data) {
      state.catSelection = data
      console.log(state.catSelection);
    },
    SET_CATEGORY_LIST(state, payload) {

      state.categoryList = payload

      state.categoryFilters = state.filters.filter(object => {
        return object.name === payload[0].category;
      });


      if (state.categoryFilters[0]) {
        state.categoryFilters[0].list.filter((filterList) => {
          state.categoryList.filter((product) => {
            product.subCategory.filter((sub) => {
              if (filterList.title === sub.subcategory) {
                filterList.children.push(sub.child)
                filterList.children = [...new Set(filterList.children)].sort((a, b) => a.localeCompare(b))
              }
            })
          })
        })
      }


    },
    SET_ACTIVE_CATEGORY_FILTERS(state, payload) {
      state.categoryFilters[0].list.forEach((filterList) => {
        filterList.children = []
        return payload.forEach((product) => {
          return product.subCategory.forEach((sub) => {
            if (filterList.title === sub.subcategory) {
              filterList.children.push(sub.child)
              filterList.children = [...new Set(filterList.children)].sort((a, b) => a.localeCompare(b))
            }
          })
        })
      })
    },
    SET_NAME_FOR_BREADCRUMP(state, payload) {
      state.productName = payload
    },
    EDIT_PRODUCT_SPEC(state, payload) {
      state.oneProduct.specification.find((spec) => {
        if (spec.id == payload.id) {
          spec.heading = payload.heading
          spec.description = payload.description
          return spec
        }
      })
    },
    UPDATE_PRODUCT_IMAGES(state, payload) {
      payload.forEach((fileImage) => {
        state.oneProduct.images.push(fileImage)
      })
    },
    DELETE_FROM_PRODUCT_IMAGES(state, payload) {
      if (state.oneProduct.images !== undefined) {
        state.oneProduct.images.splice(payload, 1)
      }
    },
    DELETE_FROM_PREVIEW_IMAGES(state, payload) {
      state.previewImages.splice(payload, 1)
    },
    DELETE_PRODUCT_SPEC(state, payload) {
      state.oneProduct.specification.splice(payload.index, 1)
    },
    DELETE_PRODUCT_SUB_CATEGORY(state, payload) {
      state.oneProduct.subCategory.splice(payload.index, 1)
    },
    UPDATE_MODAL(state, payload) {
      state.updateModalOn = payload
    },
    SET_INFO_MODAL(state, payload) {
      state.infoModalOn = payload
    },
    SEARCH_FILTER(state, payload) {
      state.searchList = state.allProducts.filter(
        (product) =>
        product.title.toLowerCase().includes(payload.toLowerCase()) ||
        product.ean.toLowerCase().includes(payload.toLowerCase()) ||
        product.model.toLowerCase().includes(payload.toLowerCase()) ||
        product.category.toLowerCase().includes(payload.toLowerCase()) ||
        product.specification.some(element =>
          element.description.toLowerCase().includes(payload.toLowerCase())
        ) ||
        product.subCategory.some(element =>
          element.child.toLowerCase().includes(payload.toLowerCase())
        )
      );
    }
  },
  actions: {
    async getAllProducts(context) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/product')

        const allProducts = response.data

        for (const i in allProducts) {
          if (allProducts[i].images[0] == undefined) {
            allProducts[i].images.push({
              url: 'error/error.jpg'
            })
          }
        }
        context.commit('SET_ALLPRODUCTS', allProducts)
        context.commit('SET_SHOP_PRODUCT_MENU', allProducts)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async getProductHomepage(context) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/products/homepage')

        const allProducts = response.data

        for (const i in allProducts) {
          if (allProducts[i].images[0] == undefined) {
            allProducts[i].images.push({
              url: 'error/error.jpg'
            })
          }
        }

        context.commit('SET_PRODUCT_ON_ACTION', allProducts)
        context.commit('SET_PRODUCT_ON_PROMOTION', allProducts)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async getProductManu(context) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/menu')
        context.commit('SET_SHOP_PRODUCT_MENU', response.data)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async getProductsByCategory(context, payload) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/product/' + payload)
        console.log(response.data.message);
        if (response.data.message) {
          throw new Error(response.data.message)
        } else {
          context.commit("SET_CATEGORY_LIST", response.data);
        }
      } catch (error) {
        console.log(error);
        errorHandler(error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async getOneProduct(context, payload) {
      console.log(payload);
      try {
        const response = await axios.get(context.rootGetters.get_API + '/product/one/' + payload.id)
        if (response.status == 200) {
          context.commit("SET_ONE_PRODUCT", response.data)
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async deleteImageById(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + "/product/image/" + payload.id, context.rootGetters.get_headers)
        context.commit('DELETE_FROM_PREVIEW_IMAGES', payload.index)
        await context.dispatch("getOneProduct", {
          id: Number(payload.productID)
        })
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async addNewProduct(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + "/product", payload, context.rootGetters.get_headers)
        if (response.status === 200) {
          context.dispatch('getAllProducts')
          context.commit('SET_SUCCES', true)
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async deleteProductById(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + '/product/' + payload.id, payload.headers)
        context.dispatch('getAllProducts')
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async deleteProductSpec(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + '/product/spec/' + payload.id, context.rootGetters.get_headers)
        context.commit('DELETE_PRODUCT_SPEC', payload)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async updateProduct(context, payload) {
      try {
        const response = await axios.patch(context.rootGetters.get_API + '/product/' + payload.id, payload.formData, context.rootGetters.get_headers)
        if (response.status == 200) {
          console.log(payload.id);
          await context.dispatch('getOneProduct', {
            id: payload.id
          })
          context.commit("UPDATE_MODAL", true);
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async updateProductStatus(context, payload) {
      try {
        console.log(payload);
        const response = await axios.patch(context.rootGetters.get_API + '/product/' + payload.id + '/status', {
          status: payload.status
        }, context.rootGetters.get_headers)
        if (response.status == 200) {
          context.commit('UPDATE_ONEPRODUCT_STATUS', payload.id)
          alert(response.data.message)

        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async updateProductSpecById(context, payload) {
      try {
        await axios.patch(context.rootGetters.get_API + '/product/spec/' + payload.id, {
          heading: payload.heading,
          description: payload.description
        }, context.rootGetters.get_headers)
        context.commit("EDIT_PRODUCT_SPEC", payload)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async updateProductSubCategoryById(context, payload) {
      try {
        await axios.patch(context.rootGetters.get_API + '/product/subcategory/' + payload.id, {
          subcategory: payload.subcategory,
          child: payload.child
        }, context.rootGetters.get_headers)
        context.commit("EDIT_PRODUCT_SPEC", payload)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async addSubCategoryByProductId(context, payload) {
      try {
        await axios.post(context.rootGetters.get_API + '/product/' + payload.id + '/subcat/', {
          subcategory: payload.subcategory,
          child: payload.child
        }, context.rootGetters.get_headers)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    async deleteSubCategoryById(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + '/product/subcategory/' + payload.id, context.rootGetters.get_headers)
        context.commit('DELETE_PRODUCT_SUB_CATEGORY', payload)
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
  },
  getters: {
    get_allProducts(state) {
      return state.allProducts.reverse()
    },
    get_catSelection(state) {
      return state.catSelection
    },
    get_productOnAction(state) {
      return state.productOnAction
    },
    get_productOnPromotion(state) {
      return state.productOnPromotion
    },
    get_previewImages(state) {
      return state.previewImages
    },
    get_infoModalOn(state) {
      return state.infoModalOn
    },
    get_oneProduct(state) {
      return state.oneProduct
    },
    get_updateModalOn(state) {
      return state.updateModalOn
    },
    get_categoryList(state) {
      return state.categoryList
    },
    get_productsAreReady(state) {
      if (state.productOnAction.length > 3 || state.productOnPromotion.length > 3) {
        state.productsAreReady = true
      } else {
        state.productsAreReady = false
      }
      return state.productsAreReady
    },
    get_productName(state) {
      return state.productName
    },
    get_categoryFilters(state) {
      return state.categoryFilters
    },
    get_activatedFilters(state) {
      return state.activatedFilters
    },
    get_searchList(state) {
      return state.searchList
    }
  }
}

export default products