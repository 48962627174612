import axios from 'axios';
import errorHandler from '../../../../middleware/ErrorHandler';
const blog = {
  state: {
    allPosts: [],
    onePost: [],
    addedPostMessage: '',
    succes: false,
    galleryImages: []
  },
  mutations: {
    SET_ALL_POSTS(state, payload) {
      state.allPosts = payload;

    },
    SET_ONE_POST(state, payload) {
      state.onePost = payload
    },
    POST_MESSAGE(state, payload) {
      state.succes = true

      state.addedPostMessage = payload
    },
    SET_SUCCES(state, payload) {
      state.succes = payload
    },
    SET_GALLERY_PREVIEW_IMAGES(state, payload) {

      payload.forEach(image => {
        state.galleryImages.push(image)
      });
    },
    RESET_GALLERY(state) {
      state.galleryImages = []
    },
    DELETE_FROM_ONE_POST_IMAGES(state, payload) {
      if (state.onePost.images !== undefined) {
        state.onePost.images.splice(payload, 1)
      }
    },
    DELETE_FROM_GALLERY_PREVIEW_IMAGES(state, payload) {

      state.galleryImages.splice(payload, 1)
    },
  },
  actions: {
    async getAllPosts(context) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/blog')
        context.commit('SET_ALL_POSTS', response.data)
      } catch (error) {
        if (error) {
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
        }
      }
    },
    async getOnePost(context, payload) {
      try {
        const response = await axios.get(context.rootGetters.get_API + '/blog/' + payload)
        context.commit('SET_ONE_POST', response.data)
      } catch (error) {
        if (error) {
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
        }
      }
    },
    async addNewPost(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + "/blog/add", payload, context.rootGetters.get_headers);
        if (response.data.message === "OK") {
          context.commit('POST_MESSAGE', 'Proizvod je uspesno dodat.')
        }
      } catch (error) {
        if (error) {
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
        }
      }
    },
    async updatePost(context, payload) {
      try {
        await axios.patch(context.rootGetters.get_API + '/blog/edit/' + payload.id, payload.formData, context.rootGetters.get_headers)
        context.commit('POST_MESSAGE', 'Post je uspesno promenjen.')
      } catch (error) {
        if (error) {
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
        }
      }
    },
    async deletePost(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + '/blog/' + payload, context.rootGetters.get_headers)
        context.commit('POST_MESSAGE', 'Post je uspesno obrisan.')
        context.dispatch('getAllPosts')
      } catch (error) {
        if (error) {
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
        }
      }
    },
    async deleteBlogImageById(context, payload) {
      try {
        await axios.delete(context.rootGetters.get_API + "/blog/image/" + payload.id, context.rootGetters.get_headers)
        context.commit('DELETE_FROM_GALLERY_PREVIEW_IMAGES', payload.index)
        await context.dispatch("getOnePost", Number(payload.blogID))
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
  },
  getters: {
    get_allPosts(state) {
      return state.allPosts.reverse()
    },
    get_onePost(state) {
      return state.onePost
    },
    get_addedPostMessage(state) {
      return state.addedPostMessage
    },
    get_succes(state) {
      return state.succes
    },
    get_galleryImages(state) {
      return state.galleryImages
    }
  },
};
export default blog;