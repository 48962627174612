<template>
  <div
    class="fixed top-0 left-0 z-50 h-full pb-10 overflow-x-hidden overflow-y-auto transition origin-left transform bg-gray-900 w-60 md:translate-x-0"
  >
    <img src="@/assets/logos/logo-big-white.svg" class="w-auto px-4 py-5" />
    <nav class="text-sm font-medium text-gray-500 flex flex-col" aria-label="Main Navigation">
      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/"
      >
        <i class="fas fa-tachometer-alt w-5"></i>
        <span class="ml-2">Info Strana</span>
      </router-link>
      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/products"
      >
        <i class="fas fa-stream w-5"></i>
        <span class="ml-2">Proizvodi</span>
      </router-link>
      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/orders"
      >
        <i class="fas fa-clipboard w-5"></i>
        <span class="ml-2">Porudžbenice</span>
      </router-link>

      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/promotions"
      >
        <i class="fas fa-gifts w-5"></i>
        <span class="ml-2">Promocije</span>
      </router-link>
      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/blog"
      >
        <i class="fas fa-blog w-5"></i>
        <span class="ml-2">Blog</span>
      </router-link>

      <router-link
        class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-gray-600 hover:text-gray-200"
        to="/dilektra-cms/users"
      >
        <i class="fas fa-users w-5"></i>
        <span class="ml-2">Korisnici</span>
      </router-link>
      <div class="px-4 absolute bottom-8">
        <button @click="$router.push('/').catch(() => {})" class="px-4 py-2 border border-yellow rounded-md text-yellow uppercase text-sm mb-2">
          <span class="text">Početna strana</span>
        </button>
        <the-singout-button></the-singout-button>
      </div>
    </nav>
  </div>
</template>

<script>
import TheSingoutButton from "../../reusable/TheSingoutButton.vue";

export default {
  components: {
    TheSingoutButton,
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply text-white bg-gray-700;
}
.button {
  @apply flex items-center justify-center py-4 px-7 focus:outline-none bg-transparent border border-yellow rounded focus:ring-2 focus:ring-offset-2 focus:ring-gray-700;
  .text{
    @apply text-sm font-medium text-center text-yellow capitalize;
  }
}
</style>
