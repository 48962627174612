<template>
  <div class="max-w-7xl mx-auto">
    <div class="flex items-start lg:flex-row flex-col justify-end">
      <div class="w-full lg:py-6 lg:px-0 lg:pr-6 md:px-4 md:py-8 py-4 bg-white overflow-x-hidden h-auto">
        <h2 class="px-4 lg:text-4xl text-3xl font-black leading-10 text-gray-800 pt-3">
          Stavke
        </h2>
        <div v-if="$store.getters.get_userShopingCart.length == 0">
          <p class="mt-4">Vasa korpa je prazna...</p>
        </div>
        <shoping-cart-product v-for="(p, index) in $store.getters.get_userShopingCart" :key="p.id" :ean="p.ean"
          :image="p.images[p.images.length - 1].url" :model="p.model" :title="p.title" :price="p.price" :index="index"
          :amount="p.quantity" :actionPrice="p.actionPrice" :id="p.id"></shoping-cart-product>
      </div>
      <div class="md:w-6/12 w-full bg-gray-100 h-auto mb-6">
        <div class="flex flex-col h-auto lg:px-8 md:px-7 px-4 md:py-10 py-6 justify-between overflow-y-auto">
          <div class="">
            <p class="lg:text-4xl text-3xl font-black leading-9 text-gray-800 uppercase">
              vasa korpa
            </p>
            <div class="flex items-center justify-between pt-16">
              <p class="text-base leading-none text-gray-800">Stavke Ukupno</p>
              <p class="text-base leading-none text-gray-800">
                {{  productPrice($store.getters.get_userTotal) }}RSD
              </p>
            </div>

            <div class="flex items-center justify-between pt-5">
              <div class="flex flex-row gap-1">
                <!-- <input name="delivery" type="checkbox" v-model="delivery"> -->
                <label for="delivery" class="text-base leading-none text-gray-800">Dostava</label>
              </div>
              <p class="text-base leading-none text-gray-800">
                {{
                  trackDeliveryCosts
                }}
                .00 RSD
              </p>
            </div>
            <!-- <div class="flex items-center justify-between pt-5">
                <label for="delivery" :class="!delivery ? 'text-base leading-none text-gray-800' : 'text-base leading-none text-gray-300'">Lično preuzimanje</label>
            </div> -->
          </div>
          <div class="">
            <div class="flex items-center pb-6 justify-between lg:pt-8 pt-20">
              <p class="text-2xl leading-normal text-gray-800 uppercase">
                Ukupno
              </p>
              <p class="text-2xl font-bold leading-normal text-right text-gray-800">
                =
                {{
                  $store.getters.get_userShopingCart.length == 0
                  ? 0
                  : trackPrice($store.getters.get_userTotal)
                }} RSD
              </p>
            </div>
            <button @click="setOrder"
              class="text-base leading-none w-full py-5 bg-yellow hover:bg-yellow-dark rounded-lg focus:outline-none focus:ring-0 focus:ring-offset-0 text-white uppercase">
              Naruci
            </button>
          </div>
        </div>
      </div>
    </div>
    <spinner :loading="start"></spinner>
    <order-modal :show="$store.getters.get_showOrderModal" :text="message"></order-modal>
  </div>
</template>

<script>
import ShopingCartProduct from "./ShopingCartProduct.vue";
import Spinner from "../reusable/TheSpinner.vue";
import OrderModal from "../modals/OrderModal.vue";
import FormPrice from "../../middleware/PriceHandler.js"
export default {
  components: { ShopingCartProduct, Spinner, OrderModal },
  name: "component",
  data() {
    return {
      start: false,
      message: "",
      delivery: false,
      deliveryCosts: 0
    };
  },
  methods: {
    async setOrder() {
      this.start = true;
      if (this.$store.getters.get_userShopingCart.length == 0) {
        this.message = "Prvo morate dodati neki proizvod u korpu";
        this.$store.commit("TOGGLE_MODAL", true);
        this.start = false;
        return;
      } else {
        await this.$store.dispatch("makeOrder");
        this.$store.commit("SET_SHOPING_LIST", []);
        this.start = false;
      }
    },
    trackPrice(price) {
      let sum = price + this.deliveryCosts
      return FormPrice(sum)
    },
    productPrice(price){
      return FormPrice(price)
    }

  },
  watch: {
    '$store.getters.get_userTotal': function () {
      if(this.$store.getters.get_userTotal === 0){
        this.deliveryCosts = 0 
      }
      if (this.$store.getters.get_userTotal > 10000 || this.$store.getters.get_userTotal === 0) {
        this.deliveryCosts = 0
      }
      if (this.$store.getters.get_userTotal > 5000 && this.$store.getters.get_userTotal < 10000) {
        this.deliveryCosts = 500
      }
      if (this.$store.getters.get_userTotal < 5000) {
        this.deliveryCosts = 1000
      }


    }
  },
  computed: {
    trackDeliveryCosts() {
      return this.deliveryCosts
    }
  },
  async mounted() {
    await this.$store.dispatch("getUserShopingList");
    // this.devlieryCalculator()
  },
};
</script>

<style>
/* width */

#scroll::-webkit-scrollbar {
  width: 1px;
}

/* Track */

#scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

#scroll::-webkit-scrollbar-thumb {
  background: rgb(133, 132, 132);
}
</style>
