const errorHandler = (error, context, name) => {

    switch (error) {
        case ("1001"):
            context.commit(name, "Doslo je do greske popunite sva polja.")
            break;
        case ("1002"):
            context.commit(name, "Ovaj Email se vec koristi. Probajte sa drugim.")
            break;
        case ("1003"):
            context.commit(name, "Doslo je do greske prilikom verifikacije. Pokusajte opet kasnije.")
            break;
        case ("1004"):
            context.commit(name, "Vas email ili password, su pogresni.")
            break;
        case ("1005"):
            context.commit(name, "Korisnicki nalog nije pronadjen.")
            break;
        case ("1006"):
            context.commit(name, "Vas email ili password, su pogresni.")
            break;
        case ("1007"):
            context.commit(name, "Doslo je greske, molimo vas pokusajte kasnije.")
            break;
        case ("1008"):
            context.commit(name, "Doslo je greske, molimo vas pokusajte kasnije.")
            break;
        case ("1009"):
            context.commit(name, "Mail nije verifikovan.")
            break;
        case ("1010"):
            context.commit(name, "Vasa sesija je istekla, molimo prijavite se opet.")
            break;
        case ("2000"):
            context.commit(name, "Greska prilikom pravljenja posta.")
            break;
        case ("2001"):
            context.commit(name, "Greska prilikom pravljenja posta, proverite sva polja.")
            break;
        case ("2002"):
            context.commit(name, "Greska prilikom pravljenja posta, kontaktirajte administartora.")
            break;
        case ("2003"):
            context.commit(name, "Greska prilikom pravljenja posta, kontaktirajte administartora.")
            break;
        case ("2004"):
            context.commit(name, "Doslo je do greske, molim vas kontaktirajte administartora.")
            break;
        case ("2005"):
            context.commit(name, "Doslo je do greske, ovaj post je predhodno obrisan.")
            break;
        case ("2006"):
            context.commit(name, "Doslo je do greske, ovaj post je predhodno obrisan.")
            break;
        case ("2007"):
            context.commit(name, "Doslo je do greske, proverite sva polja.")
            break;
        case ("2008"):
            context.commit(name, "Doslo je do greske, proverite sva polja.")
            break;
        case ("2009"):
            context.commit(name, "Doslo je do greske, ovaj post ne postoji.")
            break;
        case ("2010"):
            context.commit(name, "Doslo je do greske, molim vas kontaktirajte administartora.")
            break;
        case ("4001"):
            context.commit(name, "Doslo je do greske, slika je prevelika, maksimalna velicina je 1mgb.")
            break;
        case ("9000"):
            context.commit(name, "Doslo je do greske, slika nije odgovarajuca.")
            break;
        default:
            context.commit(name, "Doslo je do greske, pokusajte opet.")
    }

}
export default errorHandler;