<template>
<div class="container mx-auto my-16 pt-16 max-w-7xl">
    <div class="flex justify-center flex-row">
      <div
        class="xl:w-2/5 lg:w-2/5 bg-yellow py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none"
      >
        <div class="xl:w-5/6 xl:px-0 px-4 mx-auto">
          <h1 class="xl:text-4xl text-3xl pb-4 text-white font-bold capitalize">
            Kontaktirajte nas
          </h1>
          <p
            class="text-xl text-white pb-8 leading-relaxed font-normal lg:pr-4"
          >
            Imate pitanje o nama? Da li ste zainteresovani za partnerstvo sa
            nama? Imate neke predloge ili samo želite da se pozdravite? Samo nas
            kontaktirajte. Tu smo da vam pomognemo.
          </p>
          <div class="flex pb-4 items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-phone-call"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1"
                />
                <path d="M15 7a2 2 0 0 1 2 2" />
                <path d="M15 3a6 6 0 0 1 6 6" />
              </svg>
            </div>
            <p class="pl-4 text-white text-base"><a href="tel:+38162492091">062 492 091</a></p>
            <p class="pl-4 text-white text-base"><a href="tel:+381 11 66 71 369">011 66 71 369</a></p>
          </div>
          <div class="flex items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#FFFFFF"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>
            </div>
            <p class="pl-4 text-white text-base">info@dilektra.co.rs</p>
          </div>
          <p class="text-lg text-white pt-10 tracking-wide">
            Bulevar Arsenija Čarnojevića 17a,
            <br />
            Beograd, Srbija
          </p>
        </div>
      </div>
    </div>
</div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Reklamacije',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '  Imate pitanje o nama? Da li ste zainteresovani za partnerstvo sa\n' +
            '            nama? Imate neke predloge ili samo želite da se pozdravite? Samo nas\n' +
            '            kontaktirajte. Tu smo da vam pomognemo.'
      }
    ]
  },
}
</script>