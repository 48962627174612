import axios from 'axios'
// import router from '../../../router'
import errorHandler from '../../../middleware/ErrorHandler'
import products from '../cms/products/index.js'
const unique = (arr) => [...new Set(arr)]
const shopingCart = {
    state: {
        userShopingCart: [],
        userTotal: 0,
        counter: 1,
        showOrderModal: false
    },
    mutations: {
        ADD_TO_SHOPING_CART(state, payload) {
            console.log(payload);
            let addedProduct = products.state.allProducts.filter((product) => product.id == payload)
            addedProduct[0].quantity = 1
            state.userShopingCart.push(addedProduct[0])
        },
        REMOVE_FORM_SHOPING_CART(state, payload) {
            let noDubles = unique(state.userShopingCart)
            noDubles.splice(payload, 1)
            state.userShopingCart = noDubles
        },
        SET_SHOPING_LIST(state, payload) {
            state.userShopingCart = payload
        },
        TOGGLE_MODAL(state, payload) {
            state.showOrderModal = payload
        }
    },
    actions: {
        async getUserShopingList(context) {
            try {
                const response = await axios.get(context.rootGetters.get_API + '/user/' + context.rootGetters.get_user.user_id + '/shopingList/', context.rootGetters.get_headers)
                // const userShopingList = []
                // context.rootGetters.get_allProducts.find((item) => {
                //     for (let i = 0; i < response.data.length; i++) {
                //         if (response.data[i].product_id == item.id) {
                //             item.quantity = response.data[i].quantity
                //             userShopingList.push(item)
                //         }
                //     }
                // })
                context.commit('SET_SHOPING_LIST', response.data)
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async addShopItem(context, payload) {
            try {
                await axios.post(context.rootGetters.get_API + "/shopinglist/add", {
                    product: payload.id,
                    email: payload.email
                }, context.rootGetters.get_headers)
                // context.commit('ADD_TO_SHOPING_CART', payload.id)
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async setProductQuantity(context, payload) {
            try {
                await axios.patch(context.rootGetters.get_API + '/user/' + context.rootGetters.get_user.user_id + '/shopingList/product/' + payload.id, {
                    quantity: payload.quantity
                }, context.rootGetters.get_headers)
                context.dispatch('getUserShopingList')
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async removeShopItem(context, payload) {
            try {
                await axios.delete(context.rootGetters.get_API + '/user/' + context.rootGetters.get_user.user_id + '/shopinglist/product/' + payload.product_id, context.rootGetters.get_headers)
                context.commit('REMOVE_FORM_SHOPING_CART', payload.index)
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async makeOrder(context) {
            try {
                let order = {
                    products: [],
                    delivery: 1200,
                    productsTotal: 0,
                    total: 0,
                    user: context.rootGetters.get_user.name,
                    email: context.rootGetters.get_user.email
                }
                let product = {}

                context.rootGetters.get_userShopingCart.forEach((prod) => {
                    var total = 0
                    if (prod.actionPrice > 0) {
                        total = prod.actionPrice * prod.quantity
                    }
                    if (prod.actionPrice == 0) {
                        total = prod.price * prod.quantity
                    }

                    product = {
                        model: prod.model,
                        price: prod.price,
                        actionPrice: prod.actionPrice,
                        title: prod.title,
                        ean: prod.ean,
                        quantity: prod.quantity,
                        totalPerProduct: total
                    }
                    order.products.push(product)
                });

                order.productsTotal = context.state.userTotal
                order.total = context.state.userTotal + order.delivery

                const response = await axios.post(context.rootGetters.get_API + '/user/' + context.rootGetters.get_user.user_id + '/order', {
                    order
                }, context.rootGetters.get_headers)
                if (response.data.message === "ok") {
                    context.commit('TOGGLE_MODAL', true)
                }

            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        }
    },
    getters: {
        get_userShopingCart(state) {
            return unique(state.userShopingCart)
        },
        get_counter(state) {
            return state.counter
        },
        get_showOrderModal(state) {
            return state.showOrderModal
        },
        get_userTotal(state) {
            let noDubles = unique(state.userShopingCart)
            let forSum = [];

            noDubles.filter((prod) => {
                if (prod.actionPrice > 0) {
                    let total = 0
                    total = prod.actionPrice * prod.quantity
                    forSum.push(total)
                }
                if (prod.actionPrice == 0) {
                    let total = 0
                    total = prod.price * prod.quantity
                    forSum.push(total)
                }
            })
            state.userTotal = forSum.reduce((a, b) => a + b, 0)
            return state.userTotal
        }
    }
}

export default shopingCart