<template>
  <router-view></router-view>
</template>
<script>
export default {
  metaInfo: {
    title: 'Prodavnica',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `Sastavljanje konfiguracija po želji i potrebama. 30 god. Iskustva u sastavljanju konfiguracija po želji i potrebama.  Pošaljite nam vaš zahtev na mail i naši stručnjaci će vam napraviti optimalnu ponudu. Uz konsultacije sa vama dolazimo do najboljeg rešenja. Komponente kojih nema na našem sajtu smo mogućnosti da nabavimo i da odgovorimo na svaki zahtev. Radimo i nadogradnje i zamenu staro za novo. Svaku konfiguraciju sastavljenu kod nas besplatno dostavljamo na teritoriji Beograda.`
      }
    ]
  },
}
</script>