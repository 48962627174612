<template>
  <div class="container mx-auto xl:my-16 xl:pt-16 max-w-7xl">
    <div class="flex flex-col-reverse lg:flex-row">
      <div
        class="xl:w-2/5 lg:w-2/5 bg-yellow py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none"
      >
        <div class="xl:w-5/6 xl:px-0 px-4 mx-auto">
          <h1 class="xl:text-4xl text-3xl pb-4 text-white font-bold capitalize">
            Kontaktirajte nas
          </h1>
          <p
            class="text-xl text-white pb-8 leading-relaxed font-normal lg:pr-4"
          >
            Imate pitanje o nama? Da li ste zainteresovani za partnerstvo sa
            nama? Imate neke predloge ili samo želite da se pozdravite? Samo nas
            kontaktirajte. Tu smo da vam pomognemo.
          </p>
          <div class="flex pb-4 items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-phone-call"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1"
                />
                <path d="M15 7a2 2 0 0 1 2 2" />
                <path d="M15 3a6 6 0 0 1 6 6" />
              </svg>
            </div>
            <p class="pl-4 text-white text-base"><a href="tel:+38162492091">062 492 091</a></p>
            <p class="pl-4 text-white text-base"><a href="tel:+381116671369">011 66 71 369</a></p>
          </div>
          <div class="flex items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#FFFFFF"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <rect x="3" y="5" width="18" height="14" rx="2" />
                <polyline points="3 7 12 13 21 7" />
              </svg>
            </div>
            <p class="pl-4 text-white text-base">info@dilektra.co.rs</p>
          </div>
          <p class="text-lg text-white pt-10 tracking-wide">
            Ruža 37, Voždovac,
            <br />
            Beograd, Srbija
          </p>
        </div>
      </div>
      <div
        class="xl:w-3/5 lg:w-3/5 h-full pt-5 pb-5 xl:pr-5 xl:pl-0 rounded-tr rounded-br"
      >
        <div class="bg-white py-4 px-4 rounded-tr rounded-br">
          <h1 class="text-4xl text-gray-700 font-extrabold mb-6 capitalize">
            Unesite svoje podatke
          </h1>
          <div class="block xl:flex w-full flex-wrap justify-start mb-6">
            <div class="w-2/4 max-w-xs mb-6 xl:mb-0">
              <div class="flex flex-col">
                <label
                  for="full_name"
                  class="text-gray-500 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >Ime i Prezime</label
                >
                <input
                  required
                  name="full_name"
                  type="text"
                  class="focus:outline-none focus:border focus:border-yellow font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="Petar Petrovic"
                  v-model="fullName"
                />
              </div>
            </div>
            <div class="w-2/4 max-w-xs xl:flex">
              <div class="flex flex-col">
                <label
                  for="email"
                  class="text-gray-500 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >Email</label
                >
                <input
                  required
                  name="email"
                  type="email"
                  class="focus:outline-none focus:border focus:border-yellow font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="petar.petrovic@gmail.com"
                  v-model="email"
                />
              </div>
            </div>
          </div>
          <div class="flex w-full flex-wrap">
            <div class="w-2/4 max-w-xs">
              <div class="flex flex-col">
                <label
                  for="phone"
                  class="text-gray-500 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >Telefon</label
                >
                <input
                  required
                  name="phone"
                  type="tel"
                  class="focus:outline-none focus:border focus:border-yellow font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder="0641112233"
                  v-model="phone"
                />
              </div>
            </div>
          </div>
          <div class="w-full mt-6">
            <div class="flex flex-col">
              <label
                class="text-sm font-semibold text-gray-500 mb-2"
                for="message"
                >Poruka</label
              >
              <textarea
                placeholder="Vasa poruka..."
                name="message"
                class="border-gray-300 border mb-4 rounded py-2 text-sm outline-none resize-none px-3 focus:border focus:border-yellow"
                rows="8"
                v-model="message"
              ></textarea>
            </div>
            <button @click="sendMessage" class="px-4 py-2 border border-yellow text-yellow rounded-md uppercase">
              Pošalji 
            </button>
          </div>
        </div>
      </div>
    </div>
    <the-spinner :loading="start"></the-spinner>
    <success-pop :message="responseMessage" :show="showMessage"></success-pop>
  </div>
</template>

<script>
import axios from "axios";
import TheSpinner from "../components/reusable/TheSpinner.vue";
import SuccessPop from "../components/modals/SuccessPop.vue";

export default {
  metaInfo: {
    title: 'Kontaktirajte nas',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '  Imate pitanje o nama? Da li ste zainteresovani za partnerstvo sa\n' +
            '            nama? Imate neke predloge ili samo želite da se pozdravite? Samo nas\n' +
            '            kontaktirajte. Tu smo da vam pomognemo.'
      }
    ]
  },
  components: { TheSpinner, SuccessPop },
  data() {
    return {
      fullName: "",
      email: "",
      phone: "",
      message: "",
      start: false,
      showMessage: false,
      responseMessage: "",
    };
  },
  methods: {
    async sendMessage() {
      this.start = true;
      if (
        this.fullName == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.message == ""
      ) {
        this.showMessage = true;
        this.responseMessage = "Sva polja moraju biti popunjena";
        this.start = false;
        return;
      }
      const contact = {
        fullname: this.fullName,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };
      await axios
        .post(this.$store.getters.get_API + "/contactus", { contact })
        .then((response) => {
          if (response.status == 200) {
            this.start = false;
            this.showMessage = true;

            this.responseMessage =
              "Primili smo vas mail, uskoro ce vam neko odogovoriti.";
            this.fullName == "";
            this.email == "";
            this.phone == "";
            this.message == "";
          }
        })
        .catch((error) => {
          if (error) {
            this.start = false;
            this.showMessage = true;
            this.responseMessage = error.response.data.message;
          }
        });
     
    },
  },
};
</script>
