<template>
  <section class="px-4 pt-20 pb-32 mx-auto max-w-7xl">
    <div class="w-full lg:w-2/3">
      <router-link to="/" title="Pocetna strana" class="flex items-center">
        <img src="@/assets/logos/logo-big.svg" alt="logo" class="w-2/4" />
      </router-link>
      <div v-if="!$store.getters.get_errorStatus">
        <p class="mt-5 mb-3 text-xl font-bold text-black md:text-2xl">
          Vas email je verifikovan
        </p>
        <p class="mb-3 text-base font-medium text-gray-700">
          Mozete da se ulogujete.
        </p>
        <router-link to="/singin" class="btn btn-lg btn-dark mt-5 mr-5"
          >Uloguj se</router-link
        >
        <router-link to="/" class="btn btn-lg btn-outline-dark mt-5"
          >Idi na pocetnu stranu</router-link
        >
      </div>
      <div v-if="$store.getters.get_errorStatus">
        <p class="mt-5 mb-3 text-xl font-bold text-black md:text-2xl">
          Doslo je do greske.
        </p>
        <p class="mb-3 text-base font-medium text-gray-700">
          Proverite da li ste iskopirali link kako treba.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.sendVerifyToekn();
  },
  methods: {
    async sendVerifyToekn() {
      await this.$store.dispatch("handleVerify", this.$route.params._id);
    },
  },
};
</script>
