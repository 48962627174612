<template>
    <section class="px-4 py-20 mx-auto max-w-7xl">
      <div class="w-full mx-auto space-y-5 sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-3/12">
        <div class="text-center pb-6">
            <h1 class="text-4xl font-semibold text-center text-yellow">Uloguj se</h1>
            <p class="mt-3 text-center text-gray-500 dark:text-gray-300">Unesite svoje kredencijale</p>
        </div>
        <div class="space-y-4">
          <label class="block">
            <span class="block mb-1 text-xs font-medium text-gray-700">Vas Email</span>
            <input class="form-input" type="email" placeholder="Ex. pera.peric@gmail.com" inputmode="email" v-model="email" required />
            <small v-if="nodata" class="capitalize text-red-500">{{ email.length > 2 ? '' : 'Unesite svoje kredencijale' }}</small>
          </label>
          <label class="block">
            <span class="block mb-1 text-xs font-medium text-gray-700">Vas Password</span>
            <input class="form-input" type="password" placeholder="••••••••" required  v-model="password"/>
          </label>
          <button @click="trySingin" class="singout mx-auto"><span class="text"> Prijavi se</span> </button>
        </div>
        <div class="my-2 text-center">
          <router-link to="/zaboravljena-sifra" class="text-sm text-gray-700 underline hover:text-purple-700">Zaboravio/la sam password</router-link>
        </div>
      </div>
      <success-pop 
          :show="this.$store.getters.get_showModal" 
          :message="'Dobro dosli nazad.'">
      </success-pop>
       <spinner :loading="start"></spinner>
    </section>
</template>

<script>
import Spinner from "../components/reusable/TheSpinner.vue";
import SuccessPop from "../components/modals/SuccessPop.vue";
import { mapGetters } from "vuex";
export default {
  name: "Singin",
  metaInfo: {
    title: 'Uloguj se'
  },
  components: {
    SuccessPop,Spinner
  },
  data() {
    return {
      email: "",
      password: "",
      start: false,
      nodata: false
    };
  },
  computed: {
    ...mapGetters[("get_showModal", "get_message")],
  },
  methods: {
    async trySingin() {
      this.start = true
      if (this.email.length == 0 || this.password.length < 8 ||  this.email == '' || this.password == '') {
        this.start = false
        this.nodata = true
        return;
      }
      await this.$store.dispatch("trySingin", {
        email: this.email,
        password: this.password,
      });
      this.start = false
    },
  },
};
</script>

<style scoped lang="scss">
.singout {
  @apply flex items-center justify-center border border-yellow py-2 px-4 rounded-md;
  .text {
    @apply text-sm text-center text-yellow uppercase;
    
  }
  &:hover > .text,  &:hover > i{
    @apply text-yellow;
  }
  
}

</style>