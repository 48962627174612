import axios from 'axios'
import router from '../../../router'
import errorHandler from '../../../middleware/ErrorHandler'

const auth = {
  state: {
    isValid: false,
    isAdmin: null,
    message: '',
    showModal: false,
    errorStatus: false,
    errorMessage: 'No user data',
    sendHeader: {
      headers: {
        Authorization: ''
      }
    },
    spinner: false,
    user: {},
    openSinginModal: false
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
      if (state.user !== {}) {
        localStorage.setItem('user', state.user.user)
        localStorage.setItem('email', state.user.email)
        localStorage.setItem('user_id', state.user.user_id)
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('user_id')
        return
      }
    },
    CLEAR_USER(state){
      state.user = {}
      localStorage.removeItem('user')
      localStorage.removeItem('email')
      localStorage.removeItem('user_id')
    },
    SET_IS_ADMIN(state, payload) {
      state.isAdmin = payload == 'admin' ? true : false
    },
    SET_IS_USER_VALID(state, payload) {
      state.isValid = payload
    },
    SET_TOKEN(state, payload) {
      if (payload !== undefined) {
        state.sendHeader.headers.Authorization = `Bearer ${payload}`
        localStorage.setItem('_id', payload)
      }
    },
    SET_MESSAGE(state, payload) {
      state.showModal = true
      state.message = payload
    },
    CLEAR_MESSAGE(state) {
      state.showModal = false
      state.message = ''
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorStatus = true
      state.errorMessage = payload
    },
    CLEAR_ERROR_MESSAGE(state) {
      state.errorStatus = false
      state.errorMessage = ''
    },
    SET_SPINNER(state, payload) {
      state.spinner = payload
    },
    SET_SINGIN_MODAL(state,payload){
      state.openSinginModal = payload
    }
  },
  actions: {
    //* Singup */
    async trySingup(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + '/singup', payload)

        if (response.status == 200) {
          context.commit('SET_MESSAGE', response.data.message)
         
        }
      } catch (error) {
        //error handler
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    //* verifycation */
    async handleVerify(context, payload) {
      try {
        await axios.post(context.rootGetters.get_API + '/verify/' + payload)
      } catch (error) {
        //error handler
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    /**forgot password */
    async handleForgotPassword(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + '/forgotpassword', {
          email: payload
        })

        if (response.status == 200) {
          context.commit('SET_MESSAGE', response.data.message)
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    /**reset password */
    async handleResetPassword(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + '/reset-password', {
          token: payload.token,
          password: payload.password
        })

        if (response.status == 200) {
          context.commit('SET_MESSAGE', response.data.message)
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    //* Singin */
    async trySingin(context, payload) {
      try {
        const response = await axios.post(context.rootGetters.get_API + '/singin', payload)

        if (response.status == 200) {

          context.commit('SET_TOKEN', response.data.token) 
          // console.log(JSON.parse(atob(response.data.token.split('.')[1])));
          context.commit('SET_USER', JSON.parse(atob(response.data.token.split('.')[1])))
          context.commit('SET_IS_USER_VALID', true)
          context.commit('SET_IS_ADMIN', JSON.parse(atob(response.data.token.split('.')[1])).role)
          context.commit('SET_MESSAGE', 'Dobro došli nazad.')
          router.push('/')
        }
      } catch (error) {
        //error handler
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    //* Singot */
    async trySingout(context) {
      try {
        const user = localStorage.getItem('_id')
        const response = await axios.post(context.rootGetters.get_API + '/singout/' + user)
        if (response.status == 200) {
          localStorage.removeItem('user')
          localStorage.removeItem('email')
          localStorage.removeItem('_id')
          localStorage.removeItem('user_id')
          context.commit('CLEAR_USER')
          context.commit('SET_IS_ADMIN', null)
          context.commit('SET_IS_USER_VALID', false)
          if (router.currentRoute.path == "/shop") {
            return
          } else {
            router.push('/')
          }
        }
      } catch (error) {
        errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
      }
    },
    //* Check session */
    async checkIsSessionExpired(context) {
      try {
        const isSingin = localStorage.getItem('_id')
        if (isSingin == undefined) {
          context.commit('SET_IS_USER_VALID', false)
          context.commit('SET_TOKEN', undefined)
          return
        }
        const checkToken = await axios.post(context.rootGetters.get_API + '/is_session_expired/' + isSingin)

        if (checkToken.status == 200) {
          context.commit('SET_IS_USER_VALID', true)
          context.commit('SET_TOKEN', isSingin)
          context.commit('SET_IS_ADMIN', JSON.parse(atob(isSingin.split('.')[1])).role)
        } else {
          throw new Error()
        }

      } catch (error) {
        if (error) {
          localStorage.removeItem('user')
          context.commit('SET_TOKEN', undefined)
          context.commit('SET_IS_USER_VALID', false)
          errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
          router.push('/')
        }
      }
    }
  },
  getters: {
    get_user(state) {
      let localStorageUser = {
        name: localStorage.getItem('user'),
        email: localStorage.getItem('email'),
        _id: localStorage.getItem('_id'),
        user_id: localStorage.getItem('user_id')
      }
      state.user = localStorageUser
      return state.user
    },
    get_headers(state) {
      return state.sendHeader
    },
    get_isValid(state) {
      return state.isValid
    },
    get_message(state) {
      return state.message
    },
    get_showModal(state) {
      return state.showModal
    },
    get_errorStatus(state) {
      return state.errorStatus
    },
    get_errorMessage(state) {
      return state.errorMessage
    },
    get_isAdmin(state) {
      return state.isAdmin
    },
    get_openSinginModal(state){
      return state.openSinginModal
    }
  }
}

export default auth