<template>
  <ol class="w-full">
    <li
      v-for="(item, index) in showList"
      :key="index"
      class="list-item w-48 cursor-pointer bg-white"
    >
      <a
        class="whitespace-nowrap hover:text-yellow"
        @click="categoryList(Object.values(item)[0])"
       
        >{{ Object.values(item)[0] }}</a
      >
    </li>
  </ol>
</template>

<script>
export default {
  props: ["list", "catname"],
  data() {
    return {
      childrenList: [],
    };
  },
  computed: {
    showList() {
      let ifTrue = this.filterList()
      for(let i in ifTrue){
        if(ifTrue[i].show == undefined){
          delete ifTrue[i]
        }
      }
      return ifTrue;
    },
  },
  methods: {
    filterList() {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].name == this.catname) {
          this.childrenList = this.list[i].children;
        }
      }
      // console.log(this.childrenList);
      return this.childrenList;
    },
    categoryList(categoryName) {
      // this.$store.commit("SET_CATEGORY_LIST", categoryName);
      this.$store.dispatch('getProductsByCategory', categoryName)
      this.$router
        .push({ name: "CategoryPage", params: { category: categoryName } })
        .catch(() => {});
      this.$store.commit("TOGGLE_DROPDOWN_MENU");
    },
  },
};
</script>
