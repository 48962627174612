<template>
  <article class="max-w-7xl px-4 mx-auto my-10">
    <h1 class="text-3xl leading-10 mb-10">Mogućnosti plaćanja pri online kupovini</h1>
    <h3 class="text-2xl leading-8 my-4">Plaćanje preko računa</h3>
    <p class="font-light">
      Po dogovorenoj kupovini dostavljamo vam predračun na mail. Po
      evidentiranoj uplati pozivamo vas i dogovaramo se o načinu isporuke.
      Plaćanje pouzećem
    </p>

    <h3 class="text-2xl leading-8 my-4">Gotovinski (POUZEĆEM)</h3>
    <p class="font-light">
      Dogovorenu robu plaćate pouzećem, distriburteru koji vam izvrši dostavu Za
      robu vrednosti preko 5000.00 din. Poštarina je besplatna
    </p>
    <br />
  

    <h3 class="text-2xl leading-8 my-4">Plaćanja u maloprodajnom objektu</h3>
    <p class="font-light">
      U našem objektu možete vršiti plaćanje karticama Maestro, Visa, Master,
      DinaCard, American express, Union Pay. <br />
      Kako debitnim tako i kreditnim.
    </p>
    <ul class="mb-5 p-5">
      <li class="list-disc my-2 font-bold">
        Korisnici Banca Intesa mogu plaćati karticama do 12 rata bez kamate
      </li>
      <li class="list-disc font-bold">Za plaćanje u gotovini odobravamo rabat od 5%</li>
    </ul>
    <br />

    <br />
    <small>* Plaćanje je moguće samo u dinarskoj valuti.</small><br />
    <small
      >** Gabaritni proizvodi su uređaji poput bele tehnike, uređaji za
      klimatizaciju i grejanje, televizori većih dijagonala. Kriterijumi koji
      proizvode klasifikuju u gabaritne jesu težina proizvoda, površina kutije
      kao i informacija da li se proizvod isporučuje u objekat bez lifta ili
      prilaza.</small
    ><br />

  </article>
</template>

<script>
export default {
  metaInfo: {
    title: 'Mogućnosti plaćanja',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Po dogovorenoj kupovini dostavljamo vam predračun na mail. Poevidentiranoj uplati pozivamo vas i dogovaramo se o načinu isporuke. Plaćanje pouzećem'
      }
    ]
  },
};
</script>

<style></style>
