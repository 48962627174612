<template>
  <section
    v-if="$store.getters.get_promotionActive && this.$route.path === '/prodavnica'"
    class="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto"
  >
    <div class="sm:w-full md:w-full lg:w-10/12 mx-auto bg-white relative lg:top-20 px-10 py-16 shadow-2xl ">
      <div class="flex flex-col justify-center items-start">
        <div class="flex justify-start items-start">
          <h1
            class="text-3xl lg:text-4xl font-semibold text-gray-800 uppercase"
          >
            Proizvodi na promociji
          </h1>
          <button class="absolute top-2 right-4" @click="closePromotionModal">
            <i class="fas fa-times text-2xl"></i>
          </button>
        </div>
        <div
          @click="closePromotionModal"
          class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-between mt-8 gap-4"
        >
          <!-- lista proizvoda -->
          <product
            v-for="(product, index) in this.$store.getters
              .get_promotionProducts"
            :key="index"
            :id="product.id"
            :image="product.images[0].url"
            :model="product.model"
            :title="product.title"
            :price="product.price"
            :actionPrice="product.actionPrice"
            :category="product.category"
          ></product>
        </div>
      </div>
    </div>

    <!-- end product-->
  </section>
</template>

<script>
import Product from "../partials/Product.vue";
export default {
  components: {
    Product,
  },
  methods: {
    closePromotionModal() {
      this.$store.commit("CLOSE_PROMOTION_MODAL", false);
    },
  },
};
</script>

<style></style>
