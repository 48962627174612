import axios from 'axios';
import errorHandler from '../../../../middleware/ErrorHandler.js';

const orders = {
    state: {
        allOrders: [],
        pdf: null
    },
    mutations: {
        SET_ORDERS(state, payload) {
            state.allOrders = payload
        },
        SET_PDF(state, payload) {
            state.pdf = payload
        }
    },
    actions: {
        async getAllOrders(context) {
            try {
                const response = await axios.get(context.rootGetters.get_API + '/orders', context.rootGetters.get_headers)
                context.commit('SET_ORDERS', response.data)
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        },
        async getOrderPdf(context, payload) {
            try {
                const response = await axios.post(context.rootGetters.get_API + '/order/' + payload.id, payload.id, {
                    headers: context.rootGetters.get_headers.headers
                })
                context.commit('SET_PDF', response.data)
            } catch (error) {
                errorHandler(error.response.data.error, context, 'SET_ERROR_MESSAGE')
            }
        }
    },
    getters: {
        get_allOrders(state) {
            return state.allOrders.reverse()
        },
        get_pdf(state) {
            return state.pdf
        }
    }
};
export default orders;