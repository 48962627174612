<template>
  <div class="w-full">
    <header
      class="flex items-end justify-between sticky top-0 h-116 px-12 pb-4 bg-gray-900 text-gray-100 font-bold"
    >
      <h2 class="text-2xl text-gray-100">
        <i class="fas fa-tachometer-alt"></i> Info Strana
      </h2>
    </header>
    <the-activities></the-activities>
  </div>
</template>

<script>
import TheActivities from "./partials/TheActivities.vue";
export default {
  components: { TheActivities },
};
</script>

<style></style>
