<template>
  <article class="max-w-7xl mx-auto my-10 px-4">
    <h3 class="text-2xl leading-8 my-4 font-bold">Isporuka</h3>
    <p class="font-light">
      Isporuka se vrši preuzimanjem u maloprodajnom objektu.
    </p>
    <br />
    <h3 class="text-xl leading-8 my-4">Dostava</h3>
    <p class="font-light">
      U slučaju dostave pouzećem, informišemo vas kada je distributer preuzeo
      robu a sam rok isporuke zavisi od distributera.
    </p>
    <br />
    <h3 class="text-xl leading-8 my-4"> Prilikom dostave pouzećem</h3>
    <p class="font-light">
      Prilikom isporuke možete pregledati uređaj i sva eventualna uočena oštećenja robe koja Vam je dostavljena <strong>ODMAH</strong>, a najkasnije <strong>U ROKU OD
      24H</strong> prijaviti službi za reklamacije Dilektre na broj telefona <a href="tel:+381 11 66 71 369">011 66 71 369</a>.
    </p>
    <br />
    <br />
    <br />
  </article>
</template>

<script>
export default {
  metaInfo: {
    title: 'Isporuka',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Prilikom isporuke možete pregledati uređaj i sva eventualna uočena oštećenja robe koja Vam je dostavljena ODMAH, a najkasnije U ROKU OD 24H prijaviti službi za reklamacije Dilektre na broj telefona 011 66 71 369.'
      }
    ]
  },
};
</script>

<style></style>
