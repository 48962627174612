<template>
    <section class="overflow-hidden max-w-7xl mx-auto">
      <div class="2xl:mx-auto 2xl:px-0 md:py-12 md:px-4 sm:py-9 sm:px-4">
        <h1
          class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 px-4 py-4 sm:px-0"
        >
        Usluge
        </h1>
        <div
          class="px-4 pb-10 sm:p-0 lg:w-full w-full flex flex-col lg:flex-row lg:justify-between flex-nowrap"
        >
          <div class="sm:p-0 w-full xl:w-5/12 lg:w-5/12">
            <h2 class="text-2xl leading-8 my-4">
                Pored prodaja IT opreme vršimo<br /> i sledeće usluge za fizička lica
            </h2>
            <ul>
              <li>• Servis desktop-ova</li>
              <li>• Servis laptop-ova</li>
              <li>• Podizanje sistema</li>
              <li>• Izrada backup-a</li>
              <li>• Zamena display-a</li>
              <li>• Zamena baterija</li>
              <li>• Zamena staro za novo, komponenti i računara</li>
              <li>• Izrada konfiguracija prema želji i potrebama korisnika</li>
            </ul>
          </div>
          <div class="sm:p-0 w-full xl:w-5/12 lg:w-5/12">
            <h2 class="text-2xl leading-8 my-4">
                Za firme:
            </h2>
            <ul>
              <li>•Održavanje sistema</li>
              <li>• Izrada mrežne infrastrukture</li>
              <li>• Izrada serverskih soba</li>
              <li>• Rashladni sistemi za serverske sobe</li>
              <li>• Neprekidna napajanja (UPS,agregati)</li>
              <li>• Back up rešenja</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
export default {
  metaInfo: {
    title: 'Usluge',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `
                Pored prodaja IT opreme vršimo<br /> i sledeće usluge za fizička lica
            </h2>
            <ul>
              <li>• Servis desktop-ova</li>
              <li>• Servis laptop-ova</li>
              <li>• Podizanje sistema</li>
              <li>• Izrada backup-a</li>
              <li>• Zamena display-a</li>
              <li>• Zamena baterija</li>
              <li>• Zamena staro za novo, komponenti i računara</li>
              <li>• Izrada konfiguracija prema želji i potrebama korisnika</li>
            </ul>
          </div>
          <div">
            <h2 >
                Za firme:
            </h2>
            <ul>
              <li>•Održavanje sistema</li>
              <li>• Izrada mrežne infrastrukture</li>
              <li>• Izrada serverskih soba</li>
              <li>• Rashladni sistemi za serverske sobe</li>
              <li>• Neprekidna napajanja (UPS,agregati)</li>
              <li>• Back up rešenja</li>
            </ul>`
      }
    ]
  },
};
</script>