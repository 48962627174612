<template>
  <div class="specification">
    <div :class="index % 2 == 0 ? 'bg-gray-200' : 'bg-gray-50'">
      <div class="flex flex-row justify-start items-ceter py-3 px-3">
        <h5 class="w-4/12 flex items-center mr-2">
          {{ oneSpec.heading }}
        </h5>
        <p
          class="w-8/12 flex items-center"
          v-html="oneSpec.description.replace(/\n/g, '<br />')"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "heading", "description", "index"],
  data() {
    return {
      oneSpec: {
        heading: this.heading,
        description: this.description,
      },
    };
  },
};
</script>
