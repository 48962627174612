<template>
  <section>
    <mob-product-nav></mob-product-nav>
    <the-navbar></the-navbar>
    <notification :message="'Dodato u korpu'"></notification>
    <router-view />
    <!-- <the-search-result></the-search-result> -->
    <the-footer></the-footer>
    <error-modal
      :show="this.$store.getters.get_errorStatus"
      :message="this.$store.getters.get_errorMessage"
    ></error-modal>
    <promotion-modal></promotion-modal>
    <singin-modal></singin-modal>
    <spinner :loading="start"></spinner>
  </section>
</template>
<script>
import Spinner from "./components/reusable/TheSpinner.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import TheNavbar from "./components/layout/TheNavbar.vue";
import MobProductNav from './components/layout/MobProductNav.vue'
import ErrorModal from "./components/modals/ErrorModal.vue";
import { mapActions, mapGetters } from "vuex";
import PromotionModal from "./components/modals/PromotionModal.vue";
import SinginModal from "./components/modals/SinginModal.vue";
import Notification from "./components/modals/Notification.vue";
// import TheSearchResult from "./components/layout/TheSearchResult.vue";
export default {
  metaInfo: {
    titleTemplate: '%s - Dilektra d.o.o.',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { vmid: 'description', name: 'description', content: 'Prodavnica' },
    ],
  },
  components: {
    TheNavbar,
    ErrorModal,
    TheFooter,
    PromotionModal,
    Spinner,
    SinginModal,
    Notification,
    MobProductNav
    // TheSearchResult
  },
  computed: {
    ...mapActions(["checkIsSessionExpired"]),
    ...mapGetters(["get_isValid", "get_errorStatus", "get_errorMessage"]),
  },
  async mounted() {
    this.start = true;
    await this.$store.dispatch("getProductHomepage");
    await this.$store.dispatch("getPromotion");
    await this.$store.dispatch("getProductManu");
    await this.$store.dispatch("getAllPosts")
    this.start = false;
    this.checkUser();
    setInterval(() => {
      this.checkUser();
    }, 1800000);
  },
  data() {
    return {
      start: false,
    };
  },
  methods: {
    checkUser() {
      this.$store.dispatch("checkIsSessionExpired");
    },
  },
};
</script>

<style lang="scss">
* {
  @apply box-border;
}
html,
body {
  @apply min-w-full w-full min-h-full h-full;
}
.singin,
.singup {
  @apply relative inset-0 w-full h-screen;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
