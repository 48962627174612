<template>
  <section class="h-full w-full cms-grid">
    
    <header>
      <sidebar></sidebar>
      <div class="hisHeight hidden"></div>
      <div class="dropdown-menu"></div>
      <div class="locktotop"></div>
      <div class="moveRight"></div>
    </header>
    <router-view></router-view>
  </section>
</template>

<script>
import Sidebar from "../components/cms-components/partials/TheSidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style lang="scss">
.cms-grid {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 240px calc(100% - 240px);
}
.h-116 {
  height: 116px;
}
</style>
