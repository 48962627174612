<template>
  <button @click="singout" class="singout">
    <span class="text">Odjavi se</span>
    <i class="fas fa-sign-out-alt text-yellow ml-2"></i>
  </button>
</template>

<script>
export default {
  methods: {
    async singout() {
      await this.$store.dispatch("trySingout");
    },
  },
};
</script>

<style lang="scss">
.singout {
  @apply flex items-center justify-center border border-yellow py-2 px-4 rounded-md;
  .text {
    @apply text-sm text-center text-yellow uppercase;
    
  }
  &:hover > .text,  &:hover > i{
    @apply text-white;
  }
  
}
</style>
